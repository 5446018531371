import type { FC } from 'react';
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core';
import { ResourceService } from '../../services/ResourceService';
import { useSnackbar, VariantType } from 'notistack';
import { useNavigate } from "react-router-dom";
import { UserService } from '../../services/UserService';
import { AppState } from "../../store";
import { ResourceSelectItem } from '../../types/resource';
import { User } from '../../types/user';
import { UserInfo } from "../../models/UserInfo";
import { useSelector } from "react-redux";
import MultiSelect from '../MultiSelect';
import { UserResourceAccess } from '../../types/resourceAccess';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import { deepClone } from '../../utils/common';

interface UserProps {
    user: User;
}

const UserAccessManagement: FC<UserProps> = (props) => {
    const { user } = props;

    var userService = new UserService();
    var resourceService = new ResourceService();

    const [allResources, setAllResources] = useState<ResourceSelectItem[]>([]);
    const [allResourceSelectItems, setAllResourceSelectItems] = useState<string[]>([]);
    const [userResourceSelectItems, setUserResourceSelectItems] = useState<string[]>([]);

    const [fullSelectedResourceNames, setFullSelectedResourceNames] = useState<string[]>([]);

    const [selectedResources, setSelectedResources] = useState<ResourceSelectItem[]>([]);



    const { enqueueSnackbar } = useSnackbar();
    const history = useNavigate();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const [selectedtUsersSelectItem, setSelectedUsersSelectItem] = useState<string[]>();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleMultiSelectChange = (selectedResources: string[]): void => {
        setUserResourceSelectItems(selectedResources);

        setSelectedUsersSelectItem(deepClone(selectedResources));
    };

    const handleChipDelete = (chip: string): void => {
        setSelectedUsersSelectItem(() => selectedtUsersSelectItem?.filter((prevChip) => chip !== prevChip));
    };

    useEffect(() => {
        (async () => {
            try {
                if (userInfo) {

                    var [allResourcesSelectItemsRes, userResourceSelectItemsRes] = await Promise.all([
                        resourceService.getResourceSelectItems(userInfo.tenantId, userInfo.email),
                        resourceService.getResourceSelectItems(userInfo.tenantId, user.email)
                    ]);

                    var fullSelectedResourceNames = allResourcesSelectItemsRes?.filter(resource => userResourceSelectItemsRes.map(r => r.id).includes(resource.id));

                    setAllResources(allResourcesSelectItemsRes);
                    setAllResourceSelectItems(allResourcesSelectItemsRes?.map(resource => resource.name));
                    setUserResourceSelectItems(fullSelectedResourceNames?.map(resource => resource.name));
                }
            } catch {
                history('/500');
            }
        })();
    }, [userInfo]);

    async function submitResourceAccessAsync() {
        try {

            const userResourceAccess: UserResourceAccess = {
                resourceIds: allResources.filter(resource => userResourceSelectItems.includes(resource.name))?.map(r => r.id),
                email: user.email,
                tenantId: userInfo?.tenantId || ''
            };

            var msgUser = t('ACCESSES_ASSIGNED_SUCCESSFULLY');
            var msgVariant: VariantType = 'success';

            await resourceService.upsertUserResourceAccess(userResourceAccess);

            enqueueSnackbar(msgUser, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: msgVariant
            });
            history('/users');

        } catch {
            history('/500');
        }
    }

    return (
        <>
            <Card>
                <CardHeader title={t('ACCESSES')} />
                <Divider />
                <CardContent>
                    <Box
                        sx={{
                            mb: 2,
                            mt: 1
                        }}
                    >
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle2"
                                        >
                                            {t('USER')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color="textSecondary"
                                            variant="body2"
                                        >
                                            {user.email}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexWrap: 'wrap',
                                p: 1,
                                width: '100%',
                                border: selectedtUsersSelectItem && selectedtUsersSelectItem.length < 1 ? '0.5px solid #f44336!important' : '0.5px solid #919eab!important',
                                borderRadius: '16px',
                                marginLeft: '0px',
                                marginTop: '10px'
                            }}
                        >
                            <MultiSelect
                                label={t('RESOURCES')}
                                onChange={handleMultiSelectChange}
                                options={allResourceSelectItems || []}
                                value={userResourceSelectItems || []}
                            />
                            {userResourceSelectItems?.map((chip) => (
                                <Chip
                                    key={chip}
                                    label={chip}
                                    onDelete={(): void => handleChipDelete(chip)}
                                    sx={{ m: 1 }}
                                    variant="outlined"
                                />
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 2
                    }}>
                        <Button
                            onClick={async () => {
                                setIsLoading(true);
                                await submitResourceAccessAsync();
                                setIsLoading(false);
                            }}
                            color="primary"
                            variant="contained"
                        >
                            {t('ASSIGN')}
                        </Button>
                    </Box>
                </CardContent>
                <Loader isLoading={isLoading} />
            </Card>
        </>);
};

export default UserAccessManagement;
