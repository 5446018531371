import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, Divider, TextField, Typography } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import { UserService } from '../services/UserService';
import { AppState } from "../store";
import { UserInfo } from "../models/UserInfo";
import { useDispatch, useSelector } from "react-redux";
import InitService from '../services/InitService';
import { User as UserClient } from 'oidc-client';
import { actionCreators as appInitAction } from '../store/AppInit';
import { useTranslation } from 'react-i18next';

interface ChangeTenantFormProps {
  onAddComplete?: () => void;
  onCancel?: () => void;
}

const ModalChangeTenant: FC<ChangeTenantFormProps> = (props) => {
  const { onAddComplete, onCancel } = props;
  const [selectedTenantSelectItem, setSelectedTenantSelectItem] = useState<string>();
  const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
  const userClient = useSelector<AppState, UserClient>(state => state.oidc.user);
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedTenantSelectItem(userInfo?.tenantId);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Typography
        color="textPrimary"
        variant="h6"
      >
        {t('CHANGE_TENANT')}
      </Typography>
      <Box sx={{ mt: 3, pb: 3 }}>
        <TextField
          fullWidth
          label={t('TENANT')}
          select
          SelectProps={{ native: true }}
          onChange={(e) => {
            setSelectedTenantSelectItem(e.target.value);
          }}
          value={selectedTenantSelectItem || ''}
          variant="outlined"
        >
          {userInfo?.tenants.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          }).map((tenant) => (
            <option
              key={tenant.id}
              value={tenant.id}
            >
              {tenant.name}
            </option>
          ))}
        </TextField>
      </Box>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="primary"
          onClick={onCancel}
          variant="text"
        >
          {t('CANCEL')}
        </Button>
        <Button
          color="primary"
          sx={{ ml: 1 }}
          type="submit"
          variant="contained"
          onClick={async () => {
            try {
              if (userInfo && selectedTenantSelectItem) {

                var userService = new UserService();
                await userService.changeCurrentTenant(selectedTenantSelectItem);

                const userInfo = await InitService.initializeUserProfile(userClient);
                dispatch(appInitAction.setUserInfo(userInfo));
                history('/igloo'); //Do not remove
                history('/');

                if (onAddComplete)
                  onAddComplete();
              }
            }
            catch {
              history('/500');
            }
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Box>
    </Box>
  )
};

export default ModalChangeTenant;
