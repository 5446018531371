import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from '@material-ui/core';
import { Resource } from '../types/resource';
import ChevronRightIcon from '../icons/ChevronRight';
import PlusIcon from '../icons/Plus';
import ResourceListTable from '../components/resource/ResourceListTable';
import { ResourceService } from '../services/ResourceService';
import { useSelector } from "react-redux";
import { AppState } from "../store";
import { UserInfo } from "../models/UserInfo";
import { PaginationResult } from '../types/paginationResult';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTheme } from '@material-ui/core/styles';

const ResourceList: FC = () => {

    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const history = useNavigate();
    const { t } = useTranslation();
    var currentTheme = useTheme();

    const [resources, setResources] = useState<PaginationResult<Resource>>();
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    const getResources = useCallback(async () => {
        setIsPageLoading(true);
        try {
            if (userInfo) {
                var resourceService = new ResourceService();
                var res = await resourceService.searchResources(userInfo.tenantId, 0, 5, '', '');
                setResources(res);
            }
        } catch (e) {
            console.error(e);
            history('/500');
        }
        setIsPageLoading(false);
    }, [userInfo]);

    useEffect(() => {
        getResources();
    }, [getResources]);

    const handlePageChange = (event: any, newPage: number): void => {
        setIsPageLoading(true);
        (async () => {
            try {
                if (userInfo) {
                    var resourceService = new ResourceService();
                    var res = await resourceService.searchResources(userInfo.tenantId, newPage, 5, '', '');
                    setResources(res);
                }
            } catch (e) {
                console.error(e);
                history('/500');
            }
        })();
        setIsPageLoading(false);
    };

    const handleLimitChange = (limit: any): void => {
        setIsPageLoading(true);
        (async () => {
            try {
                if (userInfo) {
                    var resourceService = new ResourceService();
                    var res = await resourceService.searchResources(userInfo.tenantId, 0, limit, '', '');
                    setResources(res);
                }
            } catch (e) {
                console.error(e);
                history('/500');
            }
        })();
        setIsPageLoading(false);
    };

    const handleQueryChange = (event: string, type: string): void => {
        setIsPageLoading(true);
        (async () => {
            try {
                if (userInfo) {
                    var resourceService = new ResourceService();
                    var res = await resourceService.searchResources(userInfo.tenantId, 0, 5, event ?? '', type ?? '');
                    setResources(res);
                }
            } catch (e) {
                console.error(e);
                history('/500');
            }
        })();
        setIsPageLoading(false);
    };

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 2,
                [currentTheme.breakpoints.up('lg')]: {
                    paddingLeft: '300px'
                }
            }}
        >
            <Container>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('RESOURCE_LIST')}
                        </Typography>
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<ChevronRightIcon fontSize="small" />}
                            sx={{ mt: 1 }}
                        >
                            <Link
                                color="textPrimary"
                                component={RouterLink}
                                to="/"
                                variant="subtitle2"
                            >
                                {t('DASHBOARD')}
                            </Link>
                            <Typography
                                color="textSecondary"
                                variant="subtitle2"
                            >
                                {t('RESOURCES')}
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <Box sx={{ m: -1 }}>
                            <Button
                                component={RouterLink}
                                to="add"
                                color="primary"
                                startIcon={<PlusIcon fontSize="small" />}
                                sx={{ m: 1 }}
                                variant="contained"
                            >
                                {t('ADD_RESOURCE')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 3 }}>
                    {!isPageLoading ?
                        resources && <ResourceListTable
                            paginationResult={resources}
                            handlePageChange={handlePageChange}
                            handleLimitChange={handleLimitChange}
                            handleQueryChange={handleQueryChange} />
                        : (<>
                            <Skeleton variant='text' width='100%' height={180} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                        </>)}
                </Box>
            </Container>
        </Box>)
};

export default ResourceList;
