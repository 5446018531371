import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Resource } from '../types/resource';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardHeader,
    Container,
    Divider,
    Grid,
    Link,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ResourceDataDetails from '../components/resource/ResourceDataDetails';
import ChevronRightIcon from '../icons/ChevronRight';
import PencilAltIcon from '../icons/PencilAlt';
import ResourceDataManagement from '../components/resource/ResourceDataManagement';
import ResourceDocumentList from '../components/resource/ResourceDocumentList';
import { ResourceService } from '../services/ResourceService';
import { useTranslation } from 'react-i18next';
import ResourcePosition from '../components/resource/ResourcePosition';
import { useTheme } from '@material-ui/core/styles';
import { userCanUseIfRoles } from '../utils/common';
import { ResourceContributor } from '../types/rolesConst';
import { useSelector } from "react-redux";
import { AppState } from "../store";
import { UserInfo } from "../models/UserInfo";

const ResourceDetails: FC = () => {
    const { t } = useTranslation();
    const [resource, setResource] = useState<Resource | null>(null);
    const [currentTab, setCurrentTab] = useState<string>('details');
    const [id, setId] = useState<string>('');
    const history = useNavigate();
    var currentTheme = useTheme();
    var resourceService = new ResourceService();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);

    const tabs = [
        { label: t('DETAILS'), value: 'details' },
        { label: t('POSITION'), value: 'position' }
    ];

    const getCustomer = useCallback(async () => {
        try {
            let idParam = window.location.href.split('id=')[1] ?? '';
            setId(idParam!);
            if (window.location.href.includes('map=true')) {
                setCurrentTab('position')
            }
            var res = await resourceService.findResource(idParam);
            setResource(res);
        } catch (err) {
            console.error(err);
            history('/500');
        }
    }, []);

    useEffect(() => {
        getCustomer();
    }, [getCustomer]);

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    if (!resource) {
        return null;
    }

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 2,
                    [currentTheme.breakpoints.up('lg')]: {
                        paddingLeft: '300px'
                    }
                }}
            >
                <Container>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {resource.name}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    {t('DASHBOARD')}
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/resources"
                                    variant="subtitle2"
                                >
                                    {t('RESOURCES')}
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    {t('DETAILS')}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Divider />
                        {userCanUseIfRoles(userInfo, [ResourceContributor]) && <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<PencilAltIcon fontSize="small" />}
                                    sx={{ m: 1 }}
                                    to={`/resources/edit?id=${id}`}
                                    variant="contained"
                                >
                                    {t('EDIT')}
                                </Button>
                            </Box>
                        </Grid>}
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Tabs
                            indicatorColor="primary"
                            onChange={handleTabsChange}
                            scrollButtons="auto"
                            textColor="primary"
                            value={currentTab}
                            variant="scrollable"
                        >
                            {tabs.map((tab) => (
                                <Tab
                                    key={tab.value}
                                    label={tab.label}
                                    value={tab.value}
                                />
                            ))}
                        </Tabs>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 3 }}>
                        {currentTab === 'details' && (
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    lg={6}
                                    md={6}
                                    xl={6}
                                    xs={12}
                                >
                                    <ResourceDataDetails
                                        id={resource.id}
                                        name={resource.name}
                                        description={resource.description}
                                        resourceTypeCode={resource.resourceTypeCode}
                                        users={resource.users}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    md={6}
                                    xl={6}
                                    xs={12}
                                >
                                    <ResourceDataManagement id={resource.id} />
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    xl={12}
                                    xs={12}
                                >
                                    {
                                        resource.documents?.length > 0 &&
                                        <Card>
                                            <CardHeader title={t('DOCUMENTS')} />
                                            <Divider />
                                            <ResourceDocumentList files={resource.documents} resourceId={resource.id} isDetail={true} />
                                        </Card>
                                    }
                                </Grid>
                            </Grid>
                        )}
                        {currentTab === 'position' && (
                            <Grid
                                container
                                spacing={3}
                            >
                                <ResourcePosition resource={resource} />
                            </Grid>)}

                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default ResourceDetails;
