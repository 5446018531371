import { Resource } from '../../types/resource';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Card,
    Divider,
    IconButton,
    InputAdornment,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tabs,
    TextField,
    Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Scrollbar from '../Scrollbar';
import getInitials from '../../utils/getInitials';
import ArrowRightIcon from '../../icons/ArrowRight';
import PencilAltIcon from '../../icons/PencilAlt';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import SearchIcon from '../../icons/Search';
import RoomIcon from "@material-ui/icons/Room";
import { PaginationResult } from '../../types/paginationResult';
import { UserInfo } from "../../models/UserInfo";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { ResourceTypeSelectItem } from '../../types/resourceType';
import { ResourceTypeService } from '../../services/ResourceTypeService';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';

interface ResourceListTableProps {
    paginationResult: PaginationResult<Resource>;
    handlePageChange(event: any, newPage: number): void;
    handleLimitChange(event: any): void;
    handleQueryChange(event: string, type: string): void;
}

const ResourceListTable: FC<ResourceListTableProps> = (props) => {
    const { paginationResult, handlePageChange, handleLimitChange, handleQueryChange, ...other } = props;
    const [selectedResources, setSelectedResources] = useState<string[]>([]);
    const [currentTab, setCurrentTab] = useState<string>('all');
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(5);
    const [searchedText, setSearchedText] = useState<string>('');
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const [resourceTypeSelectItems, setResourceTypeSelectItems] = useState<ResourceTypeSelectItem[]>([]);
    const [selectedResourceTypeSelectItem, setSelectedResourceTypeSelectItem] = useState<string>();
    const { t } = useTranslation();

    const tabs = [
        {
            label: t('ALL'),
            value: 'all'
        }
    ];

    useEffect(() => {

        (async () => {
            if (userInfo) {
                let resourceTypeService = new ResourceTypeService();
                let selectItems = await resourceTypeService.getResourceTypeSelectItems(userInfo?.tenantId);
                selectItems.unshift({ id: '', description: '', code: '', impactOnCapacity: false });
                setResourceTypeSelectItems(selectItems);
            }
        })();
    }, [userInfo]);


    return (
        <Card {...other}>
            <Tabs
                indicatorColor="primary"
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
            >
                {tabs.map((tab) => (
                    <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                    />
                ))}
            </Tabs>
            <Divider />
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                    m: -1,
                    p: 2
                }}
            >
                <Box
                    sx={{
                        m: 1,
                        maxWidth: '100%',
                        width: 500
                    }}
                >
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => {
                            setSearchedText(e.target.value);
                            handleQueryChange(e.target.value, selectedResourceTypeSelectItem ?? '');
                        }}
                        placeholder={t('SEARCH_RESOURCES')}
                        value={searchedText}
                        variant="outlined"
                    />
                </Box>
                <Box
                    sx={{
                        m: 1,
                        width: 240
                    }}
                >
                    <TextField
                        label={t('TYPE')}
                        name="resourceTypeCode"
                        onChange={(e) => {
                            setSelectedResourceTypeSelectItem(e.target.value);
                            handleQueryChange(searchedText, e.target.value);
                        }}
                        select
                        SelectProps={{ native: true }}
                        value={selectedResourceTypeSelectItem || ''}
                        variant="outlined"
                    >
                        {resourceTypeSelectItems.map((resourceTypeSelectItem) => (
                            <option
                                key={resourceTypeSelectItem.code}
                                value={resourceTypeSelectItem.code}
                            >
                                {resourceTypeSelectItem.description}
                            </option>
                        ))}
                    </TextField>
                </Box>
            </Box>
            <Scrollbar>
                <Box sx={{ minWidth: 700 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t('NAME')}
                                </TableCell>
                                <TableCell>
                                    {t('TYPE')}
                                </TableCell>
                                <TableCell>
                                    {t('DESCRIPTION')}
                                </TableCell>
                                <TableCell align="right">
                                    {t('ACTIONS')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginationResult.items.map((resource) => {
                                const isResourceSelected = selectedResources.includes(resource.id);
                                return (<TableRow
                                    hover
                                    key={resource.id}
                                    selected={isResourceSelected}
                                >
                                    <TableCell>
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}
                                        >
                                            <Avatar
                                                src={resource.avatar}
                                                sx={{
                                                    height: 42,
                                                    width: 42
                                                }}
                                            >
                                                {getInitials(resource.name)}
                                            </Avatar>
                                            <Box sx={{ ml: 1 }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="subtitle2"
                                                >
                                                    {resource.name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ ml: 1 }}>
                                            <Typography
                                                color="textSecondary"
                                                variant="body2"
                                            >
                                                {resource.resourceTypeCode}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ ml: 1 }}>
                                            <Typography
                                                color="textSecondary"
                                                variant="body2"
                                            >
                                                {resource.description}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Tooltip title={t('DUPLICATE') || ''} aria-label={t('DUPLICATE') || ''}>
                                            <IconButton
                                                component={RouterLink}
                                                to={`duplicate?id=${resource.id}`}
                                            >
                                                <FileCopyOutlinedIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t('POSITION') || ''} aria-label={t('POSITION') || ''}>
                                            <IconButton
                                                component={RouterLink}
                                                to={`/resources/detail?map=true&id=${resource.id}`}
                                            >
                                                <RoomIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t('EDIT') || ''} aria-label={t('EDIT') || ''}>
                                            <IconButton
                                                component={RouterLink}
                                                to={`edit?id=${resource.id}`}
                                            >
                                                <PencilAltIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t('DETAILS') || ''} aria-label={t('DETAILS') || ''}>
                                            <IconButton
                                                component={RouterLink}
                                                to={`detail?id=${resource.id}`}
                                            >
                                                <ArrowRightIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Box>
            </Scrollbar>
            <TablePagination
                component="div"
                count={paginationResult.totalCount}
                onRowsPerPageChange={(e) => {
                    setLimit(parseInt(e.target.value, 10));
                    handleLimitChange(e.target.value);
                }}
                onPageChange={(e, page) => {
                    setPage(page);
                    handlePageChange(e, page);
                }}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Card>);
}

export default ResourceListTable;
