import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Divider, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import UserIcon from '../../icons/User';
import { useTranslation } from 'react-i18next';

interface ResourceDataDetailsProps {
    id?: string;
    name?: string;
    description?: string;
    resourceTypeCode?: string;
    users?: string[];
}

const ResourceDataDetails: FC<ResourceDataDetailsProps> = (props) => {
    const {
        id,
        name,
        description,
        resourceTypeCode,
        users,
        ...other
    } = props;
    const { t } = useTranslation();

    return (
        <Card {...other}>
            <CardHeader title={t('RESOURCE_DETAILS')} />
            <Divider />
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('NAME')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {name}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('DESCRIPTION')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {description}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('TYPE')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {resourceTypeCode}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
    );
};

ResourceDataDetails.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    resourceTypeCode: PropTypes.string,
    description: PropTypes.string,
    users: PropTypes.array
};

export default ResourceDataDetails;
