import type { FC } from 'react';
import { useState } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Slider, Switch, Typography } from '@material-ui/core';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { useTranslation } from 'react-i18next';

interface SmartLampProps {
    isSwitched: boolean;
    brightness: number;
    white: number;
    setIsSwitched(value: boolean): any;
    setBrightness(value: number): any;
    setWhite(value: number): any;
}

const SmartLamp: FC<SmartLampProps> = (props) => {
    const { t } = useTranslation();

    const { white, isSwitched, brightness, setIsSwitched, setBrightness, setWhite } = props;

    function valuetext(value: number) {
        return `${value} %`;
    }

    const [bright, setBright] = useState<number>(100);
    return (
        <Grid
            sx={{ py: 2 }}
            item
            md={12}
            sm={12}
            xs={12}
        >
            <Card>
                <CardHeader
                    disableTypography
                    title={(
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {t('LAMP')}
                            </Typography>
                        </Box>
                    )}
                />
                <CardContent>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            py: 0
                        }}>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('DESK_LAMP')}
                        </Typography>
                        <Switch color="primary" checked={isSwitched} onClick={() => {
                            setIsSwitched(!isSwitched);
                        }} />
                    </Box>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            py: 2
                        }}>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('INTENSITY')}
                        </Typography>
                        <Slider 
                            sx={{ mx: 2 }}
                            value={brightness}
                            defaultValue={100}
                            getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            min={0}
                            max={100}
                            onChange={(e, number) => {
                                setBrightness(number as number);
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            py: 2
                        }}>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('WHITE')}
                        </Typography>
                        <Slider
                            sx={{ mx: 2 }}
                            value={white}
                            defaultValue={100}
                            getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            min={0}
                            max={100}
                            onChange={(e, number) => {
                                setWhite(number as number);
                            }}
                        />
                    </Box>
                </CardContent>
            </Card>

        </Grid>
    );
};


export default SmartLamp;
