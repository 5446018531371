import type { FC } from 'react';
import { useRef } from 'react';
import { Box, Button, Card, CardActions, CardContent, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import DownloadIcon from '../../icons/Download';
import TrashIcon from '../../icons/Trash';
import bytesToSize from '../../utils/bytesToSize';
import { ResourceDocument } from '../../types/resource';
import { ResourceService } from '../../services/ResourceService';
import { useTranslation } from 'react-i18next';

interface ResourceDocumentsProps {
  handleRemoveDocument?: (id: string) => void;
  files: ResourceDocument[];
  resourceId: string;
  isDetail: boolean;
}

const ResourceDocumentList: FC<ResourceDocumentsProps> = (props) => {
  const {
    files,
    resourceId,
    isDetail,
    handleRemoveDocument
  } = props;
  const { t } = useTranslation();
  const moreRef = useRef<HTMLButtonElement | null>(null);

  async function downloadDocumentAsync(id: string, resourceId: string) {
    try {
        var resourceService = new ResourceService();
        var res = await resourceService.getResourceDocument(id, resourceId);
        var link = document.createElement('a');
        link.href = res.base64;
        link.download = res.fileName;
        link.click();
    } catch(e) {
      console.log(e);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100%',
        p: 2
      }}
    >
      <Grid
        container
        spacing={3}
      >
        {files.map((file) => (
          
          <Grid
            item
            key={file.id}
            md={4}
            xs={12}
          >
            <Card>
              
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {file.fileName}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                  >
                    {bytesToSize(file.lenght)}
                  </Typography>
                </div>
                {!isDetail &&
                  <div>
                    
                      <IconButton
                        edge="end"
                        onClick={(evt) => {
                          evt.preventDefault();
                          if (handleRemoveDocument)
                            handleRemoveDocument(file.id);
                        }}
                        ref={moreRef}
                        size="small"
                      >
                        <TrashIcon fontSize="small" />
                      </IconButton>
                    
                  </div>
                }
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  fullWidth
                  onClick={async (evt) => {
                    evt.preventDefault();
                    await downloadDocumentAsync(file.id, resourceId);
                  }}
                  startIcon={<DownloadIcon fontSize="small" />}
                  variant="text"
                >
                  {t('DOWNLOAD')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ResourceDocumentList;
