import type { FC } from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField } from '@material-ui/core';
import type { Building } from '../../types/building';
import { BuildingService } from '../../services/BuildingService';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import { useSnackbar, VariantType } from 'notistack';
import { useNavigate } from "react-router-dom";
import AvatarDropzone from '../AvatarDropzone';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';

interface BuildingAddFormProps {
  building: Building;
  isAdd: boolean;
}

const BuildingAddForm: FC<BuildingAddFormProps> = (props) => {
  const { building, isAdd, ...other } = props;
  const { enqueueSnackbar } = useSnackbar();
  const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
  const history = useNavigate();
  const [avatar, setAvatar] = useState<string>();
  const [files, setFiles] = useState<any[]>([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDrop = (newFiles: any): void => {
    var reader = new FileReader();
    reader.readAsDataURL(newFiles[0]);
    reader.onload = () => {
      setAvatar(reader.result as string);
    };

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file: any): void => {
    setFiles([]);
    setAvatar(undefined);
  };

  useEffect(() => {

    if (building.avatar) {
      setAvatar(building.avatar);
    } else {
      setAvatar(undefined);
    }
  }, []);

  return (building ?
    <Formik
      initialValues={{
        name: building.name || '',
        tenantId: building.tenantId || '',
        addressId: building.addressId || '',
        id: building.id || '',
        address: building.address || '',
        locality: building.locality || '',
        province: building.province || '',
        country: building.country || '',
        postalCode: building.postalCode || '',
        postalCodeExt: building.postalCodeExt || '',
        avatar: avatar,
        submit: null
      }}
      validationSchema={
        Yup
          .object()
          .shape({
            name: Yup
              .string()
              .max(255)
              .required(t('NAME_REQUIRED'))
          })
      }
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }): Promise<void> => {
        try {
          setIsLoading(true);
          var msgBuilding = t('BUILDING_UPDATED');
          var msgVariant: VariantType = 'success';
          const buildingModel: Building = {
            id: uuidv4(),
            addressId: uuidv4(),
            tenantId: userInfo?.tenantId ?? '',
            name: values.name,
            altName: values.name,
            address: values.address,
            locality: values.locality,
            province: values.province,
            country: values.country,
            postalCode: values.postalCode,
            postalCodeExt: values.postalCodeExt,
            avatar: avatar ?? ''
          };

          var buildingService = new BuildingService();

          if (isAdd) {
            msgBuilding = t('BUILDING_ADDED');
          }
          else {
            buildingModel.id = values.id;
            buildingModel.addressId = values.addressId;
          }

          await buildingService.upsertBuilding(buildingModel);

          enqueueSnackbar(msgBuilding, {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            variant: msgVariant
          });

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          history('/buildings');

        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
          history('/500');
        }
        setIsLoading(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <Box sx={{ pt: 3 }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={6}
              xl={3}
              xs={12}
            >
              <Card>
                <CardHeader title={t('AVATAR')} />
                <Divider />
                <CardContent>
                  {avatar
                    ? <div style={{ textAlign: 'center' }}>
                      <img src={avatar} alt="avatar" style={{ padding: '20px', borderRadius: '50%', width: '250px', height: '250px' }}>
                      </img>
                      <Divider />
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 2
                      }}>
                        <Button
                          sx={{
                            backgroundColor: 'error.main',
                            color: 'error.contrastText',
                            '&:hover': {
                              backgroundColor: 'error.dark'
                            }
                          }}
                          variant="contained"
                          onClick={handleRemove}
                        >
                          {t('DELETE')}
                        </Button>
                      </Box>
                    </div>
                    : <Box sx={{ mt: 3 }}>
                      <AvatarDropzone
                        accept="image/*"
                        files={files}
                        maxFiles={1}
                        maxSize={5000000}
                        onDrop={handleDrop}
                        onRemove={handleRemove}
                      />
                    </Box>}
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              lg={8}
              md={6}
              xl={9}
              xs={12}
            >
              <form
                onSubmit={handleSubmit}
                {...other}
              >
                <Card>
                  <CardHeader title={t('BUILDING')} />
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={4}
                    >
                      <Grid
                        item
                        md={12}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label={t('NAME')}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.address && errors.address)}
                          fullWidth
                          helperText={touched.address && errors.address}
                          label={t('ADDRESS')}
                          name="address"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.locality && errors.locality)}
                          fullWidth
                          helperText={touched.locality && errors.locality}
                          label={t('CITY')}
                          name="locality"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.locality}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.province && errors.province)}
                          fullWidth
                          helperText={touched.province && errors.province}
                          label={t('STATE')}
                          name="province"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.province}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.country && errors.country)}
                          fullWidth
                          helperText={touched.country && errors.country}
                          label={t('COUNTRY')}
                          name="country"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.country}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.postalCode && errors.postalCode)}
                          fullWidth
                          helperText={touched.postalCode && errors.postalCode}
                          label={t('ZIP_CODE')}
                          name="postalCode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.postalCode}
                          variant="outlined"
                        />
                      </Grid>

                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      {isAdd ? t('BUILDING_ADD') : t('BUILDING_EDIT')}
                    </Button>
                  </Box>
                </Card>
              </form>
            </Grid>
          </Grid>
          <Loader isLoading={isLoading} />
        </Box>
      )}
    </Formik> : <></>
  );
};

export default BuildingAddForm;
