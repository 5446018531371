import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Divider, Grid, Link, Typography } from '@material-ui/core';
import BuildingAddForm from '../components/building/BuildingAddForm';
import ChevronRightIcon from '../icons/ChevronRight';
import type { Building } from '../types/building';
import { BuildingService } from '../services/BuildingService';
import LayersIcon from '@material-ui/icons/Layers';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

interface BuildingAddProps {
  isAdd: boolean;
}

const BuildingAdd: FC<BuildingAddProps> = (props) => {
  const { isAdd } = props;
  const [building, setBuilding] = useState<Building | null>(null);
  const [isAddMode, setIsAddMode] = useState<boolean>();
  const history = useNavigate();
  const { t } = useTranslation();
  const currentTheme = useTheme();

  useEffect(() => {
    setIsAddMode(isAdd);
    (async () => {
      if (isAdd) {
        const bld: Building = {
          id: '',
          tenantId: '',
          addressId: '',
          name: '',
          altName: '',
          address: '',
          locality: '',
          province: '',
          country: '',
          postalCode: '',
          postalCodeExt: '',
          avatar: '',
        };
        setBuilding(bld);
      } else {
        try {
          let buildingId = window.location.href.split('id=')[1] ?? '';
          var buildingService = new BuildingService();
          var res = await buildingService.findBuilding(buildingId);

          setBuilding(res);

        } catch (err) {
          console.error(err);
          history('/500');
        }
      }
    })();
  }, [isAdd, isAddMode]);

  if (!building) {
    return null;
  }

  let labelTitle = t('BUILDING_EDIT');
  let navigationTitle = t('EDIT');

  if (isAdd) {
    labelTitle = t('BUILDING_ADD');
    navigationTitle = t('ADD');
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 2,
          [currentTheme.breakpoints.up('lg')]: {
            paddingLeft: '300px'
          }
        }}
      >
        <Container>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {labelTitle}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  {t('DASHBOARD')}
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/buildings"
                  variant="subtitle2"
                >
                  {t('BUILDINGS')}
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {navigationTitle}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Divider />
            {!isAdd &&
              <Grid item>
                <Box sx={{ m: -1 }}>
                  <Button
                    color="primary"
                    component={RouterLink}
                    startIcon={<LayersIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    to={`/levels?buildingId=${building.id}`}
                    variant="contained"
                  >
                    {t('LEVELS')}
                  </Button>
                </Box>
              </Grid>
            }
          </Grid>
          <Box mt={3}>
            <BuildingAddForm building={building} isAdd={isAddMode!} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BuildingAdd;
