import type { FC } from 'react';
import { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Avatar, Box, Button, Dialog, Divider, Drawer, Hidden, Typography } from '@material-ui/core';
import Scrollbar from './Scrollbar';
import { AppState } from "../store";
import { useSelector } from "react-redux";
import { UserInfo } from "../models/UserInfo";
import getInitials from '../utils/getInitials';
import ModalChangeTenant from '../components/ModalChangeTenant';
import { useTranslation } from 'react-i18next';
import NavSection from './NavSection';
import HomeIcon from '@material-ui/icons/Home';
import Users from "../icons/Users";
import Resource from "../icons/Resource";
import Calendar from "../icons/Calendar";
import BusinessIcon from '@material-ui/icons/Business';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import AIEngine from "../icons/AiEngine";

interface DashboardSidebarProps {
    onMobileClose: () => void;
    openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
    const { onMobileClose, openMobile } = props;
    const location = useLocation();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();
    const basisTenantId = 'baecfaa7-ac23-494a-b960-d00d4bc43421';

    const sections = [
        {
            title: '',
            items: [
                {
                    title: t('DASHBOARD'),
                    path: '/',
                    icon: <HomeIcon fontSize="small"/>
                },
                {
                    title: t('USERS'),
                    path: '/users',
                    icon: <Users fontSize="small"/>
                },
                {
                    title: t('RESOURCES'),
                    path: '/resources',
                    icon: <Resource fontSize="small"/>
                },
                {
                    title: t('RESOURCE_TYPES'),
                    path: '/resourceTypes',
                    icon: <LocalOfferIcon fontSize="small"/>
                },
                {
                    title: t('CALENDAR'),
                    path: '/calendar',
                    icon: <Calendar fontSize="small"/>
                },
                {
                    title: t('BUILDINGS'),
                    path: '/buildings',
                    icon: <BusinessIcon fontSize="small"/>
                },
                {
                    title: t('MY_IGLOO'),
                    path: '/smartDesk',
                    icon: <DesktopWindowsIcon fontSize="small"/>
                },
                {
                    title: t('AI_ENGINE'),
                    path: '/aiengine',
                    icon: <AIEngine fontSize="small"/>
                }]
        }
    ];

    const handleAddClick = (): void => {
        setIsModalOpen(true);
    };

    const handleModalClose = (): void => {
        setIsModalOpen(false);
    };

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Scrollbar options={{ suppressScrollX: true }}>
                <Hidden lgUp>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            p: 2
                        }}
                    >
                        <RouterLink to="/">

                        </RouterLink>
                    </Box>
                </Hidden>
                <Box sx={{ p: 2 }}>
                    <Box sx={{ p: 2, pt: 0 }}>
                        {userInfo?.tenantId === basisTenantId
                        && <div style={{ width: '100%', background: 'white', textAlign: 'center' }}>
                            <img alt='logo_basis' src='/static/logo_basis.jpg' height={65}/>
                        </div>}
                    </Box>
                    <Box
                        sx={{
                            alignItems: 'center',
                            backgroundColor: 'background.default',
                            borderRadius: 1,
                            display: 'flex',
                            overflow: 'hidden',
                            p: 2
                        }}
                    >
                        <RouterLink to="/">
                            <Avatar
                                src={userInfo?.avatar}
                                sx={{
                                    height: 48,
                                    width: 48
                                }}
                                {...userInfo ? getInitials(`${userInfo.name} ${userInfo.surname}`) : ''}
                            />
                        </RouterLink>
                        <Box sx={{ ml: 2 }}>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {userInfo?.name} {userInfo?.surname}
                            </Typography>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                                sx={{
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                {userInfo && userInfo?.tenants?.length > 1 &&
                                <Button
                                    onClick={handleAddClick}
                                >
                                    {userInfo?.tenantName}
                                </Button>
                                }
                                {userInfo && userInfo?.tenants?.length === 1 &&
                                userInfo?.tenantName}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider/>
                <Box sx={{ p: 2 }}>
                    {sections.map((section) => (
                        <NavSection
                            onClick={() => {
                                if (onMobileClose)
                                    onMobileClose()
                            }}
                            key={section.title}
                            pathname={location.pathname}
                            sx={{
                                '& + &': {
                                    mt: 3
                                }
                            }}
                            {...section}
                        />
                    ))}
                </Box>
            </Scrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'background.paper',
                            width: 280
                        }
                    }}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    open
                    PaperProps={{
                        sx: {
                            backgroundColor: 'background.paper',
                            height: 'calc(100% - 64px) !important',
                            top: '64px !Important',
                            width: 280
                        }
                    }}
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Dialog
                fullWidth
                maxWidth="sm"
                onClose={handleModalClose}
                open={isModalOpen}
            >
                {isModalOpen && (
                    <ModalChangeTenant
                        onAddComplete={handleModalClose}
                        onCancel={handleModalClose}
                    />)}
            </Dialog>
        </>
    );
};

export default DashboardSidebar;
