import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DesktopDatePicker, DesktopTimePicker, MobileDatePicker, MobileTimePicker } from '@material-ui/lab';
import { deepClone, isToday } from '../../../utils/common';
import { Pattern } from '../../../types/reservation';
import { Step2 } from './SingleEvent';
import { addDays, addHours, addMinutes } from 'date-fns';

interface EventTypeProps {
    step2Prop?: Step2;

    setStep2Prop(val: Step2): void;

    currentPattern?: Pattern;

    setCurrentPattern(pattern: Pattern): void;
}

interface DayOfWeek {
    id: string;
    value: string;
    isSelected: boolean;
}

const PeriodicEvent: FC<EventTypeProps> = (props) => {
    const { setStep2Prop, step2Prop, setCurrentPattern, currentPattern } = props;

    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [dayOfWeeks, setDayOfWeeks] = useState<DayOfWeek[]>();

    const eventTypes: string[] = [t('daily'), t('weekly'), t('monthly')];

    let dayOfWeeksConsts: DayOfWeek[] = [
        {
            id: 'monday',
            value: t('MON'),
            isSelected: false
        },
        {
            id: 'tuesday',
            value: t('TUE'),
            isSelected: false
        },
        {
            id: 'wednesday',
            value: t('WED'),
            isSelected: false
        },
        {
            id: 'thursday',
            value: t('THU'),
            isSelected: false
        },
        {
            id: 'friday',
            value: t('FRI'),
            isSelected: false
        },
        {
            id: 'saturday',
            value: t('SAT'),
            isSelected: false
        },
        {
            id: 'sunday',
            value: t('SUN'),
            isSelected: false
        }
    ];

    useEffect(() => {
        setDayOfWeeks(dayOfWeeksConsts);

        let startParam = window.location.href.split('start=')[1] ?? '';

        if (startParam) {
            let parts = startParam.split('/');
            console.log(parts);
            console.log(parseInt(parts[2]));
            console.log(parseInt(parts[1]));
            console.log(parseInt(parts[0]));

            let start = new Date(`${parts[1]} ${parts[0]} ${parts[2]}`);
            console.log(isToday(start))
            if (isToday(start)) {
                let step2: Step2 = {
                    from: start,
                    to: addDays(start, 7),
                    fromTime:  addMinutes(new Date(), 30),
                    toTime: addMinutes(new Date(), 90)
                };
                setStep2Prop(step2);

                let defaultPattern: Pattern = {
                    type: t('daily'),
                    interval: 1,
                    dayOfMonth: 1,
                    daysOfWeek: [],
                    toDate: addDays(start, 7)
                };

                setCurrentPattern(deepClone(defaultPattern));

            } else {
                let step2: Step2 = {
                    from: start,
                    to: addDays(start, 7),
                    fromTime: addHours(start, 9),
                    toTime: addHours(start, 18)
                };
                setStep2Prop(step2);

                let defaultPattern: Pattern = {
                    type: t('daily'),
                    interval: 1,
                    dayOfMonth: 1,
                    daysOfWeek: [],
                    toDate: addDays(start, 7)
                };

                setCurrentPattern(deepClone(defaultPattern));
            }
        } else {
            let today = addMinutes(new Date(), 30);
            let step2: Step2 = {
                from: today,
                to: addDays(today, 7),
                fromTime: today,
                toTime: addHours(today, 1)
            };

            setStep2Prop(step2);

            let defaultPattern: Pattern = {
                type: t('daily'),
                interval: 1,
                dayOfMonth: 1,
                daysOfWeek: [],
                toDate: addDays(today, 7)
            };

            setCurrentPattern(deepClone(defaultPattern));
        }



    }, []);

    return (<>{currentPattern && step2Prop &&
    <Grid
        container
        justifyContent="center"
        display="inline"
    >
        <Box sx={{ textAlign: 'center', pt: 5, display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ mr: 1 }}>
                {isMobile
                    ? <MobileTimePicker
                        cancelText={t('CANCEL')}
                        label={t('START_HOUR')}
                        onChange={(date) => {
                            step2Prop.fromTime = date || new Date();
                            setStep2Prop(deepClone(step2Prop));
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={step2Prop?.fromTime}
                    />
                    : <DesktopTimePicker
                        orientation="landscape"
                        label={t('START_HOUR')}
                        onChange={(date) => {
                            step2Prop.fromTime = date || new Date();
                            setStep2Prop(deepClone(step2Prop));
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={step2Prop?.fromTime}
                    />}
            </Box>
            <Box sx={{ ml: 1 }}>
                {isMobile
                    ? <MobileTimePicker
                        cancelText={t('CANCEL')}
                        label={t('END_HOUR')}
                        onChange={(date) => {
                            step2Prop.toTime = date || new Date();
                            setStep2Prop(deepClone(step2Prop));
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={step2Prop?.toTime}
                    />
                    : <DesktopTimePicker
                        orientation="landscape"
                        label={t('END_HOUR')}
                        onChange={(date) => {
                            step2Prop.toTime = date || new Date();
                            setStep2Prop(deepClone(step2Prop));
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={step2Prop?.toTime}
                    />}
            </Box>
        </Box>
        <Box sx={{ textAlign: 'center', pt: 3, display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ mr: 1 }}>
                {isMobile
                    ? <MobileDatePicker
                        cancelText={t('CANCEL')}
                        minDate={new Date()}
                        inputFormat={t('DATE_PICKER_ONLY_DATE')}
                        label={t('START_DATE')}
                        onChange={(date) => {
                            step2Prop.from = date || new Date();
                            setStep2Prop(deepClone(step2Prop));
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={step2Prop?.from}
                    />
                    : <DesktopDatePicker
                        orientation="landscape"
                        minDate={new Date()}
                        inputFormat={t('DATE_PICKER_ONLY_DATE')}
                        label={t('START_DATE')}
                        onChange={(date) => {
                            step2Prop.from = date || new Date();
                            setStep2Prop(deepClone(step2Prop));
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={step2Prop?.from}
                    />}
            </Box>
            <Box sx={{ ml: 1 }}>
                {isMobile
                    ? <MobileDatePicker
                        cancelText={t('CANCEL')}
                        minDate={new Date()}
                        inputFormat={t('DATE_PICKER_ONLY_DATE')}
                        label={t('END_DATE')}
                        onChange={(date) => {
                            step2Prop.to = date || new Date();
                            setStep2Prop(deepClone(step2Prop));
                            currentPattern.toDate = date || new Date();
                            setCurrentPattern(deepClone(currentPattern));
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={step2Prop?.to}
                    />
                    : <DesktopDatePicker
                        orientation="landscape"
                        minDate={new Date()}
                        inputFormat={t('DATE_PICKER_ONLY_DATE')}
                        label={t('END_DATE')}
                        onChange={(date) => {
                            step2Prop.to = date || new Date();
                            setStep2Prop(deepClone(step2Prop));
                            currentPattern.toDate = date || new Date();
                            setCurrentPattern(deepClone(currentPattern));
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={step2Prop?.to}
                    />}
            </Box>
        </Box>
        <Box sx={{ p: 1 }}>
            <div style={{ paddingTop: '20px', textAlign: 'center' }}>
                <TextField
                    fullWidth
                    label={t('REPEAT')}
                    name="type"
                    onChange={(e: any) => {
                        currentPattern.type = e.target.value;
                        setCurrentPattern(deepClone(currentPattern));
                    }}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={currentPattern.type}
                    variant="outlined"
                    style={{ width: 420 }}
                >
                    {eventTypes?.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </TextField>
            </div>
            {currentPattern.type === t('daily') &&
            <div style={{ paddingTop: '20px', textAlign: 'center' }}>
                <TextField
                    label={t('DAY')}
                    name="type"
                    type="number"
                    onChange={(e: any) => {
                        currentPattern.interval = e.target.value;
                        setCurrentPattern(deepClone(currentPattern));

                    }}
                    SelectProps={{ native: true }}
                    value={currentPattern.interval}
                    variant="outlined"
                    style={{ width: 420 }}
                >
                </TextField>
            </div>}

            {currentPattern.type === t('weekly') && <>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <TextField
                        fullWidth
                        label={t('EVERY')}
                        name="type"
                        onChange={(e: any) => {
                            currentPattern.interval = e.target.value;
                            setCurrentPattern(deepClone(currentPattern));

                        }}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={currentPattern.interval}
                        variant="outlined"
                        style={{ width: 420 }}
                    >
                        {[...Array(26)].map((e, i) => {
                            return <option key={i + 1} value={i + 1}>{(i + 1).toString()}</option>
                        })}
                    </TextField>
                </div>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    {dayOfWeeks?.map((day) => {
                        return (<Button
                            key={day.id}
                            style={{ color: 'white', margin: '3px', background: day.isSelected ? '#688eff' : '' }}
                            size="small"
                            type="button"
                            variant="text"
                            onClick={() => {
                                dayOfWeeks.map((dayOfWeek) => {
                                    if (dayOfWeek.value === day.value) {
                                        dayOfWeek.isSelected = !dayOfWeek.isSelected;

                                        if (dayOfWeek.isSelected && currentPattern.daysOfWeek.indexOf(day.id) === -1) {
                                            currentPattern.daysOfWeek.push(day.id);
                                        } else {
                                            const index = currentPattern.daysOfWeek.indexOf(day.id);
                                            if (index > -1) {
                                                currentPattern.daysOfWeek.splice(index);
                                            }
                                        }
                                        setDayOfWeeks(deepClone(dayOfWeeks));
                                    }
                                })
                            }}
                        >
                            {day.value}
                        </Button>)
                    })}
                </div>
            </>}

            {currentPattern.type === t('monthly') &&
            <div style={{ textAlign: 'center', padding: '10px' }}>
                <TextField
                    fullWidth
                    label={t('EVERY')}
                    name="type"
                    type="number"
                    onChange={(e: any) => {
                        currentPattern.interval = e.target.value;
                        setCurrentPattern(deepClone(currentPattern));
                    }}
                    SelectProps={{ native: true }}
                    value={undefined}
                    variant="outlined"
                    style={{ width: 420 }}
                />
            </div>}
            <div style={{ padding: '5px', color: '#f44336' }}>
                {step2Prop.to.valueOf() < step2Prop.from.valueOf() && t('VALIDATION_CALENDAR_START_DATE')}
                {step2Prop.to.valueOf() === step2Prop.from.valueOf() && step2Prop.toTime.valueOf() < step2Prop.fromTime.valueOf() && t('VALIDATION_CALENDAR_START_DATE')}
                {new Date(currentPattern.toDate).getTime() - new Date(step2Prop.from).getTime() > 31556952000 && t('VALIDATION_CALENDAR_YEAR')}
                {currentPattern.type === 'weekly' && currentPattern.daysOfWeek.length < 1 && t('VALIDATION_CALENDAR_WEEK')}
            </div>
        </Box>
    </Grid>}
    </>);
}

export default PeriodicEvent;

