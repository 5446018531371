import axios from 'axios';
import ServiceConfig from "../config/serviceConfig";
import { Reservation } from '../types/reservation';
import { Resource } from '../types/resource';
import { Session, SmartDeskCommand, SmartDeskStatus } from '../types/smartDeskStatus';

export class SmartDeskService {
    head: any;
    constructor(apikey: string) {
        this.head = { headers: { Authorization: apikey } };
    }

    async getStatus(user: string, tenantId: string): Promise<SmartDeskCommand | null> {
        try {
            var body: any = {
                user: user,
                tenantId: tenantId
            };

            const response = await axios.post<SmartDeskCommand | null>(ServiceConfig.apiBaseUrl + "/SmartDesk/GetStatus", body, this.head);
            if (response.status === 200) {
                return response.data;
            }
            else if (response.status === 204) {
                return null;
            }
        }
        catch (error) {
            console.log(error);
        }
        return null;
    }

    async anySessioForDevice(deviceId: string, tenantId: string): Promise<Session | null> {
        try {
            var body: any = {
                deviceId: deviceId,
                tenantId: tenantId
            };

            const response = await axios.post<Session>(ServiceConfig.apiBaseUrl + "/SmartDesk/AnySessioForDevice", body, this.head);
            if (response.status === 200) {
                return response.data;
            }
        }
        catch (error) {
            console.log(error);
        }
        return null;
    }

    async setStatus(status: SmartDeskCommand, deviceId: string): Promise<boolean> {
        try {

            var body: any = {
                deviceId: deviceId,
                commandMessage: status
            };
            const response = await axios.post<SmartDeskStatus>(ServiceConfig.apiBaseUrl + "/SmartDesk/SetSmartDesk", body, this.head);
            if (response.status === 200) {
                return Boolean(response.data);

            }
            else {
                throw new Error("setStatus error");
            }
        }
        catch (error) {
            throw new Error("setStatus error:" + error);
        }
    }

    async startSession(deviceId: string, sessionId: string, tenantId: string): Promise<boolean> {
        try {

            var body: any = {
                deviceId: deviceId,
                sessionId: sessionId,
                tenantId: tenantId
            };

            const response = await axios.post<boolean>(ServiceConfig.apiBaseUrl + "/SmartDesk/StartSmartDeskSession", body, this.head);
            if (response.status === 200) {
                return true;
            }
        }
        catch (error) {
            throw new Error("startSession error:" + error);
        }
        return false;
    }

    async stopSession(sessionId: string, tenantId: string): Promise<boolean> {
        try {

            var body: any = {
                session_id: sessionId,
                tenant_id: tenantId
            };
            const response = await axios.post<boolean>(ServiceConfig.apiBaseUrl + "/SmartDesk/EndSmartDeskSession", body, this.head);
            if (response.status === 200) {
                return true;
            }
        }
        catch (error) {
            throw new Error("stopSession error:" + error);
        }
        return false;
    }

    async findDevice(id: string): Promise<Resource | null> {
        try {
            const response = await axios.get<Resource>(ServiceConfig.apiBaseUrl + "/SmartDesk/findDevice", {
                params: {
                    id: id
                },
                headers: this.head.headers
            });
            if (response.status === 200) {
                return response.data;
            }
        }
        catch (error) {
            console.log(error);
        }
        return null;
    }

}