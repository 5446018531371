import { FC, SetStateAction, useEffect } from 'react';
import { useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Grid, Slider, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ColorPicker, useColor, Color } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";

interface SmartLedStripProps {
    isSwitched: boolean;
    brightness: number;
    color: string;
    setIsSwitched(value: boolean): any;
    setBrightness(value: number): any;
    setColor(value: string): any;
}

const SmartLedStrip: FC<SmartLedStripProps> = (props) => {
    const { t } = useTranslation();
    const { color, isSwitched, brightness, setIsSwitched, setBrightness, setColor } = props;

    const [internalColor, setInternalColor] = useColor("hex", color);

    const colorToHex = (value: SetStateAction<Color>) => {
        setInternalColor(value);

    };

    function valuetext(value: number) {
        return `${value} %`;
    }

    return (
        <Grid
            sx={{ py: 2 }}
            item
            md={6}
            sm={6}
            xs={12}
        >
            <Card>
                <CardHeader
                    disableTypography
                    title={(
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {t('SMART_LED_STRIP')}
                            </Typography>
                        </Box>
                    )}
                />
                <CardContent>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            py: 0
                        }}>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('LED_STRIP')}
                        </Typography>
                        <Switch color="primary" checked={isSwitched} onClick={() => {
                            setIsSwitched(!isSwitched);
                        }} />
                    </Box>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            textAlign: 'center',
                            py: 0,
                            width: '100%',
                            placeContent: 'center'
                        }}>
                        <ColorPicker width={400} height={100} color={internalColor} hideHSB={true} hideRGB={true} onChange={colorToHex} dark />
                    </Box>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            textAlign: 'center',
                            py: 1,
                            width: '100%',
                            placeContent: 'center'
                        }}>
                        <Button
                            color="primary"
                            onClick={() => {
                                setColor(internalColor.hex);
                            }}
                            size="large"
                            type="button"
                            variant="text"
                        >
                            {t('IMPOSTA COLORE')}
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            py: 1
                        }}>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('INTENSITY')}
                        </Typography>
                        <Slider
                            sx={{ mx: 2 }}
                            value={brightness}
                            defaultValue={100}
                            getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            min={0}
                            max={100}
                            onChange={(e, number) => {
                                setBrightness(number as number);
                            }}
                        />
                    </Box>
                </CardContent>
            </Card>

        </Grid>
    );
};

export default SmartLedStrip;
