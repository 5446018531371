import type { FC } from 'react';
import { useEffect, useState } from 'react';
import {
    Badge,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    Divider,
    Link,
    ListItem,
    ListItemText,
    TextField,
    Typography
} from '@material-ui/core';
import { DashboardService } from '../../services/DashboardService';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import { format } from 'date-fns';
import { toLocalDate } from '../../utils/common';
import User from '../../icons/User';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { it } from "date-fns/locale";
import { ReservationService } from '../../services/ReservationService';
import RoomIcon from '@material-ui/icons/Room';

export interface ReservationItemChart {
    id: string;
    resourceId: string;
    resourceName: string;
    title: string;
    description: string;
    isPeriodic: boolean;
    timezoneId: string;
    dateTimeFrom: Date;
    dateTimeTo: Date;
    emails: string[];
    checkInStatus: number;
}

const NextReservationChart: FC = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const locales = { it };
    const history = useNavigate();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);

    const [reservation, setReservation] = useState<ReservationItemChart>();
    const [note, setNote] = useState<string>();
    const [isCheckinModalOpened, setIsCheckinModalOpened] = useState<boolean>(false);

    const dashboardService = new DashboardService();
    const reservationService = new ReservationService();

    useEffect(() => {
        (async () => {
            try {
                if (userInfo) {
                    let res = await dashboardService.getNextReservation(userInfo.tenantId, userInfo.roles);
                    setReservation(res);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    return (<>
        <Box
            sx={{
                backgroundColor: 'background.default',
                alignContent: 'center',
                width: '100%',
                pb: 2
            }}
        >
            <Card>
                <CardHeader
                    disableTypography
                    title={(
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography
                                color="textPrimary"
                                variant="h6"
                            >
                                {t('YOUR_NEXT_RESERVATION')}
                            </Typography>
                        </Box>
                    )}
                />
                <CardContent>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}
                    > {!reservation
                        ? <Box
                            sx={{
                                alignItems: 'center',
                                width: '100%'
                            }}>
                            <Typography
                                align="center"
                                color="textPrimary"
                                variant="h4"
                            >
                                {'-'}
                            </Typography>
                        </Box>
                        : <Box
                            sx={{
                                width: '100%'
                            }}>
                            <Typography
                                align="center"
                                color="textSecondary"
                                variant="h5"
                            >
                                {format(toLocalDate(reservation.dateTimeFrom, reservation.timezoneId), 'dd MMM', { locale: locales['it'] })?.toUpperCase()}
                            </Typography>
                            <Typography
                                align="center"
                                color="textSecondary"
                                variant="h6"
                            >
                                {format(toLocalDate(reservation.dateTimeFrom, reservation.timezoneId), 'HH:mm')}
                            </Typography>

                            <ListItem
                                disableGutters
                                key={'res'}
                                sx={{
                                    pt: 3
                                }}
                            >
                                <ListItemText
                                    disableTypography
                                    primary={(
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Badge
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }}
                                                variant="dot"
                                                sx={{
                                                    pl: '20px',
                                                    '& .MuiBadge-badge': {
                                                        backgroundColor: '#ff9900',
                                                        left: 6,
                                                        top: 11
                                                    }
                                                }}
                                            >
                                                <Typography
                                                    color="textPrimary"
                                                    variant="subtitle2"
                                                >
                                                    {reservation.resourceName}
                                                </Typography>
                                            </Badge>
                                            <Typography
                                                color="textSecondary"
                                                variant="subtitle2"
                                                sx={{ mb: 1 }}
                                            >
                                                <Link
                                                    color="primary"
                                                    variant="subtitle2"
                                                    component={RouterLink}
                                                    to={`/resources/detail?map=true&id=${reservation.resourceId}`}
                                                >
                                                    <RoomIcon />
                                                </Link>
                                            </Typography>
                                        </Box>
                                    )}
                                />
                            </ListItem>
                            <Divider />
                            <Typography
                                color="textSecondary"
                                variant="subtitle2"
                                sx={{ pb: 1, pt: 3 }}
                            >
                                {reservation?.emails?.map(email => {
                                    return (
                                        <Typography
                                            color="textSecondary"
                                            variant="subtitle2"
                                            sx={{ p: 0.5 }}
                                            key={email}
                                        >
                                            <span>
                                                <User />
                                                <span style={{ paddingLeft: '5px', lineBreak: 'anywhere' }}>
                                                    {email}
                                                </span>
                                            </span>

                                        </Typography>

                                    )
                                })}
                            </Typography>
                        </Box>}
                    </Box>
                </CardContent>
                <Divider />
                {reservation?.checkInStatus === 1 && <Box
                    sx={{
                        px: 3,
                        py: 2,
                        textAlign: 'center'
                    }}
                >
                    <Button
                        color="primary"
                        sx={{ m: 1 }}
                        variant="contained"
                        onClick={() => setIsCheckinModalOpened(true)}
                    >
                        {t('Check-In')}
                    </Button>
                </Box>}
                {reservation?.checkInStatus === 2 && <Box
                    sx={{
                        px: 3,
                        py: 2,
                        textAlign: 'center'
                    }}
                >
                    <Typography
                        color="textPrimary"
                        variant="h6"
                    >
                        {t('CHECKIN_COMPLETED')}
                    </Typography>
                </Box>}
            </Card>
        </Box>
        <Dialog
            fullWidth
            maxWidth="sm"
            onClose={() => setIsCheckinModalOpened(false)}
            open={isCheckinModalOpened}
        >
            <Box
                sx={{
                    p: 3,
                    textAlign: 'center'
                }}>
                <Typography
                    color="textPrimary"
                    variant="h6"
                >
                    {t('CONFIRM_CHECKIN')}
                </Typography>
                <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    label={t('OPTIONAL_NOTE')}
                    name="note"
                    variant="outlined"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
                <Button
                    color="primary"
                    sx={{ m: 1, mt: 5 }}
                    variant="contained"
                    onClick={async () => {
                        await reservationService.executeCheckIn(reservation?.id || '', note || '');
                        enqueueSnackbar(t('CHECKIN_COMPLETED'), {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'success'
                        });
                        history('/igloo');
                        history('/');
                        setIsCheckinModalOpened(false);
                    }}
                >
                    {t('CONFIRM')}
                </Button>
            </ Box>
        </Dialog>
    </>);
}

export default NextReservationChart;