import axios from 'axios';
import { ReservationItemChart } from '../components/dashboard/NextReservation';
import { ReservationDifference } from '../components/dashboard/ReservationDifferenceByMonth';
import { DashboardGraph } from '../components/dashboard/ReservationGraph';
import ServiceConfig from "../config/serviceConfig";
import { ResourcesCountsDonut } from '../components/dashboard/ResourcesCountDonut';

export class DashboardService {

    async getNumberOfResources(tenantId: string, roles: string[]): Promise<number> {
        try {

            var encodedRoles = roles?.map(role => {
                return role = btoa(role)
            })

            var body: any = {
                rs: encodedRoles,
                tenantId: tenantId
            };

            const response = await axios.post<number>(ServiceConfig.serviceBaseUrl + "/GetNumberOfResources", body);

            if (response.status < 300) {
                return response.data;
            }
            else {
                throw new Error("getNumberOfResources error");
            }
        }
        catch (error) {
            throw new Error("getNumberOfResources error:" + error);
        }
    }

    async getReservationDifferenceByMonth(tenantId: string, roles: string[]): Promise<ReservationDifference> {
        try {

            var encodedRoles = roles?.map(role => {
                return role = btoa(role)
            })

            var body: any = {
                rs: encodedRoles,
                tenantId: tenantId
            };

            const response = await axios.post<ReservationDifference>(ServiceConfig.serviceBaseUrl + "/GetReservationDifferenceByMonth", body);

            if (response.status < 300) {
                return response.data;
            }
            else {
                throw new Error("getNumberOfResources error");
            }
        }
        catch (error) {
            throw new Error("getNumberOfResources error:" + error);
        }
    }

    async getReservationDifferenceByWeek(tenantId: string, roles: string[]): Promise<ReservationDifference> {
        try {

            var encodedRoles = roles?.map(role => {
                return role = btoa(role)
            })

            var body: any = {
                rs: encodedRoles,
                tenantId: tenantId
            };

            const response = await axios.post<ReservationDifference>(ServiceConfig.serviceBaseUrl + "/GetReservationDifferenceByWeek", body);

            if (response.status < 300) {
                return response.data;
            }
            else {
                throw new Error("getReservationDifferenceByWeek error");
            }
        }
        catch (error) {
            throw new Error("getReservationDifferenceByWeek error:" + error);
        }
    }

    async getDashboardGraph(tenantId: string, roles: string[]): Promise<DashboardGraph> {
        try {

            var encodedRoles = roles?.map(role => {
                return role = btoa(role)
            })

            var body: any = {
                rs: encodedRoles,
                tenantId: tenantId
            };

            const response = await axios.post<DashboardGraph>(ServiceConfig.serviceBaseUrl + "/GetDashboardGraph", body);

            if (response.status < 300) {
                return response.data;
            }
            else {
                throw new Error("GetDashboardGraph error");
            }
        }
        catch (error) {
            throw new Error("GetDashboardGraph error:" + error);
        }
    }

    async getNextReservation(tenantId: string, roles: string[]): Promise<ReservationItemChart> {
        try {

            var encodedRoles = roles?.map(role => {
                return role = btoa(role)
            })

            var body: any = {
                rs: encodedRoles,
                tenantId: tenantId
            };

            const response = await axios.post<ReservationItemChart>(ServiceConfig.serviceBaseUrl + "/NextReservation", body);

            if (response.status < 300) {
                return response.data;
            }
            else {
                throw new Error("GetDashboardGraph error");
            }
        }
        catch (error) {
            throw new Error("GetDashboardGraph error:" + error);
        }
    }

    async getResourceCountByType(tenantId: string, roles: string[]): Promise<ResourcesCountsDonut> {
        try {

            var encodedRoles = roles?.map(role => {
                return role = btoa(role)
            })

            var body: any = {
                rs: encodedRoles,
                tenantId: tenantId
            };

            const response = await axios.post<ResourcesCountsDonut>(ServiceConfig.serviceBaseUrl + "/GetResourceCountByType", body);

            if (response.status < 300) {
                return response.data;
            }
            else {
                throw new Error("GetDashboardGraph error");
            }
        }
        catch (error) {
            throw new Error("GetDashboardGraph error:" + error);
        }
    }
}