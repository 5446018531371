import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz';
import { UserInfo } from '../models/UserInfo';

export function deepClone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
}

export function StringIsNullOrEmpty(str: string) {
    return (!str || 0 === str.toString().trim().length);
}

export function StringIsNotNullOrEmpty(str: string) {
    return !(!str || 0 === str.toString().trim().length);
}

export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toLocalDate(date: any, timezoneId: string) {
    var offsetStart = getTimezoneOffset(timezoneId);
    return new Date(new Date(date).getTime() + offsetStart);
}

export function toUTCDate(date: Date) {
    return zonedTimeToUtc(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function userCanUseIfRoles(userInfo: UserInfo | undefined, acceptedRoles: string[]) {
    if (!userInfo?.roles || userInfo.roles.length < 1)
        return false;

    if (userInfo && userInfo.roles.some(userRole => acceptedRoles.includes(userRole)))
        return true;

    return false;
}

export function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function isToday(someDate : any)  {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
}

