import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@material-ui/core';
import UserAddForm from '../components/user/UserAddForm';
import ChevronRightIcon from '../icons/ChevronRight';
import { User } from '../models/User';
import { useSelector } from "react-redux";
import { UserService } from '../services/UserService';
import { AppState } from "../store";
import { UserInfo } from "../models/UserInfo";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

interface UserAddProps {
  isAdd: boolean;
}

const UserAdd: FC<UserAddProps> = (props) => {
  const { isAdd } = props;
  const [user, setUser] = useState<User | null>(null);
  const [isAddMode, setIsAddMode] = useState<boolean>();
  const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
  const history = useNavigate();
  const { t } = useTranslation();
  var currentTheme = useTheme();

  useEffect(() => {
    setIsAddMode(isAdd);
    (async () => {
      if (isAdd) {
        const usr: User = {
          id: '',
          address: '',
          avatar: '',
          city: '',
          country: '',
          email: '',
          name: '',
          surname: '',
          phone: '',
          state: '',
          tenantId: userInfo?.tenantId ?? '',
          preferredLanguage: '',
          currentTenantName: '',
          zipCode: '',
          roles: []
        };
        setUser(usr);
      } else {
        try {
          if (userInfo) {
            let userId = window.location.href.split('userId=')[1] ?? '';
            var userService = new UserService();
            var uer = await userService.findUser(userId, userInfo.tenantId);
            setUser(uer);
          }
        } catch (err) {
          console.error(err);
          history('/500');
        }
      }
    })();
  }, [isAdd, isAddMode, userInfo]);

  if (!user) {
    return null;
  }

  let labelTitle = t('UPDATE_USER');
  let navigationTitle = t('EDIT');

  if (isAdd) {
    labelTitle = t('ADD_USER');
    navigationTitle = t('ADD');
  }


  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 2,
        [currentTheme.breakpoints.up('lg')]: {
          paddingLeft: '300px'
        }
      }}
    >
      <Container>
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {labelTitle}
            </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRightIcon fontSize="small" />}
              sx={{ mt: 1 }}
            >
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/"
                variant="subtitle2"
              >
                {t('DASHBOARD')}
              </Link>
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/users"
                variant="subtitle2"
              >
                {t('USERS')}
              </Link>
              <Typography
                color="textSecondary"
                variant="subtitle2"
              >
                {navigationTitle}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Box mt={3}>
          <UserAddForm user={user} isAdd={isAddMode!} />
        </Box>
      </Container>
    </Box>
  );
};


export default UserAdd;
