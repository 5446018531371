export const countryCodes =
    [
        { id: 1, code: 'IT', language: 'Italian' },
        { id: 2, code: 'GB', language: 'English' },
        { id: 3, code: 'ES', language: 'Spanish' },
        { id: 4, code: 'DE', language: 'German' },
        { id: 5, code: 'FR', language: 'French' }
    ]

export const cultureIt = "it";
export const cultureEn = "en";