import axios from 'axios';
import { Tenant } from '../models/Tenant';
import ServiceConfig from '../config/serviceConfig';

export class TenantService {

    async getTenantList(): Promise<Tenant[]> {
        try{            
            const response = await axios.get<Tenant[]>(ServiceConfig.serviceBaseUrl + '/ListTenants');
            if (response.status === 200) {
                return response.data;
            }
            else{
                throw new Error("Get tenant list error");
            }
        }
        catch(error){
            throw new Error("Get tenant list error:" + error);
        }
     }

     async createOrUpdateTenant(tenant: Tenant): Promise<boolean> {
        try{
            const response = await axios.post<Tenant>(ServiceConfig.serviceBaseUrl + '/CreateOrUpdateTenant', tenant);
            if (response.status === 200) {
                return Boolean(response.data);
            }
            else{
                throw new Error("Add tenant error");
            }
        }
        catch(error){
            throw new Error("Add tenant error:" + error);
        }
     }

}