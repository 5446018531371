import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import { deepClone } from '../../utils/common';
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import { Pattern } from '../../types/reservation';
import { useTranslation } from 'react-i18next';

interface DayOfWeek {
    id: string;
    value: string;
    isSelected: boolean;
}

interface PatternProps {
    patternProp?: Pattern;
    isPeriodic: boolean;
    confirmClicked: boolean;
    updatePattern?: (pattern: Pattern) => void;
    startDate: Date;
}

const CalendarPeriodSection: FC<PatternProps> = (props) => {
    const { patternProp, updatePattern, isPeriodic, confirmClicked, startDate } = props;
    const { t } = useTranslation();

    let dayOfWeeksConsts: DayOfWeek[] = [
        {
            id: 'monday',
            value: t('MON'),
            isSelected: false
        },
        {
            id: 'tuesday',
            value: t('TUE'),
            isSelected: false
        },
        {
            id: 'wednesday',
            value: t('WED'),
            isSelected: false
        },
        {
            id: 'thursday',
            value: t('THU'),
            isSelected: false
        },
        {
            id: 'friday',
            value: t('FRI'),
            isSelected: false
        },
        {
            id: 'saturday',
            value: t('SAT'),
            isSelected: false
        },
        {
            id: 'sunday',
            value: t('SUN'),
            isSelected: false
        }
    ];

    const eventTypes: string[] = [t('daily'), t('weekly'), t('monthly')];

    const [currentPattern, setCurrentPattern] = useState<Pattern>();
    const [dayOfWeeks, setDayOfWeeks] = useState<DayOfWeek[]>();

    useEffect(() => {
        setDayOfWeeks(dayOfWeeksConsts);
        if (!patternProp) {

            var defaultPattern: Pattern = {
                type: t('daily'),
                interval: 1,
                dayOfMonth: 1,
                daysOfWeek: [],
                toDate: new Date()
            };

            setCurrentPattern(defaultPattern);
        }
        else {
            patternProp.type = t(patternProp.type);
            setCurrentPattern(patternProp);
            patternProp?.daysOfWeek?.map(dow => {
                dayOfWeeksConsts?.map(d => {
                    if (d.id === dow) {
                        d.isSelected = true;
                    }
                })
            })
            setDayOfWeeks(dayOfWeeksConsts);
        }

        return (() => {
            setDayOfWeeks(dayOfWeeksConsts);
        })
    }, []);

    return (currentPattern ? <>
        <Box sx={{ p: 1 }}>
            <TextField
                fullWidth
                label={t('REPEAT')}
                name="type"
                onChange={(e: any) => {
                    currentPattern.type = e.target.value;
                    setCurrentPattern(deepClone(currentPattern));
                    if (updatePattern)
                        updatePattern(currentPattern);
                }}
                required
                select
                SelectProps={{ native: true }}
                value={currentPattern.type}
                variant="outlined"
            >
                {eventTypes?.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </TextField>

            {currentPattern.type === t('daily') && <div style={{ paddingTop: '10px' }}>
                <TextField
                    error={Boolean(currentPattern.interval > 182 || currentPattern.interval < 1)}
                    fullWidth
                    label={t('DAY')}
                    name="type"
                    type="number"
                    onChange={(e: any) => {
                        currentPattern.interval = e.target.value;
                        setCurrentPattern(deepClone(currentPattern));
                        if (updatePattern)
                            updatePattern(currentPattern);
                    }}
                    SelectProps={{ native: true }}
                    value={currentPattern.interval}
                    variant="outlined"
                >
                </TextField>
            </div>}

            {currentPattern.type === t('weekly') && <div style={{ textAlign: 'center', padding: '10px' }}>
                <TextField
                    fullWidth
                    label={t('EVERY')}
                    name="type"
                    onChange={(e: any) => {
                        currentPattern.interval = e.target.value;
                        setCurrentPattern(deepClone(currentPattern));
                        if (updatePattern)
                            updatePattern(currentPattern);
                    }}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={currentPattern.interval}
                    variant="outlined"
                >
                    {[...Array(26)].map((e, i) => {
                        return <option key={i + 1} value={i + 1}>{(i + 1).toString()}</option>
                    })}
                </TextField>
                {dayOfWeeks?.map((day) => {
                    return (<Button
                        key={day.id}
                        style={{ color: 'white', margin: '3px', background: day.isSelected ? '#688eff' : '' }}
                        size="small"
                        type="button"
                        variant="text"
                        onClick={() => {
                            dayOfWeeks.map((dayOfWeek) => {
                                if (dayOfWeek.value === day.value) {
                                    dayOfWeek.isSelected = !dayOfWeek.isSelected;

                                    if (dayOfWeek.isSelected && currentPattern.daysOfWeek.indexOf(day.id) === -1) {
                                        currentPattern.daysOfWeek.push(day.id);
                                    } else {
                                        const index = currentPattern.daysOfWeek.indexOf(day.id);
                                        if (index > -1) {
                                            currentPattern.daysOfWeek.splice(index);
                                        }
                                    }
                                    setDayOfWeeks(deepClone(dayOfWeeks));
                                }
                            })
                            if (updatePattern)
                                updatePattern(currentPattern);
                        }}
                    >
                        {day.value}
                    </Button>)
                })}
            </div>}

            {currentPattern.type === t('monthly') && <div style={{ marginTop: '10px' }}>
                <TextField
                    error={Boolean(currentPattern.interval > 7 || currentPattern.interval < 1)}
                    fullWidth
                    label={t('EVERY')}
                    name="type"
                    type="number"
                    onChange={(e: any) => {
                        currentPattern.interval = e.target.value;
                        setCurrentPattern(deepClone(currentPattern));
                        if (updatePattern)
                            updatePattern(currentPattern);
                    }}
                    SelectProps={{ native: true }}
                    value={currentPattern.interval}
                    variant="outlined"
                />
            </div>}

            <div style={{ marginTop: '10px' }}>
                    <MobileDatePicker
                        cancelText={t('CANCEL')}
                        minDate={startDate}
                        disablePast={true}
                        inputFormat={t('DATE_PICKER_ONLY_DATE')}
                        label={t('DUE_DATE')}
                        onChange={(date) => {
                            if (date) {
                                currentPattern.toDate = date;
                                setCurrentPattern(deepClone(currentPattern));
                                if (updatePattern)
                                    updatePattern(currentPattern);
                            }
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                error={Boolean(confirmClicked && isPeriodic && currentPattern.toDate.valueOf() < startDate.valueOf())}
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={currentPattern.toDate}
                    />
            </div>
            <div style={{ padding: '5px', color: '#f44336' }}>
                {confirmClicked && isPeriodic && currentPattern.toDate.valueOf() < startDate.valueOf()
                    && t('VALIDATION_CALENDAR_START_DATE')}
                {confirmClicked && isPeriodic && new Date(currentPattern.toDate).getTime() - new Date(startDate).getTime() > 31556952000
                    && t('VALIDATION_CALENDAR_YEAR')}
                {confirmClicked && isPeriodic && currentPattern.type === 'weekly' && currentPattern.daysOfWeek.length < 1
                    && t('VALIDATION_CALENDAR_WEEK')}
            </div>
        </Box>
    </> : <></>);
}




export default CalendarPeriodSection;