import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Autocomplete, Box, Card, CardContent, Chip, List, ListItem, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ResourceWithDetail } from '../../../types/resourceType';
import { Step2 } from './SingleEvent';
import { format } from "date-fns";
import { ResourceService } from "../../../services/ResourceService";
import { deepClone, isValidEmail } from "../../../utils/common";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { UserInfo } from "../../../models/UserInfo";

interface EventTypeProps {
    step1IsSingleSelectedProp: boolean;
    step2Prop?: Step2;
    step3SelectedResource?: ResourceWithDetail;
    participants: string[];
    setParticipants(emails: string[]): void;
    reservationName: string;
    setReservationName(name: string): void;
}

function getPeriodicTime(step1IsSingleSelectedProp: boolean, step2Prop: Step2 | undefined, t: any) {
    if (step1IsSingleSelectedProp && step2Prop?.from && step2Prop?.to) {
        return ' ' + format(new Date(step2Prop?.from), t('TIME_FORMAT')) + ' - ' + format(new Date(step2Prop?.to), t('TIME_FORMAT'));
    }

    return step2Prop && step2Prop?.fromTime && step2Prop?.toTime
        ? ' ' + format(new Date(step2Prop?.fromTime), t('TIME_FORMAT')) + ' - ' + format(new Date(step2Prop?.toTime), t('TIME_FORMAT'))
        : '';
}

function getFromAndToDate(step1IsSingleSelectedProp: boolean, step2Prop: Step2 | undefined, t: any) {

    return step2Prop && step2Prop?.from && step2Prop?.to
        ? format(new Date(step2Prop?.from), t('DATE_PICKER_ONLY_DATE')) + ' - ' + format(new Date(step2Prop?.to), t('DATE_PICKER_ONLY_DATE'))
        : '';
}

const CompleteEvent: FC<EventTypeProps> = (props) => {
    const { t } = useTranslation();
    const {
        step1IsSingleSelectedProp, step2Prop, step3SelectedResource,
        participants, setParticipants, setReservationName, reservationName
    } = props;

    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);

    let resourceService = new ResourceService();

    const [searchedEmail, setSearchedEmail] = useState<string>('');
    const [emailOptions, setEmailOptions] = useState<string[]>([]);
    const [maxUserReached, setMaxUserReached] = useState<boolean>(false);

    useEffect(() => {
        if (participants)
            setParticipants([]);
        if (userInfo) {
            if (!participants.includes(userInfo.email))
                participants.push(userInfo?.email);
            setParticipants(deepClone(participants));
        }
    }, []);

    useEffect(() => {
        if (userInfo) {
            (async () => {
                let res = await resourceService.getAutoCompleteEmails(userInfo?.tenantId, searchedEmail);
                setEmailOptions(res);
            })();
        }
    }, [searchedEmail])

    return (<>
        <Card sx={{ mt: 4 }}>
            <CardContent>
                <List>
                    <ListItem
                        disableGutters
                        divider
                        sx={{
                            justifyContent: 'space-between',
                            padding: 2
                        }}
                    >
                        <Typography
                            color="textPrimary"
                            variant="subtitle2"
                        >
                            {t('SELECTED_RESOURCE')}
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                            {step3SelectedResource?.name} ({step3SelectedResource?.resourceTypeName})
                        </Typography>
                    </ListItem>
                    <ListItem
                        disableGutters
                        divider
                        sx={{
                            justifyContent: 'space-between',
                            padding: 2
                        }}
                    >
                        <Typography
                            color="textPrimary"
                            variant="subtitle2"
                        >
                            {t('TYPOLOGY_OF_RESOURCE')}:{step1IsSingleSelectedProp ? t('SINGLE_WHITESPACE') : t('IS_PERIODIC_WHITESPACE')}
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                            {getFromAndToDate(step1IsSingleSelectedProp, step2Prop, t)}
                            {getPeriodicTime(step1IsSingleSelectedProp, step2Prop, t)}
                        </Typography>
                    </ListItem>
                </List>
                <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ pl: 2, pb: 2, pt: 1 }}
                >
                    {t('NAME')}
                </Typography>
                <Box sx={{ pl: 2 }}>
                    <TextField
                        fullWidth
                        label={t('ADD_NAME_RESERVATION')}
                        name="title"
                        onChange={(e) => {
                            setReservationName(e.target.value);
                        }}
                        value={reservationName}
                        variant="outlined"
                    />
                </Box>
                <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ pl: 2, pb: 2, pt: 1 }}
                >
                    {t('PARTICIPANTS')}
                </Typography>
                <Autocomplete
                    sx={{ pl: 2, pt: 0 }}
                    multiple
                    id="tags-filled"
                    options={emailOptions}
                    defaultValue={[userInfo?.email || '']}
                    freeSolo
                    renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip
                                variant="outlined"
                                label={option} {...getTagProps({ index })}
                                onDelete={() => {
                                    let filteredParticipants = participants.filter(p => p !== option);
                                    setParticipants(deepClone(filteredParticipants));
                                    if (filteredParticipants.length <= (step3SelectedResource?.effectiveCapacity || 100)) {
                                        setMaxUserReached(false);
                                    }
                                }} />
                        ))
                    }
                    onChange={(event, value) => {
                        if (!participants)
                            setParticipants([]);
                        let p = value?.filter(v => isValidEmail(v));
                        if (p.length <= (step3SelectedResource?.effectiveCapacity || 100)) {
                            setParticipants(p);
                            setMaxUserReached(false);
                        } else {
                            setMaxUserReached(true)
                        }
                    }}
                    value={participants}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label=""
                            placeholder=""
                            onChange={(evt) => {
                                setSearchedEmail(evt.target.value);
                            }}
                        />
                    )}
                />
                {maxUserReached && <Typography
                    sx={{ pl: 2 }}
                    color="error"
                    variant="subtitle2"
                >
                    {t('MAXIMUM_USERS_RESOURCE')}
                </Typography>}
            </CardContent>
        </Card>
    </>)
}


export default CompleteEvent;
