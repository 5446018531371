import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { addHours, format } from 'date-fns';
import MobileDateTimePicker from '@material-ui/lab/MobileDateTimePicker';
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Dialog,
    Divider,
    FormControlLabel,
    IconButton, Link,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import MultiSelect from '../MultiSelect';
import { UserService } from '../../services/UserService';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import CalendarPeriodSection from './CalendarPeriodicSection';
import { ResourceService } from '../../services/ResourceService';
import { ResourceSelectItem } from '../../types/resource';
import { ReservationService } from '../../services/ReservationService';
import { Pattern, Reservation } from '../../types/reservation';
import { v4 as uuidv4 } from 'uuid';
import { deepClone, isValidEmail, toUTCDate, userCanUseIfRoles } from '../../utils/common';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { addDays } from 'date-fns/esm';
import TrashIcon from '../../icons/Trash';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import Scrollbar from '../Scrollbar';
import { ResponseResult } from '../../types/responseResult';
import CanInsertResultError from './CanInsertResultError';
import RoomIcon from "@material-ui/icons/Room";

interface CalendarEventFormProps {
    eventId?: string;
    onAddComplete?: () => void;
    onCancel?: () => void;
    range?: { start: Date; end: Date };
    selectedResource?: ResourceSelectItem;
    isReadOnly: boolean;
}

const CalendarEventForm: FC<CalendarEventFormProps> = (props) => {
    const { eventId, onAddComplete, onCancel, range, selectedResource, isReadOnly } = props;
    const { enqueueSnackbar } = useSnackbar();
    const history = useNavigate();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const { t } = useTranslation();

    const [userSelectItems, setUserSelectItems] = useState<string[]>();
    const [selectedUsersSelectItem, setSelectedUsersSelectItem] = useState<string[]>();
    const [selectedResourceSelectItem, setSelectedResourceSelectItem] = useState<ResourceSelectItem>();
    const [reservation, setReservation] = useState<Reservation>();
    const [confirmClicked, setConfirmClicked] = useState<boolean>(false);
    const [isDeletePeriodicEventModalOpen, setIsDeletePeriodicEventModalOpen] = useState<boolean>(false);
    const [isDeleteSingleEventModalOpen, setIsDeleteSingleEventModalOpen] = useState<boolean>(false);
    const [isCanInsertResultModalOpen, setIsCanInsertResultModalOpen] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [canInsertResultError, setCanInsertResultError] = useState<ResponseResult>();
    const [searchedEmail, setSearchedEmail] = useState<string>('');
    const [emailOptions, setEmailOptions] = useState<string[]>([]);
    const [maxUserReached, setMaxUserReached] = useState<boolean>(false);

    const userService = new UserService();
    const resourceService = new ResourceService();
    const reservationService = new ReservationService();

    const emptyReservation: Reservation = {
        id: uuidv4(),
        title: '',
        description: '',
        tenantId: userInfo?.tenantId?.toString(),
        isPeriodic: false,
        resourceId: '',
        timezoneId: '',
        dateTimeFrom: new Date(),
        dateTimeTo: addHours(new Date(), 1),
        isPublic: false,
        emails: [],
        editAllNewEvents: false,
        pattern: {
            type: 'daily',
            interval: 1,
            dayOfMonth: 1,
            daysOfWeek: [],
            toDate: addDays(new Date(), 31)
        }
    };

    useEffect(() => {
        if (userInfo) {
            (async () => {
                let res = await resourceService.getAutoCompleteEmails(userInfo?.tenantId, searchedEmail);
                setEmailOptions(res);
            })();
        }
    }, [searchedEmail])

    useEffect(() => {
        (async () => {
            try {
                if (userInfo) {
                    setSelectedResourceSelectItem(selectedResource);

                    let selectItems = await userService.getUserSelectItems(userInfo.tenantId);
                    setUserSelectItems(selectItems?.map(({ email }) => email));

                    let selUsers = selectItems?.filter(s => s.email === userInfo.email)?.map(s => s.email);
                    setSelectedUsersSelectItem(selUsers);

                    emptyReservation.emails = selUsers;
                    setReservation(deepClone(emptyReservation));
                }
            } catch {
                history('/500');
            }
        })();

        if (eventId) {
            try {
                (async () => {
                    if (userInfo) {
                        let [reservation, resources] = await Promise.all([
                            reservationService.findReservationById(eventId),
                            resourceService.getResourceSelectItems(userInfo.tenantId, userInfo.email)]);

                        let selectedResource = resources.filter(res => res.id === reservation?.resourceId)[0];
                        setSelectedResourceSelectItem(selectedResource);

                        setSelectedUsersSelectItem(reservation.emails);
                        setReservation(deepClone(reservation));

                        console.log(reservation)
                    }
                })();
            } catch {
                history('/500');
            }

        } else {
            emptyReservation.resourceId = selectedResource?.id;
            emptyReservation.dateTimeFrom = range?.start || new Date();
            emptyReservation.dateTimeTo = range?.end || new Date();

            setReservation(deepClone(emptyReservation));
            setSelectedResourceSelectItem(selectedResource);
        }
    }, [userInfo, range, eventId]);

    const updatePattern = (newPattern: Pattern): void => {
        if (reservation) {
            reservation.pattern = newPattern;
            setReservation(deepClone(reservation));
        }
    }

    async function deleteReservationAsync(reservationId: string, editAllNewEvents: boolean) {
        try {
            setIsLoading(true);
            setIsDeletePeriodicEventModalOpen(false);
            setIsDeleteSingleEventModalOpen(false);
            await reservationService.deleteReservationEvent(reservationId, editAllNewEvents);
            enqueueSnackbar(t('RERSERVATION_DELETED'), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
        } catch {
            history('/500')
        }
        setIsLoading(false);
    }

    async function upsertReservationAsync(reservation: Reservation, reservationIsValid: boolean) {
        try {
            setIsLoading(true);
            if (reservationIsValid) {
                if (!isCreating && reservation.isPeriodic) {
                    setIsDeletePeriodicEventModalOpen(false);
                }

                reservation.timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
                var canInsert = await reservationService.canInsertReservation(reservation);
                console.log(canInsert);
                if (canInsert.result) {
                    await reservationService.upsertReservation(reservation);
                    enqueueSnackbar(isCreating ? t('RERSERVATION_CREATED') : t('RERSERVATION_UPDATED'), {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'success'
                    });
                    if (onAddComplete)
                        onAddComplete();
                } else {
                    setIsCanInsertResultModalOpen(true);
                    setCanInsertResultError(canInsert);
                }

            } else {
                console.log('error')
            }
        } catch {
            history('/500')
        }
        setIsLoading(false);
    }

    const isCreating = !eventId;
    const reservationIsValid = (reservation
        && reservation.resourceId
        && reservation?.emails !== undefined && reservation?.emails?.length > 0
        && reservation?.dateTimeFrom <= reservation?.dateTimeTo
        && new Date(reservation?.dateTimeFrom).getTime() >= new Date().getTime() - 120000
        && (reservation.isPeriodic ? reservation.pattern && reservation.pattern.toDate.valueOf() >= reservation.dateTimeFrom.valueOf() : true)
        && (reservation.isPeriodic ? reservation.pattern && new Date(reservation.pattern.toDate).getTime() - new Date(reservation.dateTimeFrom).getTime() <= 31556952000 : true)
        && (reservation.isPeriodic && reservation.pattern && reservation.pattern.type === t('weekly') ? reservation.pattern.daysOfWeek.length > 0 : true)
        && (reservation.isPeriodic && reservation.pattern && reservation.pattern.type === t('daily') ? reservation.pattern.interval <= 182 && reservation.pattern.interval >= 1 : true)
        && (reservation.isPeriodic && reservation.pattern && reservation.pattern.type === t('monthly') ? reservation.pattern.interval <= 7 && reservation.pattern.interval >= 1 : true)
        && (reservation.emails.length <= (selectedResourceSelectItem?.effectiveCapacity || 100))) as boolean;

    return (reservation ? (
        <>
            <Scrollbar options={{ suppressScrollX: true }}>
                <Box sx={{ p: 2 }}>
                    <Box sx={{ p: 2 }}>
                        <Typography
                            align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="h5"
                        >
                            {isCreating
                                ? t('ADD_RESERVATION') + ' (2/2)'
                                : t('EDIT_RESERVATION')}
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle2"
                                        >
                                            {t('RESOURCE_NAME')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle2"
                                        >
                                            {selectedResourceSelectItem?.name || ''}
                                            <Link
                                                sx={{ ml: 2 }}
                                                color="primary"
                                                variant="subtitle2"
                                                component={RouterLink}
                                                to={`/resources/detail?map=true&id=${selectedResourceSelectItem?.id}`}
                                            >
                                                <RoomIcon/>
                                            </Link>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {!eventId && <><TableRow>
                                    <TableCell>
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle2"
                                        >
                                            {t('PERIOD')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle2"
                                        >
                                            {reservation?.dateTimeFrom ? format(new Date(reservation.dateTimeFrom), t('DATE_PICKER_FULL_DATE')) : ''}
                                            {' - '}
                                            {reservation?.dateTimeTo ? format(new Date(reservation.dateTimeTo), t('DATE_PICKER_FULL_DATE')) : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                </>}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            label={t('TITLE')}
                            name="title"
                            onChange={(e) => {
                                reservation.title = e.target.value;
                                setReservation(deepClone(reservation));
                            }}
                            value={reservation.title}
                            variant="outlined"
                        />
                    </Box>

                    <Autocomplete
                        sx={{ pt: 2 }}
                        multiple
                        id="tags-filled"
                        options={emailOptions}
                        defaultValue={[userInfo?.email || '']}
                        freeSolo
                        renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                                <Chip
                                    variant="outlined"
                                    label={option} {...getTagProps({ index })}
                                    onDelete={() => {
                                        if (reservation) {
                                            reservation.emails = reservation?.emails?.filter((prevChip) => option !== prevChip);
                                            setReservation(deepClone(reservation));
                                        }

                                        setSelectedUsersSelectItem(() => selectedUsersSelectItem?.filter((prevChip) => option !== prevChip));
                                    }}/>
                            ))
                        }
                        onChange={(event, value) => {
                            let p = value?.filter(v => isValidEmail(v));
                            if (p.length <= (selectedResourceSelectItem?.effectiveCapacity || 100)) {
                                reservation.emails = p;
                                setReservation(deepClone(reservation));
                                setMaxUserReached(false);
                            } else {
                                setMaxUserReached(true)
                            }
                        }}
                        value={reservation?.emails}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label=""
                                placeholder=""
                                onChange={(evt) => {
                                    setSearchedEmail(evt.target.value);
                                }}
                            />
                        )}
                    />
                    {maxUserReached && <Typography
                        sx={{ pl: 2 }}
                        color="error"
                        variant="subtitle2"
                    >
                        {'É stato raggiunto il massimo numero di utenti selezionabili per la risorsa scelta.'}
                    </Typography>}
                    {eventId && <>
                        <Box sx={{ mt: 2 }}>
                            <MobileDateTimePicker
                                cancelText={t('CANCEL')}
                                minDate={new Date()}
                                inputFormat={t('DATE_PICKER_FULL_DATE')}
                                ampm={false}
                                ampmInClock={false}
                                label={t('START_DATE')}
                                onChange={(date) => {
                                    reservation.dateTimeFrom = date ?? new Date();
                                    setReservation(deepClone(reservation));
                                }}
                                renderInput={(inputProps) => (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        {...inputProps}
                                    />
                                )}
                                value={reservation.dateTimeFrom}
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <MobileDateTimePicker
                                cancelText={t('CANCEL')}
                                minDate={new Date()}
                                inputFormat={t('DATE_PICKER_FULL_DATE')}
                                ampm={false}
                                ampmInClock={false}
                                label={t('END_DATE')}
                                onChange={(date) => {
                                    reservation.dateTimeTo = date ?? new Date();
                                    setReservation(deepClone(reservation));
                                }}
                                renderInput={(inputProps) => (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        {...inputProps}
                                    />
                                )}
                                value={reservation.dateTimeTo}
                            />
                        </Box>
                    </>}
                    {reservation.isPeriodic &&
                    <>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexWrap: 'wrap',
                                p: 0,
                                marginTop: '10px'
                            }}
                        >
                            <Divider/>
                            <Box sx={{ m: 2 }}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            disabled={true}
                                            checked={reservation.isPeriodic}
                                            onChange={(e: any) => {
                                                reservation.isPeriodic = e.target.checked;
                                                setReservation(deepClone(reservation));
                                            }}
                                            color="primary"
                                            name="Is periodic"
                                        />
                                    )}
                                    label={t('IS_PERIODIC')}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ m: 0 }}>
                            <CalendarPeriodSection
                                confirmClicked={confirmClicked}
                                patternProp={reservation.pattern}
                                updatePattern={updatePattern}
                                isPeriodic={reservation.isPeriodic}
                                startDate={reservation.dateTimeFrom}
                            />
                        </Box>
                    </>}
                    <Divider/>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            p: 2
                        }}
                    >
                        {!isCreating
                        && (userCanUseIfRoles(userInfo, ["building_contributor"]) || reservation.emails?.includes(userInfo?.email || ''))
                        && !isReadOnly
                        && (<IconButton
                                onClick={() => {
                                    if (reservation.isPeriodic) {
                                        setIsDeleting(true);
                                        setIsDeletePeriodicEventModalOpen(true);
                                    } else {
                                        setIsDeleteSingleEventModalOpen(true);
                                    }
                                }}>
                                <TrashIcon fontSize="small"/>
                            </IconButton>
                        )}

                        <Box sx={{ flexGrow: 1 }}/>
                        <Button
                            color="primary"
                            onClick={onCancel}
                            variant="text"
                        >
                            {eventId ? t('CANCEL') : t('BACK')}
                        </Button>
                        <Button
                            color="primary"
                            disabled={!reservationIsValid}
                            sx={{ ml: 1 }}
                            type="submit"
                            variant="contained"
                            onClick={async () => {
                                if (!isCreating && reservation.isPeriodic) {
                                    setIsDeletePeriodicEventModalOpen(true)
                                } else {
                                    setConfirmClicked(true);
                                    reservation.dateTimeFrom = toUTCDate(reservation.dateTimeFrom);
                                    reservation.dateTimeTo = toUTCDate(reservation.dateTimeTo);
                                    if (reservation.pattern) {
                                        reservation.pattern.toDate = toUTCDate(reservation.pattern.toDate);
                                    }
                                    await upsertReservationAsync(reservation, reservationIsValid);
                                }
                            }}
                        >
                            {t('CONFIRM')}
                        </Button>
                    </Box>
                    <Loader isLoading={isLoading}/>
                    <Dialog
                        fullWidth
                        maxWidth="sm"
                        onClose={() => setIsCanInsertResultModalOpen(false)}
                        open={isCanInsertResultModalOpen}
                    >
                        <CanInsertResultError
                            canInsertResult={canInsertResultError}
                            action={async () => {
                                reservation?.emails?.map(participant => participant.toLowerCase());
                                await reservationService.upsertReservation(reservation);
                            }}
                            onClose={() => {
                                setIsCanInsertResultModalOpen(false);
                                if (onAddComplete)
                                    onAddComplete();
                            }}
                        />
                    </Dialog>
                    <Dialog
                        fullWidth
                        maxWidth="sm"
                        onClose={() => setIsDeleteSingleEventModalOpen(false)}
                        open={isDeleteSingleEventModalOpen}
                    >
                        <Box sx={{ flexGrow: 1 }}/>
                        <div style={{ padding: '30px', textAlign: 'center' }}>
                            {`Sei sicuro di voler eliminare l'evento selezionato?`}
                        </div>
                        <Button
                            color="primary"
                            onClick={() => setIsDeleteSingleEventModalOpen(false)}
                            variant="text"
                        >
                            {t('CANCEL')}
                        </Button>
                        <Button
                            color="primary"
                            onClick={async () => {
                                reservation.editAllNewEvents = false
                                await deleteReservationAsync(reservation.id, false);

                                if (onAddComplete)
                                    onAddComplete();
                            }}
                            variant="text"
                        >
                            {t('CONFIRM')}
                        </Button>
                    </Dialog>
                    <Dialog
                        fullWidth
                        maxWidth="sm"
                        onClose={() => setIsDeletePeriodicEventModalOpen(false)}
                        open={isDeletePeriodicEventModalOpen}
                    >
                        <Box sx={{ flexGrow: 1 }}/>
                        <div style={{ padding: '30px', textAlign: 'center' }}>
                            {`${t('DO_YOU_WANT_TO')} ${isDeleting ? t('TO_DELETE') : t('TO_EDIT')} ${t('RERSERVATION_DELETED_MESSAGE_FINAL')}`}
                        </div>
                        <Button
                            color="primary"
                            onClick={() => setIsDeletePeriodicEventModalOpen(false)}
                            variant="text"
                        >
                            {t('CANCEL')}
                        </Button>
                        <Button
                            color="primary"
                            onClick={async () => {
                                setConfirmClicked(true);
                                if (isDeleting) {
                                    await deleteReservationAsync(reservation.id, false);
                                } else {
                                    reservation.editAllNewEvents = false;
                                    reservation.isPeriodic = false;
                                    await upsertReservationAsync(reservation, reservationIsValid);
                                }

                                if (onAddComplete)
                                    onAddComplete();
                            }}
                            variant="text"
                        >
                            {t('ONLY_THIS')}
                        </Button>
                        <Button
                            color="primary"
                            disabled={!reservationIsValid}
                            sx={{ ml: 1 }}
                            type="submit"
                            variant="contained"
                            onClick={async () => {
                                setConfirmClicked(true);
                                if (isDeleting) {
                                    await deleteReservationAsync(reservation.id, true);
                                } else {
                                    reservation.editAllNewEvents = true;
                                    await upsertReservationAsync(reservation, reservationIsValid);
                                }
                                if (onAddComplete)
                                    onAddComplete();
                            }}
                        >
                            {t('FOLLOWING_ONES')}
                        </Button>
                    </Dialog>
                </Box>
            </Scrollbar>
        </>) : <></>);
};

export default CalendarEventForm;


