import type { FC } from 'react';
import { useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Dialog, Divider, Typography } from '@material-ui/core';
import TrashIcon from '../../icons/Trash';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { LevelService } from '../../services/LevelService';
import { Level } from '../../types/level';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';

interface LevelProps {
    level: Level;
}

const LevelDataManagement: FC<LevelProps> = (props) => {
    const { level } = props;
    const { enqueueSnackbar } = useSnackbar();
    const history = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function submitLevelInvalidateAsync(id: string) {
        try {
            var levelService = new LevelService();
            await levelService.invalidateLevel(id);

            enqueueSnackbar(t('LEVEL_DELETED'), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
            history(`/levels?buildingId=${level.buildingIds[0]}`);
        } catch {
            history('/500');
        }
    }

    return (
        <>
            <Card {...props}>
                <CardHeader title={t('DATA_MANAGEMENT')} />
                <Divider />
                <CardContent>
                    <Box
                        sx={{
                            mb: 2,
                            mt: 1
                        }}
                    >
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                            {t('DELETE_LEVEL_DESCRIPTION')}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 2
                    }}>
                        <Button
                            startIcon={<TrashIcon fontSize="small" />}
                            onClick={async () => {
                                setIsModalOpen(true);
                            }}
                            sx={{
                                backgroundColor: 'error.main',
                                color: 'error.contrastText',
                                '&:hover': {
                                    backgroundColor: 'error.dark'
                                }
                            }}
                            variant="contained"
                        >
                            {t('DELETE')}
                        </Button>
                    </Box>
                </CardContent>
                <Loader isLoading={isLoading} />
            </Card>
            <Dialog
                fullWidth
                maxWidth="sm"
                onClose={() => setIsModalOpen(false)}
                open={isModalOpen}
            >
                <Box sx={{ flexGrow: 1 }} />
                <div style={{ padding: '30px', textAlign: 'center' }}>
                    {t('DO_YOU_WANT_DELETE')}
                </div>
                <Button
                    color="primary"
                    onClick={async () => {
                        setIsModalOpen(false);
                        setIsLoading(true);
                        await submitLevelInvalidateAsync(level?.id);
                        setIsLoading(false);
                    }}
                    variant="text"
                >
                    {t('YES')}
                </Button>
                <Button
                    color="primary"
                    onClick={() => { setIsModalOpen(false) }}
                    variant="text"
                >
                    {t('CANCEL')}
                </Button>
            </Dialog>
        </>);
};

export default LevelDataManagement;
