import type { FC } from 'react';
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core';
import { ResourceService } from '../../services/ResourceService';
import { useSnackbar, VariantType } from 'notistack';
import { useNavigate } from "react-router-dom";
import { UserService } from '../../services/UserService';
import { AppState } from "../../store";
import { Resource } from '../../types/resource';
import { UserInfo } from "../../models/UserInfo";
import { useSelector } from "react-redux";
import MultiSelect from '../MultiSelect';
import { ResourceAccess } from '../../types/resourceAccess';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';

interface ResourceProps {
  resource: Resource;
}

const ResourceDataManagement: FC<ResourceProps> = (props) => {
  const { resource } = props;
  const [userSelectItems, setUserSelectItems] = useState<string[]>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();
  const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
  const [selectedtUsersSelectItem, setSelectedUsersSelectItem] = useState<string[]>();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMultiSelectChange = (selectedEmails: string[]): void => {
    setSelectedUsersSelectItem(selectedEmails);
  };

  const handleChipDelete = (chip: string): void => {
    setSelectedUsersSelectItem((prevChips) => selectedtUsersSelectItem?.filter((prevChip) => chip !== prevChip));
  };

  useEffect(() => {
    (async () => {
      try {
        if (userInfo) {
          var userService = new UserService();
          var selectItems = await userService.getUserSelectItems(userInfo.tenantId);
          setUserSelectItems(selectItems?.map(({ email }) => email));
        }
        if (resource.users) {
          setSelectedUsersSelectItem(resource.users);
        }
      } catch {
        history('/500');
      }
    })();
  }, [userInfo]);

  async function submitResourceAccessAsync(id: string, resourceAccessIsValid: boolean) {
    try {
      if (resourceAccessIsValid) {
        const resourceAccess: ResourceAccess = {
          resourceId: id,
          userEmails: selectedtUsersSelectItem ?? [""]
        };

        var msgUser = t('ACCESSES_ASSIGNED_SUCCESSFULLY');
        var msgVariant: VariantType = 'success';
        var resourceService = new ResourceService();

        await resourceService.upsertResourceAccess(resourceAccess);

        enqueueSnackbar(msgUser, {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: msgVariant
        });
        history('/resources');
      }
      else {
        console.log('error');
      }
    } catch {
      history('/500');
    }
  }

  const resourceAccessIsValid = (resource
    && resource.id
    && selectedtUsersSelectItem
    && selectedtUsersSelectItem.length > 0) as boolean;

  return (
    <>
      <Card>
        <CardHeader title={t('ACCESSES')} />
        <Divider />
        <CardContent>
          <Box
            sx={{
              mb: 2,
              mt: 1
            }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {t('RESOURCE')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      {resource.name} - {resource.resourceTypeCode} - {resource.description}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                p: 1,
                width: '100%',
                border: selectedtUsersSelectItem && selectedtUsersSelectItem.length < 1 ? '0.5px solid #f44336!important' : '0.5px solid #919eab!important',
                borderRadius: '16px',
                marginLeft: '0px',
                marginTop: '10px'
              }}
            >
              <MultiSelect
                label={t('USERS_REQUIRED')}
                onChange={handleMultiSelectChange}
                options={userSelectItems || []}
                value={selectedtUsersSelectItem || []}
              />
              {selectedtUsersSelectItem?.map((chip) => (
                <Chip
                  key={chip}
                  label={chip}
                  onDelete={(): void => handleChipDelete(chip)}
                  sx={{ m: 1 }}
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2
          }}>
            <Button
              disabled={!resourceAccessIsValid}
              onClick={async () => {
                setIsLoading(true);
                await submitResourceAccessAsync(resource.id, resourceAccessIsValid);
                setIsLoading(false);
              }}
              color="primary"
              variant="contained"
            >
              {t('ASSIGN')}
            </Button>
          </Box>
        </CardContent>
        <Loader isLoading={isLoading} />
      </Card>
    </>);
};

export default ResourceDataManagement;
