import type { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import ResourceNumberChart from '../components/dashboard/ResourcesNumber';
import ReservationDifferenceByMonthChart from '../components/dashboard/ReservationDifferenceByMonth';
import ReservationDifferenceByWeekChart from '../components/dashboard/ReservationDifferenceByWeek';
import ReservationGraphChart from '../components/dashboard/ReservationGraph';
import NextReservationChart from '../components/dashboard/NextReservation';
import ResourcesCountDonutChart from '../components/dashboard/ResourcesCountDonut';
import { useTheme } from '@material-ui/core/styles';
import { userCanUseIfRoles } from '../utils/common';
import { useSelector } from "react-redux";
import { AppState } from "../store";
import { UserInfo } from "../models/UserInfo";
import { PlannerAdmin, PlannerContributor, ResourceContributor } from '../types/rolesConst';

const Dashboard: FC = () => {
    const currentTheme = useTheme();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    return <>
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                p: 2,
                [currentTheme.breakpoints.up('lg')]: {
                    paddingLeft: '300px'
                }
            }}
        >
            <Grid container spacing={2}>
                <Grid item
                    xl={3}
                    md={3}
                    xs={12}
                    style={{ color: 'white' }}>
                    { userCanUseIfRoles(userInfo, [PlannerContributor, PlannerAdmin]) && <NextReservationChart />}
                    { userCanUseIfRoles(userInfo, [ResourceContributor]) && <ResourcesCountDonutChart /> }
                </Grid>
                <Grid item
                    xl={9}
                    md={9}
                    xs={12}
                    style={{ color: 'white' }}>
                    <Grid
                        container
                        spacing={2}
                    >
                        { userCanUseIfRoles(userInfo, [ResourceContributor]) && <ResourceNumberChart /> }
                        { userCanUseIfRoles(userInfo, [PlannerContributor, PlannerAdmin]) && <ReservationDifferenceByMonthChart /> }
                        { userCanUseIfRoles(userInfo, [PlannerContributor, PlannerAdmin]) && <ReservationDifferenceByWeekChart /> }
                    </Grid>
                    <Grid
                        item
                        sx={{ py: 2 }}
                    >
                        { userCanUseIfRoles(userInfo, [PlannerContributor, PlannerAdmin]) && <ReservationGraphChart /> }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </>
}

export default Dashboard;
