import axios from 'axios';
import ServiceConfig from "../config/serviceConfig";
import { PaginationResult } from '../types/paginationResult';
import { Building } from '../types/building';

export class BuildingService {

    async findBuilding(id: string): Promise<Building> {
        try {
            const response = await axios.get<Building>(ServiceConfig.serviceBaseUrl + "/FindBuildingById", {
                params: {
                    id: id
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("findBuilding error");
            }
        }
        catch (error) {
            throw new Error("findBuilding error:" + error);
        }
    }

    async searchBuildings(tenantId: string, pageNumber: number, pageSize: number, text: string): Promise<PaginationResult<Building>> {
        try {
            const response = await axios.get<PaginationResult<Building>>(ServiceConfig.serviceBaseUrl + "/SearchBuildings", {
                params: {
                    tenantId: tenantId,
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                    text: text
                }
            });
            if (response.status === 200) {
                return response.data;

            }
            else {
                throw new Error("searchBuildings error");
            }
        }
        catch (error) {
            throw new Error("searchBuildings error:" + error);
        }
    }

    async upsertBuilding(building: Building): Promise<boolean> {
        try {
            const response = await axios.post<Building>(ServiceConfig.serviceBaseUrl + "/UpsertBuilding", building);
            if (response.status === 200) {
                return Boolean(response.data);
            }
            else {
                throw new Error("upsertBuilding error");
            }
        }
        catch (error) {
            throw new Error("upsertBuilding error:" + error);
        }
    }

    async invalidateBuilding(id: string): Promise<boolean> {
        try {
            const response = await axios.get(ServiceConfig.serviceBaseUrl + "/InvalidateBuilding", {
                params: {
                    id: id
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("invalidateBuilding error");
            }
        }
        catch (error) {
            throw new Error("invalidateBuilding error:" + error);
        }
    }

}