import { combineReducers } from 'redux';
import { User } from 'oidc-client';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { reducer as oidcReducer } from 'redux-oidc';
import { AppStatusState, reducer as statusReducer } from './AppStatus';
import { AppInitState, reducer as appInitReducer } from './AppInit';

interface OidcState {
  isLoadingUser: boolean;
  user: User;
}

export interface AppState {
  oidc: OidcState;
  status: AppStatusState;
  router: RouterState;
  appInit: AppInitState;
}

const rootReducer = (history: History) => combineReducers({
  oidc: oidcReducer,
  status: statusReducer,
  appInit: appInitReducer,
  router: connectRouter(history)
});

export default rootReducer;