import axios from 'axios';
import ServiceConfig from "../config/serviceConfig";
import { PaginationResult } from '../types/paginationResult';
import { ResourceType, ResourceTypeSelectItem } from '../types/resourceType';
import { CanInsert } from "../models/CanInsert";

export class ResourceTypeService {

    async findResourceType(id: string): Promise<ResourceType> {
        try {
            const response = await axios.get<ResourceType>(ServiceConfig.serviceBaseUrl + "/FindByResourceTypeId", {
                params: {
                    id: id
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("findResourceType error");
            }
        }
        catch (error) {
            throw new Error("findResourceType error:" + error);
        }
    }

    async searchResourceTypes(tenantId: string, pageNumber: number, pageSize: number, text: string): Promise<PaginationResult<ResourceType>> {
        try {
            const response = await axios.get<PaginationResult<ResourceType>>(ServiceConfig.serviceBaseUrl + "/SearchResourceTypes", {
                params: {
                    tenantId: tenantId,
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                    text: text
                }
            });
            if (response.status === 200) {
                return response.data;

            }
            else {
                throw new Error("searchResourceTypes error");
            }
        }
        catch (error) {
            throw new Error("searchResourceTypes error:" + error);
        }
    }

    async upsertResourceType(resourceType: ResourceType): Promise<boolean> {
        try {
            const response = await axios.post<ResourceType>(ServiceConfig.serviceBaseUrl + "/UpsertResourceType", resourceType);
            if (response.status === 200) {
                return Boolean(response.data);
            }
            else {
                throw new Error("upsertResourceType error");
            }
        }
        catch (error) {
            throw new Error("upsertResourceType error:" + error);
        }
    }

    async getResourceTypeSelectItems(tenantId: string): Promise<ResourceTypeSelectItem[]> {
        try {
            const response = await axios.get<ResourceTypeSelectItem[]>(ServiceConfig.serviceBaseUrl + "/GetResourceTypeSelectItems", {
                params: {
                    tenantId: tenantId
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("getResourceTypeSelectItems error");
            }
        }
        catch (error) {
            throw new Error("getResourceTypeSelectItems error:" + error);
        }
    }

    async canInsertResourceType(code: string, tenantId: string): Promise<CanInsert> {
        try {
            const response = await axios.get(ServiceConfig.serviceBaseUrl + "/CanInsertResourceType", {
                params: {
                    code: code,
                    tenantId: tenantId
                }
            });
            if (response.status === 200) {
                return response.data;

            }
            else {
                throw new Error("canInsertResourceType error");
            }
        }
        catch (error) {
            throw new Error("canInsertResourceType error:" + error);
        }
    }

    async invalidateResourceType(id: string): Promise<boolean> {
        try {
            const response = await axios.get(ServiceConfig.serviceBaseUrl + "/InvalidateResourceType", {
                params: {
                    id: id
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("invalidateResourceType error");
            }
        }
        catch (error) {
            throw new Error("invalidateResourceType error:" + error);
        }
    }

}