import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Checkbox, Grid, Typography } from '@material-ui/core';
import { DashboardService } from '../../services/DashboardService';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import Chart from 'react-apexcharts';
import { useTheme } from '@material-ui/core/styles';
import { availableColors } from '../../utils/colorPicker';
import { useTranslation } from 'react-i18next';

export interface DashboardGraph {
    series: SeriesResult[];
    xaxis: Xaxis;
}

export interface SeriesResult {
    color: string;
    data: number[];
    name: string;
}

export interface Xaxis {
    dataPoints: string[];
}

const ReservationGraphChart: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const [dashboardGraph, setDashboardGraph] = useState<DashboardGraph>();
    const [selectedSeries, setSelectedSeries] = useState<string[]>();

    const visibleSeries = dashboardGraph?.series.filter((item) => selectedSeries?.includes(item.name));

    const selectedSeriesHandleChange = (event: any, name: string) => {
        if (!event.target.checked) {
            setSelectedSeries(selectedSeries?.filter((item) => item !== name));
        } else {
            if (selectedSeries)
                setSelectedSeries([...selectedSeries, name]);
        }
    };

    useEffect(() => {
        (async () => {
            if (userInfo) {
                var dashboardSerivice = new DashboardService();
                var res = await dashboardSerivice.getDashboardGraph(userInfo.tenantId, userInfo.roles);

                res?.series.map((s, index) => {
                    if (index > 49) {
                        var randomIndex = Math.floor(Math.random() * 49);
                        s.color = availableColors()[randomIndex]
                    } else {
                        s.color = availableColors()[index]
                    }
                });

                var selSeries = res?.series.map(s => s.name);
                setSelectedSeries(selSeries);
                setDashboardGraph(res);
            }

        })();
    }, [])

    const chart = {
        options: {
            chart: {
                background: 'transparent',
                stacked: false,
                toolbar: {
                    show: false
                },
                zoom: false
            },
            colors: dashboardGraph?.series.map(s => s.color),
            dataLabels: {
                enabled: false
            },
            grid: {
                borderColor: theme.palette.divider,
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            legend: {
                show: false
            },
            markers: {
                hover: {
                    size: undefined,
                    sizeOffset: 2
                },
                radius: 2,
                shape: 'circle',
                size: 4,
                strokeWidth: 0
            },
            stroke: {
                curve: 'smooth',
                lineCap: 'butt',
                width: 3
            },
            theme: {
                mode: theme.palette.mode
            },
            tooltip: {
                theme: theme.palette.mode
            },
            xaxis: {
                axisBorder: {
                    color: theme.palette.divider
                },
                axisTicks: {
                    color: theme.palette.divider,
                    show: true
                },
                categories: dashboardGraph?.xaxis.dataPoints,
                labels: {
                    style: {
                        colors: theme.palette.text.secondary
                    }
                }
            },
            yaxis: [
                {
                    axisBorder: {
                        color: theme.palette.divider,
                        show: true
                    },
                    axisTicks: {
                        color: theme.palette.divider,
                        show: true
                    },
                    labels: {
                        style: {
                            colors: theme.palette.text.secondary
                        }
                    }
                },
                {
                    axisTicks: {
                        color: theme.palette.divider,
                        show: true
                    },
                    axisBorder: {
                        color: theme.palette.divider,
                        show: true
                    },
                    labels: {
                        style: {
                            colors: theme.palette.text.secondary
                        }
                    },
                    opposite: true
                }
            ]
        },
        series: visibleSeries
    };

    return (<>{dashboardGraph && (
        <Grid
            item
            md={12}
            sm={12}
            xs={12}
        >
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    p: 0
                }}
            >
                <Card>
                    <CardHeader
                        disableTypography
                        title={(
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography
                                    color="textPrimary"
                                    variant="h6"
                                >
                                    {t('RERSERVATIONS_BY_RESOURCE_TYPE')}
                                </Typography>
                            </Box>
                        )}
                    />
                    <CardContent>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}
                        >
                            {dashboardGraph?.series.map((item) => (
                                <Box
                                    key={item.name}
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        mr: 2
                                    }}
                                >
                                    <Checkbox
                                        checked={selectedSeries?.some((visibleItem) => visibleItem === item.name)}
                                        color="primary"
                                        onChange={(event: any) => selectedSeriesHandleChange(event, item.name)}
                                    />
                                    <Box
                                        sx={{
                                            backgroundColor: item.color,
                                            borderRadius: '50%',
                                            height: 8,
                                            ml: 1,
                                            mr: 2,
                                            width: 8
                                        }}
                                    />
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {item.name}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        <Chart
                            height="350"
                            type="line"
                            {...chart}
                        />
                    </CardContent>
                </Card>
            </Box>
        </Grid>)}</>
    );
};

export default ReservationGraphChart;