import type { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Backdrop } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface LoaderProps {
    isLoading: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    })
);

const Loader: FC<LoaderProps> = (props) => {
    const { isLoading } = props;

    const classes = useStyles();

    return (isLoading
        ? <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress color="primary" />
        </Backdrop>
        : <></>
    );
};

export default Loader;
