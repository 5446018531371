import type { FC } from 'react';
import { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Dialog,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReservationService } from '../../../services/ReservationService';
import { ResourceTypeService } from '../../../services/ResourceTypeService';
import { ResourceService } from '../../../services/ResourceService';
import { AppState } from "../../../store";
import { UserInfo } from "../../../models/UserInfo";
import { useSelector } from "react-redux";
import { BuildingDetail, BuildingWithDetail, LevelDetail, UnitDetail } from '../../../types/building';
import { ResourceType, ResourceTypeSelectItem, ResourceWithDetail } from '../../../types/resourceType';
import { deepClone } from '../../../utils/common';
import Scrollbar from '../../Scrollbar';
import getInitials from '../../../utils/getInitials';
import { Step2 } from './SingleEvent';
import LocationOn from '@material-ui/icons/LocationOn';
import L, { CRS } from 'leaflet';
import markerBlue from '../../../icons/pureSvg/blue-marker.svg';
import { ImageOverlay, Map, Marker } from 'react-leaflet';
import Loader from '../../Loader';
import XIcon from '../../../icons/X';
import { Pattern } from "../../../types/reservation";

interface AvailableResourcesProps {
    step1IsSingle: boolean;
    step1SelectedResourceType?: ResourceType;
    step2: Step2;
    setStep3SelectedResource(resource: ResourceWithDetail): void;
    step3SelectedResource?: ResourceWithDetail;
    currentPattern?: Pattern;
}

const AvailableResources: FC<AvailableResourcesProps> = (props) => {

    const { step2, step3SelectedResource, setStep3SelectedResource, step1SelectedResourceType, currentPattern } = props;
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const { t } = useTranslation();
    const reservationService = new ReservationService();
    const resourceTypeService = new ResourceTypeService();
    const resourceService = new ResourceService();

    const [resourceTypes, setResourceTypes] = useState<ResourceTypeSelectItem[]>();
    const [selectedResourceType, setSelectedResourceType] = useState<ResourceTypeSelectItem>();
    const [buildingWithDetails, setBuildingWithDetails] = useState<BuildingWithDetail>();
    const [selectedBuilding, setSelectedBuilding] = useState<BuildingDetail>();
    const [selectedUnit, setSelectedUnit] = useState<UnitDetail>();
    const [selectedLevel, setSelectedLevel] = useState<LevelDetail>();
    const [resourceWithDetails, setResourceWithDetails] = useState<ResourceWithDetail[]>([]);
    const [isDetailPositionModelOpen, setIsDetailPositionModelOpen] = useState<boolean>(false);
    const [backgroundMediaAsBase64, setBackgroundMediaAsBase64] = useState<string>();
    const [backgroundMediaSizes, setBackgroundMediaSizes] = useState<{ w: number, h: number }>();
    const [anchor, setAnchor] = useState<number[]>();

    const iconBlue = L.icon({
        iconUrl: markerBlue,
        iconSize: [32, 34],
        iconAnchor: [16, 0]
    });

    useEffect(() => {
        (async () => {
            if (userInfo) {

                let [buildings, resourceTypesRes] = await Promise.all([
                    reservationService.getBuildingsWithDetails(userInfo.tenantId),
                    resourceTypeService.getResourceTypeSelectItems(userInfo.tenantId)]);

                setResourceTypes(resourceTypesRes);
                setBuildingWithDetails(buildings);
            }
        })();
    }, [])

    useEffect(() => {
        (async () => {
            if (userInfo) {
                if (currentPattern
                    && currentPattern.type !== 'daily'
                    && currentPattern.type !== 'weekly'
                    && currentPattern.type !== 'monthly') {
                    currentPattern.type = t(currentPattern.type);
                }

                let res = await resourceService.getAvailableResourceSelectItems(
                    userInfo.tenantId,
                    step2.from || new Date(),
                    step2.to || new Date(),
                    selectedResourceType?.code || step1SelectedResourceType?.code || '',
                    selectedUnit?.id || '',
                    selectedLevel?.id || '',
                    selectedBuilding?.id || '',
                    currentPattern);

                setResourceWithDetails(deepClone(res));
            }
        })();
    }, [selectedResourceType, selectedUnit, selectedLevel, selectedBuilding])

    async function getResourcePositionAsync(resource: ResourceWithDetail) {
        let resourcePosition = await resourceService.findAnchorByResourceId(resource.id);

        const image = new Image();
        image.src = resourcePosition.base64;
        image.onload = () => {
            let sizes: any = {
                w: image.width as number,
                h: image.height as number
            };
            setBackgroundMediaSizes(sizes);
        }

        setBackgroundMediaAsBase64(resourcePosition.base64);
        setAnchor(resourcePosition.coordinates);
    }

    function closePositionModal() {
        setIsDetailPositionModelOpen(false);
        setBackgroundMediaAsBase64(undefined);
        setAnchor(undefined);
        setBackgroundMediaSizes(undefined);
    }

    return (<>
        <Box sx={{ textAlign: 'center', pt: 5, pb: 1, display: 'flex' }}>
            <Box
                sx={{
                    mr: 2,
                    maxWidth: '100%',
                    width: 220
                }}
            >
                <TextField
                    fullWidth
                    label={t('BUILDING')}
                    name="edificio"
                    onChange={(e) => {
                        let selBuilding = buildingWithDetails?.buildings.filter(building => building.name === e.target.value)[0];
                        setSelectedBuilding(deepClone(selBuilding || { id: '', name: '' }));
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={selectedBuilding?.name}
                    variant="outlined"
                >
                    <option
                        key={''}
                        value={''}
                    >
                        {''}
                    </option>
                    {buildingWithDetails?.buildings.map((building) => (
                        <option
                            key={building.id}
                            value={building.name}
                        >
                            {building.name}
                        </option>
                    ))}
                </TextField>
            </Box>
            <Box
                sx={{
                    mr: 2,
                    maxWidth: '100%',
                    width: 220
                }}
            >
                <TextField
                    fullWidth
                    label={t('LEVEL_NUMBER')}
                    name="livello"
                    onChange={(e) => {
                        let selLevel = buildingWithDetails?.levels.filter(level => level.name === e.target.value)[0];
                        setSelectedLevel(deepClone(selLevel || { id: '', name: '' }));
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={selectedLevel?.name}
                    variant="outlined"
                >
                    <option
                        key={''}
                        value={''}
                    >
                        {''}
                    </option>
                    {buildingWithDetails?.levels.filter(l => l.buildingId === selectedBuilding?.id).map((level) => (
                        <option
                            key={level.id}
                            value={level.name}
                        >
                            {level.name}
                        </option>
                    ))}
                </TextField>
            </Box>
            <Box
                sx={{
                    mr: 2,
                    maxWidth: '100%',
                    width: 220
                }}
            >
                <TextField
                    fullWidth
                    label={t('UNIT')}
                    name="area"
                    onChange={(e) => {
                        let selUnit = buildingWithDetails?.units.filter(unit => unit.name === e.target.value)[0];
                        setSelectedUnit(deepClone(selUnit || { id: '', name: '' }));
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={selectedUnit?.name}
                    variant="outlined"
                >
                    <option
                        key={''}
                        value={''}
                    >
                        {''}
                    </option>
                    {buildingWithDetails?.units.filter(l => l.levelId === selectedLevel?.id).map((unit) => (
                        <option
                            key={unit.id}
                            value={unit.name}
                        >
                            {unit.name}
                        </option>
                    ))}
                </TextField>
            </Box>
            {!step1SelectedResourceType && <Box
                sx={{
                    mr: 2,
                    maxWidth: '100%',
                    width: 220
                }}
            >
                <TextField
                    fullWidth
                    label={t('TYPOLOGY_OF_RESOURCE')}
                    name="tipologia"
                    onChange={(e) => {
                        let res = resourceTypes?.filter(res => res.description === e.target.value)[0];
                        setSelectedResourceType(deepClone(res || { id: '', description: '' }));
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={selectedResourceType?.description}
                    variant="outlined"
                >
                    <option
                        key={''}
                        value={''}
                    >
                        {''}
                    </option>
                    {resourceTypes?.map((resourceType) => (
                        <option
                            key={resourceType.id}
                            value={resourceType.description}
                        >
                            {resourceType.description}
                        </option>
                    ))}
                </TextField>
            </Box>}
        </Box>
        <Scrollbar>
            <Box sx={{
                minWidth: 700,
                maxHeight: '300px',
                pb: 5
            }}>
                {resourceWithDetails.length === 0
                    ? <Typography
                        align="center"
                        color="textSecondary"
                        sx={{ mt: 0.5 }}
                        variant="subtitle2"
                    >
                        {t('FILTER_AVAILABLE_RESOURCES')}
                    </Typography>
                    : <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    {t('RESOURCE')}
                                </TableCell>
                                <TableCell>
                                    {t('NAME')}
                                </TableCell>
                                <TableCell>
                                    {t('TYPE_OF_RESOURCE')}
                                </TableCell>
                                <TableCell>
                                    {t('BUILDING')}
                                </TableCell>
                                <TableCell>
                                    {t('POSITION')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resourceWithDetails?.map(resource =>
                                <TableRow
                                    className="App-clickableElement"
                                    hover
                                    key={resource.id}
                                    onClick={(evt) => {
                                        //setSelectedResourceWithDetail(resource);
                                        setStep3SelectedResource(resource);
                                    }}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={step3SelectedResource?.id === resource.id}
                                            color="primary"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}
                                        >
                                            <Avatar
                                                src={undefined}
                                                sx={{
                                                    height: 42,
                                                    width: 42,
                                                    color: 'primary'
                                                }}
                                            >
                                                {getInitials(resource.name)}
                                            </Avatar>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            <Typography
                                                color="textPrimary"
                                                variant="body2"
                                            >
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body2"
                                                >
                                                    {resource.name}
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            <Typography
                                                color="textPrimary"
                                                variant="body2"
                                            >
                                                {resource.resourceTypeName}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            {resource.building &&
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body2"
                                                >
                                                    {resource.building}, {resource.level ? resource.level : ' - '}, {resource.unit ? resource.unit : ' - '}
                                                </Typography>}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            {resource.unitId &&
                                                <Tooltip title={t('MAP') || ''} aria-label={t('MAP') || ''}>
                                                    <IconButton>
                                                        <LocationOn
                                                            fontSize="small"
                                                            onClick={async (evt) => {
                                                                if (resource.unitId) {
                                                                    evt.preventDefault();
                                                                    setIsDetailPositionModelOpen(true);
                                                                    await getResourcePositionAsync(resource);
                                                                }
                                                            }} />
                                                    </IconButton>
                                                </Tooltip>}
                                        </Box>
                                    </TableCell>
                                </TableRow>)}
                        </TableBody>
                    </Table>}
            </Box>
        </Scrollbar>
        <Dialog
            fullWidth
            maxWidth="lg"
            onClose={closePositionModal}
            open={isDetailPositionModelOpen}
        >
            <Card>
                <Box
                    sx={{
                        display: 'inline',
                        m: 2,
                        pr: 3
                    }}
                >
                    <Typography
                        align="center"
                        color="textPrimary"
                        gutterBottom
                        variant="h5"
                    >
                        {t('MAP')}
                        <IconButton
                            sx={{
                                float: 'right',
                                mr: 3
                            }}
                            onClick={closePositionModal}>
                            <XIcon fontSize="small" />
                        </IconButton>
                    </Typography>
                </Box>
                <Divider />
                {backgroundMediaSizes && backgroundMediaAsBase64 && anchor
                    ? <div>
                        <Map
                            zoom={0}
                            minZoom={-10}
                            maxZoom={10}
                            scrollWheelZoom={true}
                            crs={CRS.Simple}
                            center={[backgroundMediaSizes.h / 2, backgroundMediaSizes.w / 2]}>

                            <ImageOverlay
                                bounds={[[0, 0], [backgroundMediaSizes.h, backgroundMediaSizes.w]]}
                                url={backgroundMediaAsBase64} />

                            <Marker
                                icon={iconBlue}
                                draggable={false}
                                position={[anchor[0], anchor[1]]}>
                            </Marker>
                        </Map>
                    </div>
                    : <Loader isLoading={true} />}
            </Card>
        </Dialog>
    </>);
}

export default AvailableResources;
