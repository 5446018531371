import axios from "axios";
import ServiceConfig from "../config/serviceConfig";
import { UserSelectItem } from "../types/user";
import { PaginationResult } from '../types/paginationResult';
import { User } from "../models/User";
import { CanInsert } from "../models/CanInsert";
import { SmartDeskCommand } from "../types/smartDeskStatus";

export class UserService {


  async findUser(id: string, tenantId: string): Promise<User> {
    try {
      const response = await axios.get<User>(ServiceConfig.serviceBaseUrl + "/FindByUserId", {
        params: {
          id: id,
          tenantId: tenantId
        }
      });
      if (response.status === 200) {
        return response.data;

      }
      else {
        throw new Error("findUser error");
      }
    }
    catch (error) {
      throw new Error("findUser error:" + error);
    }
  }

  async searchUsers(tenantId: string, pageNumber: number, pageSize: number, text: string): Promise<PaginationResult<User>> {
    try {
      const response = await axios.get<PaginationResult<User>>(ServiceConfig.serviceBaseUrl + "/SearchUsers", {
        params: {
          tenantId: tenantId,
          pageNumber: pageNumber,
          pageSize: pageSize,
          text: text
        }
      });
      if (response.status === 200) {
        return response.data;

      }
      else {
        throw new Error("searchUsers error");
      }
    }
    catch (error) {
      throw new Error("searchUsers error:" + error);
    }
  }

  async upsertUser(user: User): Promise<boolean> {
    try {
      const response = await axios.post<User>(ServiceConfig.serviceBaseUrl + "/UpsertUser", user);
      if (response.status === 200) {
        return Boolean(response.data);
      }
      else {
        throw new Error("upsertUser error");
      }
    }
    catch (error) {
      throw new Error("upsertUser error:" + error);
    }
  }
  async setSmartDesk(smartDesk: SmartDeskCommand): Promise<boolean> {
    try {
      const response = await axios.post<boolean>(ServiceConfig.serviceBaseUrl + "/SetSmartDesk", smartDesk);
      if (response.status === 200) {
        return Boolean(response.data);
      }
      else {
        throw new Error("setSmartDesk error");
      }
    }
    catch (error) {
      throw new Error("setSmartDesk error:" + error);
    }
  }
  async getSmartDeskStatus(tenantId: string): Promise<SmartDeskCommand | null> {
    try {
      const response = await axios.get<SmartDeskCommand | null>(ServiceConfig.serviceBaseUrl + "/GetSmartDeskStaus", {
        params: {
          tenantId: tenantId
        }
      });
      if (response.status === 200) {
        return response.data;
      }
      else if (response.status === 204) {
        return null;
      }
      else {
        throw new Error("get smartDesk error");
      }
    }
    catch (error) {
      throw new Error("get smartDesk:" + error);
    }
  }

  async getUserSelectItems(tenantId: string): Promise<UserSelectItem[]> {
    try {
      const response = await axios.get<UserSelectItem[]>(ServiceConfig.serviceBaseUrl + "/GetUserSelectItems", {
        params: {
          tenantId: tenantId
        }
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("getUserSelectItems error");
      }
    } catch (error) {
      throw new Error("getUserSelectItems error:" + error);
    }
  }

  async canInsertUser(email: string, tenantId: string): Promise<CanInsert> {
    try {
      const response = await axios.get(ServiceConfig.serviceBaseUrl + "/CanInsertUser", {
        params: {
          email: email,
          tenantId: tenantId
        }
      });
      if (response.status === 200) {
        return response.data;

      }
      else {
        throw new Error("canInsertUser error");
      }
    }
    catch (error) {
      throw new Error("canInsertUser error:" + error);
    }
  }

  async removeTenantUser(email: string, tenantId: string): Promise<boolean> {
    try {
      const response = await axios.get(ServiceConfig.serviceBaseUrl + "/RemoveTenantUser", {
        params: {
          email: email,
          tenantId: tenantId
        }
      });
      if (response.status === 200) {
        return response.data;
      }
      else {
        throw new Error("removeTenantUser error");
      }
    }
    catch (error) {
      throw new Error("removeTenantUser error:" + error);
    }
  }

  async changeCurrentTenant(tenantId: string): Promise<boolean> {
    try {
      const response = await axios.get(ServiceConfig.serviceBaseUrl + "/ChangeCurrentTenant", {
        params: {
          tenantId: tenantId
        }
      });
      if (response.status === 200) {
        return response.data;
      }
      else {
        throw new Error("changeCurrentTenant error");
      }
    }
    catch (error) {
      throw new Error("changeCurrentTenant error:" + error);
    }
  }

}
