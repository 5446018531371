import axios from 'axios';
import ServiceConfig from "../config/serviceConfig";
import { BuildingWithDetail } from '../types/building';
import { CalendarEvent } from '../types/calendar';
import { Reservation } from '../types/reservation';
import { ResponseResult } from '../types/responseResult';
import { availableColors } from '../utils/colorPicker';
import { toLocalDate } from '../utils/common';
import { ReservationItemChart } from "../components/dashboard/NextReservation";

export class ReservationService {

    async canInsertReservation(reservation: Reservation): Promise<ResponseResult> {
        try {

            const response = await axios.post<ResponseResult>(ServiceConfig.serviceBaseUrl + "/CanInsertReservationEvent", reservation);
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("CanInsertReservationEvent error");
            }
        }
        catch (error) {
            throw new Error("CanInsertReservationEvent error:" + error);
        }
    }
  
    async getReservationForSmartDesk(deviceId: string): Promise<Reservation> {
        try {

            const response = await axios.post<Reservation>(ServiceConfig.serviceBaseUrl + "/getReservationForSmartDesk", {deviceId: deviceId});
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("getReservationForSmartDesk error");
            }
        }
        catch (error) {
            throw new Error("getReservationForSmartDesk error:" + error);
        }
    }

    async upsertReservation(reservation: any): Promise<boolean> {
        try {
            reservation.appUrl = ServiceConfig.appUrl;
            const response = await axios.post(ServiceConfig.serviceBaseUrl + "/UpsertReservationEvent", reservation);
            if (response.status === 200) {
                return true;
            }
            else {
                throw new Error("UpsertReservationEvent error");
            }
        }
        catch (error) {
            throw new Error("UpsertReservationEvent error:" + error);
        }
    }

    async getReservationEvents(resourceIds: string[], tenantId: string, from: Date, to: Date): Promise<CalendarEvent[]> {
        try {
            var request: any = {
                resourceIds: resourceIds,
                tenantId: tenantId,
                from: from,
                to: to
            }

            const response = await axios.post(ServiceConfig.serviceBaseUrl + "/GetReservationEvents", request);

            if (response.status === 200) {

                var groupdIds = response.data?.map(item => item.groupId)
                    .filter((value, index, self) => self.indexOf(value) === index)

                var events = response.data?.map((data: any, index) => {

                    var color = '#EA3546'

                    try {
                        if (data.groupId != null && groupdIds?.includes(data.groupId)) {
                            var groupIdsIndex = groupdIds.indexOf(data.groupId);
                            if (groupIdsIndex > 49) {
                                color = '#EA3546';
                            }
                            else {
                                color = availableColors()[groupIdsIndex];
                            }
                        } else {
                            if (index > 49) {
                                var randomIndex = Math.floor(Math.random() * 49);
                                color = availableColors()[randomIndex];
                            } else {
                                color = availableColors()[index];
                            }
                        }

                        if (data.isReadOnly) {
                            color = '#919eab';
                        }
                    } catch (e) {
                        console.log(e);
                    }

                    var event: CalendarEvent = {
                        id: data.id,
                        allDay: false,
                        title: data.resourceName,
                        description: data.description,
                        start: toLocalDate(data.dateTimeFrom, data.timezoneId),
                        end: toLocalDate(data.dateTimeTo, data.timezoneId),
                        users: data.users,
                        color: color,
                        isReadOnly: data.isReadOnly
                    }

                    return event;
                })

                return events;
            }
            else {
                throw new Error("getReservationEvents error");
            }
        }
        catch (error) {
            throw new Error("getReservationEvents error:" + error);
        }
    }

    async findReservationById(reservationId: string): Promise<Reservation> {
        try {
            const response = await axios.get(ServiceConfig.serviceBaseUrl + "/FindByReservationEventId", {
                params: {
                    id: reservationId
                }
            });
            if (response.status === 200) {
                response.data.dateTimeTo = toLocalDate(response.data.dateTimeTo, response.data.timezoneId);
                response.data.dateTimeFrom = toLocalDate(response.data.dateTimeFrom, response.data.timezoneId);
                if (response.data.pattern) {
                    response.data.pattern.toDate = toLocalDate(response.data.pattern.toDate, response.data.timezoneId);
                }
                return response.data;
            }
            else {
                throw new Error("FindByReservationEventId error");
            }
        }
        catch (error) {
            throw new Error("FindByReservationEventId error:" + error);
        }
    }

    async changeDateReservationEvent(reservationId: string, dateFrom: Date, dateTo: Date): Promise<ResponseResult> {
        try {

            let body: any = {
                id: reservationId,
                dateTimeFrom: dateFrom,
                dateTimeTo: dateTo,
                appUrl: ServiceConfig.appUrl
            }

            const response = await axios.post<ResponseResult>(ServiceConfig.serviceBaseUrl + "/ChangeDateReservationEvent", body);

            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("ChangeDateReservationEvent error");
            }
        }
        catch (error) {
            throw new Error("ChangeDateReservationEvent error:" + error);
        }
    }

    async deleteReservationEvent(reservationId: string, editAllNewEvents: boolean): Promise<boolean> {
        try {

            var body: any = {
                id: reservationId,
                editAllNewEvents: editAllNewEvents
            }

            const response = await axios.post(ServiceConfig.serviceBaseUrl + "/DeleteReservationEvent", body);

            if (response.status === 200) {
                return true;
            }
            else {
                throw new Error("GetDeleteReservationEventApplications error");
            }
        }
        catch (error) {
            throw new Error("DeleteReservationEvent error:" + error);
        }
    }

    async getBuildingsWithDetails(tenantId: string): Promise<BuildingWithDetail> {
        try {
            const response = await axios.get<BuildingWithDetail>(ServiceConfig.serviceBaseUrl + "/GetBuildingsWithDetails", {
                params: {
                    tenantId: tenantId
                }
            });

            return response.data;
        }
        catch (error) {
            throw new Error("GetBuildingsWithDetails error:" + error);
        }
    }

    async executeCheckIn(reservationId: string, note: string): Promise<boolean> {
        try {
            var body: any = {
                reservationEventId: reservationId,
                note: note
            }

            let response = await axios.post<boolean>(ServiceConfig.serviceBaseUrl + "/CheckIn", body);
            if (response.status === 200) {
                return true;
            }
        }
        catch (error) {
            throw new Error("executeCheckIn error:" + error);
        }
        return false;
    }

    async externalCheckIn(reservationEventId: string, note: string, email: string): Promise<boolean> {
        try {
            let body: any = {
                email: email,
                reservationEventId: reservationEventId,
                note: note
            }

            let response = await axios.post<boolean>(ServiceConfig.serviceBaseUrl + "/ExternalCheckIn", body);

            if (response.status === 200) {
                return true;
            }
        }
        catch (error) {
            throw new Error("GetBuildingsWithDetails error:" + error);
        }
        return false;
    }

    async getExternalReservation(reservationEventId: string, email: string): Promise<ReservationItemChart> {
        try {
            let body: any = {
                reservationEventId: reservationEventId,
                email: email
            }

            let response = await axios.post<ReservationItemChart>(ServiceConfig.serviceBaseUrl + "/GetExternalReservation", body);
            return response.data;
        }
        catch (error) {
            throw new Error("GetBuildingsWithDetails error:" + error);
        }
    }
}