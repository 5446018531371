/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Divider, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface resourceTypeDataDetailsProps {
    id?: string;
    code?: string;
    name?: string;
    minimumEventMinutes?: number;
}

const resourceTypeDataDetails: FC<resourceTypeDataDetailsProps> = (props) => {
    const {
        id,
        code,
        name,
        minimumEventMinutes,
        ...other
    } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();

    return (
        <Card {...other}>
            <CardHeader title={t('RESOURCE_TYPE_DETAILS')} />
            <Divider />
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('CODE')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {code}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('NAME')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {name}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('MINIMUM_EVENT_MINUTES')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {minimumEventMinutes}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
    );
};

resourceTypeDataDetails.propTypes = {
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    minimumEventMinutes: PropTypes.number
};

export default resourceTypeDataDetails;
