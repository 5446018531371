import type { FC } from 'react';
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid, Switch,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core';
import { ResourceService } from '../../services/ResourceService';
import { Resource } from '../../types/resource';
import { LocalizationAnchor } from '../../types/level';
import { ImageOverlay, Map, Marker } from 'react-leaflet';
import L, { CRS } from 'leaflet';
import { useTranslation } from 'react-i18next';
import markerBlue from '../../icons/pureSvg/blue-marker.svg';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import { SmartDeskService } from "../../services/SmartDeskService";
import { SmartDeskCommand } from "../../types/smartDeskStatus";

interface ResourceProps {
    resource: Resource;
}

const ResourcePosition: FC<ResourceProps> = (props) => {

        const { resource } = props;
        const { t } = useTranslation();
        const resourceService = new ResourceService();

        const [localizationResource, setLocalizationResource] = useState<LocalizationAnchor>();
        const [backgroundMediaAsBase64, setBackgroundMediaAsBase64] = useState<string>();
        const [backgroundMediaSizes, setBackgroundMediaSizes] = useState<{ w: number, h: number }>();
        const [anchor, setAnchor] = useState<number[]>();
        const [isSwitched, setIsSwitched] = useState<boolean>(false);

        const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);

        useEffect(() => {
            (async () => {
                let resourcePosition = await resourceService.findAnchorByResourceId(resource.id);
                setLocalizationResource(resourcePosition);

                let image = new Image();
                image.src = resourcePosition.base64;
                image.onload = () => {
                    let sizes: any = {
                        w: image.width as number,
                        h: image.height as number
                    };
                    setBackgroundMediaSizes(sizes);
                }

                setBackgroundMediaAsBase64(resourcePosition.base64);
                setAnchor(resourcePosition.coordinates);
            })();
        }, []);

        const iconBlue = L.icon({
            iconUrl: markerBlue,
            iconSize: [32, 34],
            iconAnchor: [16, 0]
        });

        return (
            <>{localizationResource ?
                <>
                    <Grid
                        container
                        spacing={3}
                        sx={{ p: 3 }}
                    >
                        <Grid
                            item
                            lg={3}
                            md={6}
                            xl={3}
                            xs={12}
                        >
                            <Card>
                                <CardHeader title={t('INFO')}/>
                                <Divider/>
                                <CardContent>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        {t('BUILDING')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {localizationResource.buildingName}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        {t('LEVEL')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {localizationResource.levelName}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        {t('RESOURCE')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {localizationResource.resourceName}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        {t('COUNTRY')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {localizationResource.country}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        {t('CITY')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {localizationResource.locality}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        {t('ADDRESS')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {localizationResource.address}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        {t('STATE')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {localizationResource.province}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    {resource.resourceTypeCode === "IGL" &&
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            py: 0
                                        }}>
                                        <Typography
                                            color="textPrimary"
                                            variant="h5"
                                        >
                                            {t('LOCALIZE')}
                                        </Typography>
                                        <Switch color="primary" checked={isSwitched} onClick={async () => {
                                            if (!isSwitched) {
                                                const smartDeskService = new SmartDeskService(userInfo?.apiKey || '');
                                                let body: SmartDeskCommand = {
                                                    deskEngine: { height: 720 },
                                                    ledStrip: {
                                                        onOff: true,
                                                        brightness: 0,
                                                        effect: 3,
                                                        rgbColor: "#19A6C9"
                                                    },
                                                    smartLamp: { onOff: false, brightness: 0, white: 0 },
                                                    smartSocket: { onOff: false },
                                                    data: {
                                                        smartLampEnergy: 0,
                                                        stripLedEnergy: 0,
                                                        smartSocketEnergy: 0
                                                    }
                                                }
                                                await smartDeskService.setStatus(body, resource.id);
                                            } else {
                                                const smartDeskService = new SmartDeskService(userInfo?.apiKey || '');
                                                let body: SmartDeskCommand = {
                                                    deskEngine: { height: 720 },
                                                    ledStrip: {
                                                        onOff: false,
                                                        brightness: 0,
                                                        effect: 0,
                                                        rgbColor: "#19A6C9"
                                                    },
                                                    smartLamp: { onOff: false, brightness: 0, white: 0 },
                                                    smartSocket: { onOff: false },
                                                    data: {
                                                        smartLampEnergy: 0,
                                                        stripLedEnergy: 0,
                                                        smartSocketEnergy: 0
                                                    }
                                                }
                                                await smartDeskService.setStatus(body, resource.id);
                                            }

                                            setIsSwitched(!isSwitched);
                                        }}/>
                                    </Box>}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            lg={9}
                            md={12}
                            xl={9}
                            xs={12}
                        >
                            {backgroundMediaAsBase64 && backgroundMediaSizes && anchor &&
                            <Card>
                                <CardHeader title={t('MAP')}/>
                                <Divider/>
                                <Table>
                                    <TableRow>
                                        <div>
                                            <Map
                                                zoom={0}
                                                minZoom={-10}
                                                maxZoom={10}
                                                scrollWheelZoom={true}
                                                crs={CRS.Simple}
                                                center={[backgroundMediaSizes.h / 2, backgroundMediaSizes.w / 2]}>

                                                <ImageOverlay
                                                    bounds={[[0, 0], [backgroundMediaSizes.h, backgroundMediaSizes.w]]}
                                                    url={backgroundMediaAsBase64}/>

                                                <Marker
                                                    icon={iconBlue}
                                                    draggable={false}
                                                    position={[anchor[0], anchor[1]]}>
                                                </Marker>
                                            </Map>
                                        </div>
                                    </TableRow>
                                </Table>
                            </Card>}
                        </Grid>
                    </Grid>
                </>
                : <Typography
                    align="center"
                    color="textSecondary"
                    sx={{ p: 2 }}
                    variant="subtitle2"
                >
                    {'La risorsa selezionata non è stata posizionata in nessuna stanza.'}
                </Typography>}
            </>)
    }
;

export default ResourcePosition;
