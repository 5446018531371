import type { ChangeEvent, FC } from 'react';
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, FormControlLabel, Menu, MenuItem } from '@material-ui/core';
import ChevronDownIcon from '../icons/ChevronDown';

interface MultiSelectProps {
  label: string;
  onChange?: (value: string[]) => void;
  options: any[];
  value: string[];
}

const MultiSelect: FC<MultiSelectProps> = (props) => {
  const {
    label,
    onChange,
    options,
    value,
    ...other
  } = props;
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleMenuOpen = (): void => {
    setOpenMenu(true);
  };

  const handleMenuClose = (): void => {
    setOpenMenu(false);
  };

  const handleOptionToggle = (event: ChangeEvent<HTMLInputElement>): void => {
    let newValue = [...value];

    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      newValue = newValue.filter((item) => item !== event.target.value);
    }

    if (onChange) {
      onChange(newValue);
    }
  };


  return (
    <>
      <Button className="orange-multi-select-active"
        color="inherit"
        endIcon={<ChevronDownIcon fontSize="small" style={{ display: 'inline-block !important', float: 'right' }} />}
        onClick={handleMenuOpen}
        ref={anchorRef}
        variant="text"
        style={{ justifyContent: 'space-between', width: '100%' }}
        {...other}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{ style: { width: 400 } }}
      >
        {options.map((option) => (
          <MenuItem key={option}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={value.indexOf(option) > -1}
                  color="primary"
                  onChange={handleOptionToggle}
                  value={option}
                />
              )}
              label={option}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired
};

export default MultiSelect;
