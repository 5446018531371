import './App.css';
import Routes from './Routes';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import { createHashHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from './theme';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import { it } from "date-fns/locale";
import { FC, useEffect, useState } from "react";
import ExternalCheckIn from "./components/checkin/ExternalCheckIn";
import SmartDeskContainer from "./pages/SmartDeskContainer";

const history = createHashHistory();
const store = configureStore(history);

const newtheme = createTheme({
    direction: 'ltr',
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: 'dark'
});

export const currentTheme = newtheme;

const locales = { it };

const App: FC = () => {

    const [redirectToExternalDashboard, setRedirectToExternalDashboard] = useState<boolean>(true);
    
    useEffect(() => {
        let url = window.location.href;
        
        if (url.indexOf("?externaldashboard=") !== -1) {
            setRedirectToExternalDashboard(true);
        } else {
            setRedirectToExternalDashboard(false);
        }
        
    }, [redirectToExternalDashboard]);
    
    return (
        <ThemeProvider theme={newtheme}>
            <Provider store={store}>
                <SnackbarProvider
                    dense
                    maxSnack={3}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locales['it']}>
                        {!redirectToExternalDashboard
                            ? <Routes/>
                            : <SmartDeskContainer/>
                        }
                    </LocalizationProvider>
                </SnackbarProvider>
            </Provider>
        </ThemeProvider>
    );
}

export default App;
