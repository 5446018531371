import type { FC } from 'react';
import { useEffect } from 'react';
import { Box, Grid, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DesktopDateTimePicker, MobileDateTimePicker } from '@material-ui/lab';
import { addHours, addMinutes } from 'date-fns/esm';
import { deepClone, isToday } from '../../../utils/common';

interface EventTypeProps {
    step2Prop?: Step2;

    setStep2Prop(val: Step2): void;
}

const SingleEvent: FC<EventTypeProps> = (props) => {

    const { setStep2Prop, step2Prop } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        let startParam = window.location.href.split('start=')[1] ?? '';

        if (startParam) {
            let parts = startParam.split('/');
            console.log(parts);
            console.log(parseInt(parts[2]));
            console.log(parseInt(parts[1]));
            console.log(parseInt(parts[0]));
            
            let start =  new Date(`${parts[1]} ${parts[0]} ${parts[2]}`);

            console.log(isToday(start))
            if (isToday(start)) {
                let step2: Step2 = {
                    from: addMinutes(new Date(), 30),
                    to: addMinutes(new Date(), 90),
                    fromTime:  addMinutes(new Date(), 30),
                    toTime: addMinutes(new Date(), 90)
                };
                setStep2Prop(step2);

            } else {
                let step2: Step2 = {
                    from: addHours(start, 9),
                    to: addHours(start, 18),
                    fromTime: new Date(start),
                    toTime: new Date(start)
                };
                setStep2Prop(step2);
            }
        } else {
            let today = addMinutes(new Date(), 30);
            let step2: Step2 = {
                from: today,
                to: addHours(today, 1),
                fromTime: today,
                toTime: today
            };

            setStep2Prop(step2);
        }
    }, [])

    return (<>{step2Prop &&
    <Grid
        container
        justifyContent="center"
    >
        <Box sx={{ textAlign: 'center', py: 5, display: 'flex' }}>
            <Box sx={{ mr: 1 }}>
                {isMobile
                    ? <MobileDateTimePicker
                        cancelText={t('CANCEL')}
                        minDate={new Date()}
                        inputFormat={t('DATE_PICKER_FULL_DATE')}
                        ampm={false}
                        ampmInClock={false}
                        label={t('START_DATE')}
                        onChange={(date) => {
                            step2Prop.from = date || new Date();
                            setStep2Prop(deepClone(step2Prop));
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={step2Prop?.from}
                    />
                    : <DesktopDateTimePicker
                        minDateTime={new Date()}
                        inputFormat={t('DATE_PICKER_FULL_DATE')}
                        ampm={false}
                        ampmInClock={false}
                        label={t('START_DATE')}
                        onChange={(date) => {
                            step2Prop.from = date || new Date();
                            setStep2Prop(deepClone(step2Prop));
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={step2Prop?.from}
                    />}
            </Box>
            <Box sx={{ ml: 1 }}>
                {isMobile
                    ? <MobileDateTimePicker
                        cancelText={t('CANCEL')}
                        minDate={new Date()}
                        inputFormat={t('DATE_PICKER_FULL_DATE')}
                        ampm={false}
                        ampmInClock={false}
                        label={t('END_DATE')}
                        onChange={(date) => {
                            step2Prop.to = date || new Date();
                            setStep2Prop(deepClone(step2Prop));
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={step2Prop?.to}
                    />
                    : <DesktopDateTimePicker
                        minDateTime={new Date()}
                        inputFormat={t('DATE_PICKER_FULL_DATE')}
                        ampm={false}
                        ampmInClock={false}
                        label={t('END_DATE')}
                        onChange={(date) => {
                            step2Prop.to = date || new Date();
                            setStep2Prop(deepClone(step2Prop));
                        }}
                        renderInput={(inputProps) => (
                            <TextField
                                fullWidth
                                variant="outlined"
                                {...inputProps}
                            />
                        )}
                        value={step2Prop?.to}
                    />}
            </Box>
        </Box>
    </Grid>}
        <div style={{ padding: '5px', color: '#f44336', display: 'inline' }}>
            {step2Prop && step2Prop.to < step2Prop.from && t('VALIDATION_CALENDAR_START_DATE')}
        </div>
    </>);
}

export default SingleEvent;

export interface Step2 {
    from: Date;
    to: Date;
    fromTime: Date;
    toTime: Date;
}