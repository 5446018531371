import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@material-ui/core';
import ResourceAddForm from '../components/resource/ResourceAddForm';
import ChevronRightIcon from '../icons/ChevronRight';
import type { Resource } from '../types/resource';
import { ResourceService } from '../services/ResourceService';
import { deepClone } from '../utils/common';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

interface ResourceAddProps {
  isAdd: boolean;
}

const ResourceAdd: FC<ResourceAddProps> = (props) => {
  const { isAdd } = props;
  const [resource, setResource] = useState<Resource | null>(null);
  const [isAddMode, setIsAddMode] = useState<boolean>();
  const history = useNavigate();
  const { t } = useTranslation();
  const currentTheme = useTheme();
  const resourceService = new ResourceService();

  const updateResource = (resource: Resource): void => {
    setResource(deepClone(resource));
  }

  useEffect(() => {
    setIsAddMode(isAdd);
    (async () => {
      if (isAdd) {
        let resourceId = window.location.href.split('id=')[1] ?? '';
        if (resourceId === ''){
          const rsx: Resource = {
            id: '',
            users: [""],
            name: '',
            resourceTypeCode: '',
            description: '',
            tenantId: '',
            documents: [],
            avatar: '',
            capacity: 1,
            effectiveCapacity: 1
          };
          setResource(rsx);
        }
        else {
          try {
            var res = await resourceService.findResource(resourceId);
            res.id = '';
            res.documents = [];
            setResource(res);
  
          } catch (err) {
            console.error(err);
            history('/500');
          }
        }
      } else {
        try {
          let resourceId = window.location.href.split('id=')[1] ?? '';
          var res = await resourceService.findResource(resourceId);
          setResource(res);

        } catch (err) {
          console.error(err);
          history('/500');
        }
      }
    })();
  }, [isAdd, isAddMode]);

  if (!resource) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 2,
          [currentTheme.breakpoints.up('lg')]: {
            paddingLeft: '300px'
          }
        }}
      >
        <Container>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {isAdd ? t('ADD_RESOURCE') : t('EDIT_RESOURCE')}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  {t('DASHBOARD')}
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/resources"
                  variant="subtitle2"
                >
                  {t('RESOURCES')}
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {isAdd ? t('ADD') : t('EDIT')}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box mt={3}>
            <ResourceAddForm resource={resource} isAdd={isAddMode!} updateResource={updateResource} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ResourceAdd;
