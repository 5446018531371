import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";

interface SmartLampProps {
    smartLampEnergy: number;
    stripLedEnergy: number;
    smartSocketEnergy: number;
}

const WattUsage: FC<SmartLampProps> = (props) => {

    return (<>
        <Grid
            sx={{ py: 0 }}
            item
            md={6}
            sm={6}
            xs={6}
        >
            <Card>
                <CardHeader
                    disableTypography
                    title={(
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {'Consumo'}
                            </Typography>
                        </Box>
                    )}
                />
                <CardContent>
                    <Box
                        sx={{
                            alignItems: 'left',
                            textAlign: 'left'

                        }}>
                        <Typography
                            color="textPrimary"
                            variant="h6"
                        >
                           Smart Lamp {props.smartLampEnergy} W/m
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h6"
                        >
                           Strip Led {props.stripLedEnergy} W/m
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h6"
                        >
                           Smart socket {props.smartSocketEnergy} W/m
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    </>);
}

export default WattUsage;
