import * as React from 'react';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { AppInitState } from '../../store/AppInit';
import { useTranslation } from 'react-i18next';

const InitStatus = (): ReactElement => {
    const initState = useSelector<AppState, AppInitState>(state => state.appInit);
    const { t } = useTranslation();

    if (initState.initializationError) {
        return <div style={{fontSize:'smaller'}}>{t('DESCRIPTION_INIT_ERROR')}</div>
    }
    else if (!initState.userInfo) {
        return <div style={{fontSize:'smaller'}}>{t('DESCRIPTION_INIT_USERINFO')}</div>;
    }
    else if (!initState.isOfflineConfigInitialized) {
        return <div style={{fontSize:'smaller'}}>{t('DESCRIPTION_INIT_INFO_NOT_OFFLINE')}</div>
    }
    else {
        return <div style={{fontSize:'smaller'}}>{t('DESCRIPTION_INIT_INFO')}</div>
    }
};
export default InitStatus;