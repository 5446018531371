import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from '@material-ui/core';
import { ResourceType } from '../types/resourceType';
import ChevronRightIcon from '../icons/ChevronRight';
import PlusIcon from '../icons/Plus';
import ResourceTypeListTable from '../components/resourceType/ResourceTypeListTable';
import { ResourceTypeService } from '../services/ResourceTypeService';
import { useSelector } from "react-redux";
import { AppState } from "../store";
import { UserInfo } from "../models/UserInfo";
import { PaginationResult } from '../types/paginationResult';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTheme } from '@material-ui/core/styles';

const ResourceTypeList: FC = () => {

    const { t } = useTranslation();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const history = useNavigate();
    var resourceTypeService = new ResourceTypeService();
    const [resourceTypes, setResourceTypes] = useState<PaginationResult<ResourceType>>();
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    const getResourceTypes = useCallback(async () => {
        setIsPageLoading(true);
        try {
            if (userInfo) {
                var res = await resourceTypeService.searchResourceTypes(userInfo.tenantId, 0, 5, '');
                setResourceTypes(res);
            }
        } catch (e) {
            console.error(e);
            history('/500');
        }
        setIsPageLoading(false);

    }, [userInfo]);

    useEffect(() => {
        getResourceTypes();
    }, [getResourceTypes]);

    const handlePageChange = (event: any, newPage: number): void => {
        setIsPageLoading(true);
        (async () => {
            try {
                if (userInfo) {
                    var res = await resourceTypeService.searchResourceTypes(userInfo.tenantId, newPage, 5, '');
                    setResourceTypes(res);
                }
            } catch (e) {
                console.error(e);
                history('/500');
            }
        })();
        setIsPageLoading(false);
    };

    const handleLimitChange = (limit: any): void => {
        setIsPageLoading(true);
        (async () => {
            try {
                if (userInfo) {
                    var res = await resourceTypeService.searchResourceTypes(userInfo.tenantId, 0, limit, '');
                    setResourceTypes(res);
                }
            } catch (err) {
                console.error(err);
                history('/500');
            }
        })();
        setIsPageLoading(false);

    };

    const handleQueryChange = (event: any): void => {
        setIsPageLoading(true);
        (async () => {
            try {
                if (userInfo) {
                    var res = await resourceTypeService.searchResourceTypes(userInfo.tenantId, 0, 5, event.target.value);
                    setResourceTypes(res);
                }
            } catch (e) {
                console.error(e);
                history('/500');
            }
        })();
        setIsPageLoading(false);
    };

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 2,
                [useTheme().breakpoints.up('lg')]: {
                    paddingLeft: '300px'
                }
            }}
        >
            <Container>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('RESOURCE_TYPE_LIST')}
                        </Typography>
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<ChevronRightIcon fontSize="small" />}
                            sx={{ mt: 1 }}
                        >
                            <Link
                                color="textPrimary"
                                component={RouterLink}
                                to="/"
                                variant="subtitle2"
                            >
                                {t('DASHBOARD')}
                            </Link>
                            <Typography
                                color="textSecondary"
                                variant="subtitle2"
                            >
                                {t('RESOURCE_TYPES')}
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <Box sx={{ m: -1 }}>
                            <Button
                                component={RouterLink}
                                to="add"
                                color="primary"
                                startIcon={<PlusIcon fontSize="small" />}
                                sx={{ m: 1 }}
                                variant="contained"
                            >
                                {t('ADD_RESOURCE_TYPE')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 3 }}>
                    {!isPageLoading ?
                        resourceTypes && <ResourceTypeListTable
                            paginationResult={resourceTypes}
                            handlePageChange={handlePageChange}
                            handleLimitChange={handleLimitChange}
                            handleQueryChange={handleQueryChange} />
                        : (<>
                            <Skeleton variant='text' width='100%' height={180} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                        </>)}
                </Box>
            </Container>
        </Box>);
};

export default ResourceTypeList;
