import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from '@material-ui/core';
import UserListTable from '../components/user/UserListTable';
import ChevronRightIcon from '../icons/ChevronRight';
import PlusIcon from '../icons/Plus';
import { useSelector } from "react-redux";
import { AppState } from "../store";
import { User } from '../models/User';
import { UserInfo } from "../models/UserInfo";
import { UserService } from '../services/UserService';
import { PaginationResult } from '../types/paginationResult';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTheme } from '@material-ui/core/styles';

const UserList: FC = () => {
  const [users, setUsers] = useState<PaginationResult<User>>();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
  const history = useNavigate();
  const { t } = useTranslation();
  var userService = new UserService();

  const getUsers = useCallback(async () => {
    setIsPageLoading(true);
    try {
      if (userInfo) {
        var uss = await userService.searchUsers(userInfo.tenantId, 0, 5, '');
        setUsers(uss);
      }

    } catch (err) {
      console.error(err);
    }
    setIsPageLoading(false);

  }, [userInfo]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handlePageChange = (event: any, newPage: number): void => {
    setIsPageLoading(true);
    (async () => {
      try {
        if (userInfo) {
          var uss = await userService.searchUsers(userInfo.tenantId, newPage, 5, '');
          setUsers(uss);
        }
      }
      catch (err) {
        console.error(err);
        history('/500');
      }
    })();
    setIsPageLoading(false);
  };

  const handleLimitChange = (limit: any): void => {
    setIsPageLoading(true);
    (async () => {
      try {
        if (userInfo) {
          var uss = await userService.searchUsers(userInfo.tenantId, 0, limit, '');
          setUsers(uss);
        }
      }
      catch (err) {
        console.error(err);
        history('/500');
      }
    })();
    setIsPageLoading(false);
  };

  const handleQueryChange = (event: any): void => {
    setIsPageLoading(true);
    (async () => {
      try {
        if (userInfo) {
          var uss = await userService.searchUsers(userInfo.tenantId, 0, 5, event.target.value);
          setUsers(uss);
        }
      }
      catch (err) {
        console.error(err);
        history('/500');
      }
    })();

    setIsPageLoading(false);
  };



  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 2,
        [useTheme().breakpoints.up('lg')]: {
          paddingLeft: '300px'
        }
      }}
    >
      <Container >
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {t('USER_LIST')}
            </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRightIcon fontSize="small" />}
              sx={{ mt: 1 }}
            >
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/"
                variant="subtitle2"
              >
                {t('DASHBOARD')}
              </Link>
              <Typography
                color="textSecondary"
                variant="subtitle2"
              >
                {t('USERS')}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <Box sx={{ m: -1 }}>
              <Button
                component={RouterLink}
                to="add"
                color="primary"
                startIcon={<PlusIcon fontSize="small" />}
                sx={{ m: 1 }}
                variant="contained"
              >
                {t('ADD_USER')}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          {!isPageLoading ?
            users && <UserListTable
              paginationResult={users}
              handlePageChange={handlePageChange}
              handleLimitChange={handleLimitChange}
              handleQueryChange={handleQueryChange} />
            : (<>
              <Skeleton variant='text' width='100%' height={180} animation="wave" />
              <Skeleton variant='text' width='100%' height={100} animation="wave" />
              <Skeleton variant='text' width='100%' height={100} animation="wave" />
              <Skeleton variant='text' width='100%' height={100} animation="wave" />
              <Skeleton variant='text' width='100%' height={100} animation="wave" />
              <Skeleton variant='text' width='100%' height={100} animation="wave" />
            </>)}
        </Box>
      </Container>
    </Box>
  );
};

export default UserList;
