import type { ChangeEvent, FC } from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Avatar,
    Box,
    Card,
    Divider,
    IconButton,
    InputAdornment,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tabs,
    TextField,
    Typography
} from '@material-ui/core';
import ArrowRightIcon from '../../icons/ArrowRight';
import PencilAltIcon from '../../icons/PencilAlt';
import SearchIcon from '../../icons/Search';
import { User } from '../../models/User';
import getInitials from '../../utils/getInitials';
import Scrollbar from '../Scrollbar';
import { PaginationResult } from '../../types/paginationResult';
import { StringIsNotNullOrEmpty } from '../../utils/common';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';

interface UserListTableProps {
  paginationResult: PaginationResult<User>;
  handlePageChange(event: any, newPage: number): void;
  handleLimitChange(event: any): void;
  handleQueryChange(event: any): void;
}

const UserListTable: FC<UserListTableProps> = (props) => {
  const { paginationResult, handlePageChange, handleLimitChange, handleQueryChange, ...other } = props;
  const [currentTab, setCurrentTab] = useState<string>('all');
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [query, setQuery] = useState<string>('');
  const { t } = useTranslation();

  const tabs = [
    {
      label: t('ALL'),
      value: 'all'
    }
  ];

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setSelectedUsers([]);
    setCurrentTab(value);
  };

  return (
    <Card {...other}>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              setQuery(e.target.value);
              handleQueryChange(e);
            }}
            placeholder={t('SEARCH_USERS')}
            value={query}
            variant="outlined"
          />
        </Box>
      </Box>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('USER')}
                </TableCell>
                <TableCell>
                  {t('CITY')}
                </TableCell>
                <TableCell>
                  {t('PHONE_NUMBER')}
                </TableCell>
                <TableCell align="right">
                  {t('ACTIONS')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginationResult.items.map((user) => {
                const isUserSelected = selectedUsers.includes(user.id);

                return (
                  <TableRow
                    hover
                    key={user.id}
                    selected={isUserSelected}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          src={user.avatar}
                          sx={{
                            height: 42,
                            width: 42
                          }}
                        >
                          {getInitials(user.name)}
                        </Avatar>
                        <Box sx={{ ml: 1 }}>
                          {user.name} {user.surname}
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            {user.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {`${StringIsNotNullOrEmpty(user?.city) ? user.city : '-'}`}
                    </TableCell>
                    <TableCell>
                      {user.phone}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title={t('EDIT') || ''} aria-label={t('EDIT') || ''}>
                        <IconButton
                          component={RouterLink}
                          to={`edit?userId=${user.id}`}
                        >
                          <PencilAltIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('DETAILS') || ''} aria-label={t('DETAILS') || ''}>
                        <IconButton
                          component={RouterLink}
                          to={`detail?userId=${user.id}`}
                        >
                          <ArrowRightIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={paginationResult.totalCount}
        onRowsPerPageChange={(e) => {
          setLimit(parseInt(e.target.value, 10));
          handleLimitChange(e.target.value);
        }}
        onPageChange={(e, page) => {
          setPage(page);
          handlePageChange(e, page);
        }}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

export default UserListTable;
