import axios from 'axios';
import ServiceConfig from "../config/serviceConfig";
import { LocalizationAnchor } from '../types/level';
import { PaginationResult } from '../types/paginationResult';
import { Resource, ResourceDocument, ResourceSelectItem } from '../types/resource';
import { ResourceAccess, UserResourceAccess } from '../types/resourceAccess';
import { ResourceWithDetail } from '../types/resourceType';
import { ResponseResult } from '../types/responseResult';
import { Pattern } from "../types/reservation";

export class ResourceService {

    async findResource(id: string): Promise<Resource> {
        try {
            const response = await axios.get<Resource>(ServiceConfig.serviceBaseUrl + "/FindByResourceId", {
                params: {
                    id: id
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("findResource error");
            }
        }
        catch (error) {
            throw new Error("findResource error:" + error);
        }
    }

    async searchResources(tenantId: string, pageNumber: number, pageSize: number, text: string, resourceTypeCode: string): Promise<PaginationResult<Resource>> {
        try {
            const response = await axios.get<PaginationResult<Resource>>(ServiceConfig.serviceBaseUrl + "/SearchResources", {
                params: {
                    tenantId: tenantId,
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                    text: text,
                    resourceTypeCode: resourceTypeCode
                }
            });
            if (response.status === 200) {
                return response.data;

            }
            else {
                throw new Error("searchResources error");
            }
        }
        catch (error) {
            throw new Error("searchResources error:" + error);
        }
    }

    async upsertResource(resource: Resource): Promise<ResponseResult> {
        try {
            const response = await axios.post<ResponseResult>(ServiceConfig.serviceBaseUrl + "/UpsertResource", resource);
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("upsertResource error");
            }
        }
        catch (error) {
            throw new Error("upsertResource error:" + error);
        }
    }

    async getResourceSelectItems(tenantId: string, email: string): Promise<ResourceSelectItem[]> {
        try {
            const response = await axios.get<ResourceSelectItem[]>(ServiceConfig.serviceBaseUrl + "/GetResourceSelectItems", {
                params: {
                    tenantId: tenantId,
                    email: email
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("getResourceSelectItems error");
            }
        }
        catch (error) {
            throw new Error("getResourceSelectItems error:" + error);
        }
    }

    async getResourceSelectItemsForLevel(tenantId: string): Promise<ResourceSelectItem[]> {
        try {
            const response = await axios.get<ResourceSelectItem[]>(ServiceConfig.serviceBaseUrl + "/GetResourceSelectItemsForLevel", {
                params: {
                    tenantId: tenantId
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("getResourceSelectItems error");
            }
        }
        catch (error) {
            throw new Error("getResourceSelectItems error:" + error);
        }
    }

    async getResourceSelectItemsForReservationEvent(tenantId: string, dateTimeFrom: Date, dateTimeTo: Date): Promise<ResourceSelectItem[]> {
        try {
            var request = {
                tenantId: tenantId,
                dateTimeFrom: dateTimeFrom,
                dateTimeTo: dateTimeTo
            }

            const response = await axios.post<ResourceSelectItem[]>(ServiceConfig.serviceBaseUrl + "/GetResourceSelectItemsForReservationEvent", request);
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("getResourceSelectItems error");
            }
        }
        catch (error) {
            throw new Error("getResourceSelectItems error:" + error);
        }
    }

    async invalidateResource(id: string): Promise<boolean> {
        try {
            const response = await axios.get(ServiceConfig.serviceBaseUrl + "/InvalidateResource", {
                params: {
                    id: id
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("invalidateResource error");
            }
        }
        catch (error) {
            throw new Error("invalidateResource error:" + error);
        }
    }

    async upsertResourceAccess(resourceAccess: ResourceAccess): Promise<boolean> {
        try {
            const response = await axios.post<ResourceAccess>(ServiceConfig.serviceBaseUrl + "/UpsertResourceAccess", resourceAccess);
            if (response.status === 200) {
                return Boolean(response.data);
            }
            else {
                throw new Error("upsertResourceAccess error");
            }
        }
        catch (error) {
            throw new Error("upsertResourceAccess error:" + error);
        }
    }

    async upsertUserResourceAccess(resourceAccess: UserResourceAccess): Promise<boolean> {
        try {
            const response = await axios.post<ResourceAccess>(ServiceConfig.serviceBaseUrl + "/UpsertUserResourceAccess", resourceAccess);
            if (response.status === 200) {
                return Boolean(response.data);
            }
            else {
                throw new Error("UpsertUserResourceAccess error");
            }
        }
        catch (error) {
            throw new Error("UpsertUserResourceAccess error:" + error);
        }
    }

    async getResourceDocument(id: string, resourceId: string): Promise<ResourceDocument> {
        try {
            const response = await axios.get<ResourceDocument>(ServiceConfig.serviceBaseUrl + "/GetResourceDocument", {
                params: {
                    id: id,
                    resourceId: resourceId
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("getResourceDocument error");
            }
        }
        catch (error) {
            throw new Error("getResourceDocument error:" + error);
        }
    }

    async findAnchorByResourceId(resourceId: string): Promise<LocalizationAnchor> {
        try {
            const response = await axios.get<LocalizationAnchor>(ServiceConfig.serviceBaseUrl + "/FindAnchorByResourceId", {
                params: {
                    resourceId: resourceId
                }
            });
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw new Error("getResourceDocument error");
            }
        }
        catch (error) {
            throw new Error("getResourceDocument error:" + error);
        }
    }

    async getAvailableResourceSelectItems(tenantId: string, dateTimeFrom: Date, dateTimeTo: Date, typeCode: string, unitId: string, levelId: string, buildingId: string, pattern: Pattern | undefined): Promise<ResourceWithDetail[]> {
        try {
            var request = {
                tenantId: tenantId,
                dateTimeFrom: dateTimeFrom,
                dateTimeTo: dateTimeTo,
                typeCode: typeCode,
                unitId: unitId,
                levelId: levelId,
                buildingId: buildingId,
                pattern: pattern
            };

            const response = await axios.post<ResourceWithDetail[]>(ServiceConfig.serviceBaseUrl + "/GetAvailableResourceSelectItemsForReservationEvent", request);

            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("getResourceDocument error");
            }
        }
        catch (error) {
            throw new Error("getResourceDocument error:" + error);
        }
    }

    async getAutoCompleteEmails(tenantId: string, text: string): Promise<string[]> {
        try {
            let request = {
                tenantId: tenantId,
                text: text
            };

            const response = await axios.post<string[]>(ServiceConfig.serviceBaseUrl + "/AutoCompleteEmails", request);
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw new Error("getAutoCompleteEmails error");
            }
        }
        catch (error) {
            throw new Error("getAutoCompleteEmails error:" + error);
        }
    }

}