/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Divider, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface buildingDataDetailsProps {
    id?: string;
    name?: string;
    tenantId?: string;
    altName?: string;
    locality?: string;
    address?: string;
    province?: string;
    country?: string;
    postalCode?: string;
    postalCodeExt?: string;
}

const buildingDataDetails: FC<buildingDataDetailsProps> = (props) => {
    const {
        id,
        name,
        tenantId,
        altName,
        locality,
        address,
        province,
        country,
        postalCode,
        postalCodeExt,
        ...other
    } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();
    
    return (
        <Card {...other}>
            <CardHeader title={t('BUILDING_DETAILS')} />
            <Divider />
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('NAME')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {name}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('ALTERNATIVE_NAME')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {altName}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('ADDRESS')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {address}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('CITY')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {locality}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('STATE')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {province}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('COUNTRY')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {country}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('ZIP_CODE')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {postalCode}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
    );
};

buildingDataDetails.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string
};

export default buildingDataDetails;
