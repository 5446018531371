import Dexie from 'dexie';

class AppLocalDatabase extends Dexie {

    constructor() {
        super("MyAppDatabase");
    }

    initDb(tablesConfig: { [tableName: string]: string }): Promise<any> {
        return toPromise((resolve, reject) => {
            try {

                this.version(1).stores(tablesConfig);
                resolve();
            }
            catch (e) {
                reject(e);
            }
        });
    }

    upsert(collectionName: string, item: any): Promise<any> {
        return toPromise((resolve, reject) => this.table(collectionName).put(item).then(resolve).catch(reject));
    }

    upsertBulk(collectionName: string, items: any[]): Promise<any> {
        return toPromise((resolve, reject) => this.table(collectionName).bulkPut(items).then(resolve).catch(reject));
    }

    remove(collectionName: string, id: string): Promise<void> {
        return toPromise((resolve, reject) => this.table(collectionName).delete(id).then(resolve).catch(reject));
    }

    findOne<T>(collectionName: string, selector: any): Promise<T> {
        return toPromise<T>((resolve, reject) => this.table(collectionName).get(selector).then(resolve).catch(reject));
    }

    find<T>(collectionName: string, selector: { [key: string]: any; }): Promise<T[]> {
        return toPromise<T[]>((resolve, reject) => {
            try {
                var queryResult = this.table<T>(collectionName).where(selector);
                queryResult.toArray(resolve);
            }
            catch (e) {
                reject(e);
            }
        });
    }

    getAll<T>(collectionName: string): Promise<T[]> {
        return toPromise<T[]>((resolve, reject) => {
            try {
                this.table<T>(collectionName).toArray().then(resolve);
            }
            catch (e) {
                reject(e);
            }
        });
    }
}

function toPromise<T>(executor: (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void): Promise<any> {
    return (new Promise<T>((resolve: any, reject) => executor(resolve, reject)));
}

export default new AppLocalDatabase();