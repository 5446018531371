import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    Tab,
    Tabs,
    TextField
} from '@material-ui/core';
import { User } from '../../models/User';
import MultiSelect from '../MultiSelect';
import { UserService } from '../../services/UserService';
import { v4 as uuidv4 } from 'uuid';
import AvatarDropzone from '../AvatarDropzone';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import { useSnackbar, VariantType } from 'notistack';
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, deepClone } from '../../utils/common';
import { useTranslation } from 'react-i18next';
import { actionCreators as appInitAction } from '../../store/AppInit';
import InitService from '../../services/InitService';
import { User as UserClient } from 'oidc-client';
import Loader from '../Loader';
import UserAccessManagement from './UserAccessManagement';
import SmartLamp from '../../components/smartdesk/SmartLamp';
import DisplayMessage from '../../components/smartdesk/DisplayMessage';
import SmartLedStrip from '../../components/smartdesk/SmartLedStrip';
import HeightDesk from '../../components/smartdesk/HeightDesk';

interface UserAddFormProps {
  user: User;
  isAdd: boolean;
  onAddComplete?: () => void;
}

const UserAddForm: FC<UserAddFormProps> = (props) => {
  const { user, isAdd, onAddComplete } = props;
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userClient = useSelector<AppState, UserClient>(state => state.oidc.user);
  const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
  const userRoleSelectItems = [t('building_contributor'), t('planner_admin'), t('planner_contributor'), t('resource_contributor'), t('user_manager')];

  const [files, setFiles] = useState<any[]>([]);
  const [userForm, setUserForm] = useState<User>(user);
  const [confirmClicked, setConfirmiClicked] = useState<boolean>(false);
  const [selectedUserRolesSelectItem, setSelectedUserRolesSelectItem] = useState<string[]>();
  const [buttonSaveText, setButtonSaveText] = useState<string>();
  const [avatar, setAvatar] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('user');

  const handleDrop = (newFiles: any): void => {
    var reader = new FileReader();
    reader.readAsDataURL(newFiles[0]);
    reader.onload = () => {
      setAvatar(reader.result as string);
    };

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (): void => {
    setFiles([]);
    setAvatar(undefined);
  };

  const handleMultiSelectChange = (value: string[]): void => {
    setSelectedUserRolesSelectItem(value);
  };

  const handleChipDelete = (chip: string): void => {
    setSelectedUserRolesSelectItem(() => selectedUserRolesSelectItem?.filter((prevChip) => chip !== prevChip));
  };



  useEffect(() => {

    if (userForm.avatar) {
      setAvatar(userForm.avatar);
    } else {
      setAvatar(undefined);
    }

    if (isAdd) {
      setButtonSaveText(t('ADD_USER'));
    }
    else {
      setButtonSaveText(t('UPDATE_USER'));
      setSelectedUserRolesSelectItem(user.roles?.map(role => t(role)));
    }

  }, []);

  const tabs = userForm?.email === userInfo?.email
    ? [
      { label: t('USER'), value: 'user' },
      //{ label: t('ACCESSES'), value: 'accesses' },
      { label: t('SMART_DESK'), value: 'smartdesk' }
    ]
    : [
      { label: t('USER'), value: 'user' },
      //{ label: t('ACCESSES'), value: 'accesses' }
    ];

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  async function submitUserAsync(userForm: User, userIsValid: boolean) {
    try {
      if (userIsValid) {
        var msgUser = t('USER_UPDATED');
        var msgVariant: VariantType = 'success';
        var userService = new UserService();
        var returnToList = true;
        userForm.tenantId = userInfo?.tenantId ?? '';
        userForm.roles = selectedUserRolesSelectItem?.map(role => t(role)) ?? [];
        userForm.avatar = avatar ?? '';

        userForm.name = capitalizeFirstLetter(userForm.name);
        userForm.surname = capitalizeFirstLetter(userForm.surname);
        userForm.email = userForm.email.toLowerCase();

        if (isAdd) {
          msgUser = t('USER_CREATED');
          userForm.id = uuidv4();
          var checkAdd = await userService.canInsertUser(userForm.email, userForm.tenantId);
          if (checkAdd.result) {
            await userService.upsertUser(userForm);
          }
          else {
            msgVariant = 'error';
            msgUser = checkAdd.message;
            if (msgUser === "USER_EXIST") {
              msgUser = t('USER_EXIST');
              returnToList = false;
            }
          }
        }
        else {
          await userService.upsertUser(userForm);
        }
        if (userForm.id === userInfo?.id && (userForm.avatar !== user.avatar || userForm.roles !== user.roles)) {
          const userInfo = await InitService.initializeUserProfile(userClient);
          dispatch(appInitAction.setUserInfo(userInfo));
        }
        enqueueSnackbar(msgUser, {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: msgVariant
        });
        if (returnToList) {
          history('/users');
        }
      } else {
        console.log('error');
      }
    } catch {
      history('/500');
    }
  }

  const emailRegex = /\S+@\S+\.\S+/;

  const userIsValid = (userForm
    && userForm.name
    && userForm.surname
    && userForm.email
    && emailRegex.test(userForm.email)
    && selectedUserRolesSelectItem
    && selectedUserRolesSelectItem.length > 0) as boolean;

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Tabs
          indicatorColor="primary"
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {!isAdd && tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
      </Box>
      {currentTab === 'user' &&
        <Box sx={{ pt: 3 }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={6}
              xl={3}
              xs={12}
            >
              <Card>
                <CardHeader title={t('AVATAR')} />
                <Divider />
                <CardContent>
                  {avatar
                    ? <div style={{ textAlign: 'center' }}>
                      <img src={avatar} alt="avatar" style={{ padding: '20px', borderRadius: '50%', width: '250px', height: '250px' }}>
                      </img>
                      <Divider />
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 2
                      }}>
                        <Button
                          sx={{
                            backgroundColor: 'error.main',
                            color: 'error.contrastText',
                            '&:hover': {
                              backgroundColor: 'error.dark'
                            }
                          }}
                          variant="contained"
                          onClick={handleRemove}
                        >
                          {t('DELETE')}
                        </Button>
                      </Box>
                    </div>
                    : <Box sx={{ mt: 3 }}>
                      <AvatarDropzone
                        accept="image/*"
                        files={files}
                        maxFiles={1}
                        maxSize={5000000}
                        onDrop={handleDrop}
                        onRemove={handleRemove}
                      />
                    </Box>}
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xl={9}
              xs={12}
            >
              <Card>
                <CardHeader title={t('PROFILE')} />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={4}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(confirmClicked && !userForm.name)}
                        fullWidth
                        label={t('NAME')}
                        name="name"
                        required
                        onChange={(e) => {
                          user.name = e.target.value;
                          setUserForm(deepClone(user));
                        }}
                        value={user.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(confirmClicked && !userForm.surname)}
                        fullWidth
                        label={t('SURNAME')}
                        name="surname"
                        required
                        onChange={(e) => {
                          user.surname = e.target.value;
                          setUserForm(deepClone(user));
                        }}
                        value={user.surname}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(confirmClicked && !userForm.email && !emailRegex.test(userForm.email))}
                        fullWidth
                        label={t('EMAIL_ADDRESS')}
                        name="email"
                        helperText=" " //Do not remove
                        required
                        onChange={(e) => {
                          user.email = e.target.value;
                          setUserForm(deepClone(user));
                        }}
                        disabled={!isAdd}
                        value={user.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexWrap: 'wrap',
                          p: 1,
                          width: '100%',
                          border: confirmClicked && selectedUserRolesSelectItem && selectedUserRolesSelectItem.length < 1 ? '0.5px solid #f44336!important' : '0.5px solid #919eab!important',
                          borderRadius: '16px',
                          marginTop: '-20px'
                        }}
                      >
                        <MultiSelect
                          label={t('ROLES_REQUIRED')}
                          onChange={handleMultiSelectChange}
                          options={userRoleSelectItems || []}
                          value={selectedUserRolesSelectItem || []}
                        />
                        {selectedUserRolesSelectItem?.map((chip) => (
                          <Chip
                            key={chip}
                            label={chip}
                            onDelete={(): void => handleChipDelete(chip)}
                            sx={{ m: 1 }}
                            variant="outlined"
                          />
                        ))}
                      </Box>
                      <p className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required css-17qc97g-MuiFormHelperText-root">
                        {t('ROLES_DESCRIPTION')}
                        <br />
                        <li>{t('ROLE_DESCRIPTION_BUILDING_CONTRIBUTOR')}</li>
                        <li>{t('ROLE_DESCRIPTION_PLANNER_ADMIN')}</li>
                        <li>{t('ROLE_DESCRIPTION_PLANNER_CONTRIBUTOR')}</li>
                        <li>{t('ROLE_DESCRIPTION_RESOURCE_CONTRIBUTOR')}</li>
                        <li>{t('ROLE_DESCRIPTION_USER_MANAGER')}</li>
                      </p>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label={t('PHONE_NUMBER')}
                        name="phone"
                        onChange={(e) => {
                          user.phone = e.target.value;
                          setUserForm(deepClone(user));
                        }}
                        value={user.phone}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label={t('ADDRESS')}
                        name="address"
                        onChange={(e) => {
                          user.address = e.target.value;
                          setUserForm(deepClone(user));
                        }}
                        value={user.address}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label={t('CITY')}
                        name="city"
                        onChange={(e) => {
                          user.city = e.target.value;
                          setUserForm(deepClone(user));
                        }}
                        value={user.city}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label={t('STATE')}
                        name="state"
                        onChange={(e) => {
                          user.state = e.target.value;
                          setUserForm(deepClone(user));
                        }}
                        value={user.state}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label={t('COUNTRY')}
                        name="country"
                        onChange={(e) => {
                          user.country = e.target.value;
                          setUserForm(deepClone(user));
                        }}
                        value={user.country}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label={t('ZIP_CODE')}
                        name="zipCode"
                        onChange={(e) => {
                          user.zipCode = e.target.value;
                          setUserForm(deepClone(user));
                        }}
                        value={user.zipCode}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}>
                  <Button
                    color="primary"
                    disabled={!userIsValid}
                    type="submit"
                    variant="contained"
                    onClick={async () => {
                      setIsLoading(true);
                      setConfirmiClicked(true);
                      await submitUserAsync(userForm, userIsValid);
                      if (onAddComplete)
                        onAddComplete();
                      setIsLoading(false);
                    }}
                  >
                    {buttonSaveText}
                  </Button>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Loader isLoading={isLoading} />
        </Box>}
      {currentTab === 'accesses'
        && <UserAccessManagement user={user} />}
      {currentTab === 'smartdesk'
        && <Grid item
          xl={12}
          md={12}
          xs={12}
          sx={{ py: 2 }}>
          <Grid
            container
            spacing={2}
          >
            {/* <SmartLamp />
            <DisplayMessage /> */}
          </Grid>
          <Grid
            container
            spacing={2}
          >
            {/* <SmartLedStrip />
            <HeightDesk /> */}
          </Grid>
        </Grid>}
    </>)
};


export default UserAddForm;
