import { ResourceType } from '../../types/resourceType';
import type { FC } from 'react';
import { useState } from 'react';
import {
    Box,
    Card,
    Divider,
    IconButton,
    InputAdornment,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tabs,
    TextField,
    Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Scrollbar from '../Scrollbar';
import ArrowRightIcon from '../../icons/ArrowRight';
import PencilAltIcon from '../../icons/PencilAlt';
import SearchIcon from '../../icons/Search';
import { PaginationResult } from '../../types/paginationResult';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';

interface ResourceTypeListTableProps {
    paginationResult: PaginationResult<ResourceType>;
    handlePageChange(event: any, newPage: number): void;
    handleLimitChange(event: any): void;
    handleQueryChange(event: any): void;
}

const ResourceTypeListTable: FC<ResourceTypeListTableProps> = (props) => {
    const { paginationResult, handlePageChange, handleLimitChange, handleQueryChange, ...other } = props;
    const [selectedResourceTypes, setSelectedResourceTypes] = useState<string[]>([]);
    const [currentTab, setCurrentTab] = useState<string>('all');
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(5);
    const [query, setQuery] = useState<string>('');
    const { t } = useTranslation();

    const tabs = [
        {
            label: t('ALL'),
            value: 'all'
        }
    ];

    return (
        <Card {...other}>
            <Tabs
                indicatorColor="primary"
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
            >
                {tabs.map((tab) => (
                    <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                    />
                ))}
            </Tabs>
            <Divider />
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                    m: -1,
                    p: 2
                }}
            >
                <Box
                    sx={{
                        m: 1,
                        maxWidth: '100%',
                        width: 500
                    }}
                >
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => {
                            setQuery(e.target.value);
                            handleQueryChange(e);
                        }}
                        placeholder={t('SEARCH_RESOURCE_TYPES')}
                        value={query}
                        variant="outlined"
                    />
                </Box>
            </Box>
            <Scrollbar>
                <Box sx={{ minWidth: 700 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t('CODE')}
                                </TableCell>
                                <TableCell>
                                    {t('NAME')}
                                </TableCell>
                                <TableCell align="right">
                                    {t('ACTIONS')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginationResult.items.map((resourceType) => {
                                const isResourceTypeSelected = selectedResourceTypes.includes(resourceType.id);
                                return (<TableRow
                                    hover
                                    key={resourceType.id}
                                    selected={isResourceTypeSelected}
                                >
                                    <TableCell>
                                        <Box sx={{ ml: 1 }}>
                                            <Typography
                                                color="textSecondary"
                                                variant="body2"
                                            >
                                                {resourceType.code}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ ml: 1 }}>
                                            <Typography
                                                color="textSecondary"
                                                variant="body2"
                                            >
                                                {resourceType.name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Tooltip title={t('EDIT') || ''} aria-label={t('EDIT') || ''}>
                                            <IconButton
                                                component={RouterLink}
                                                to={`edit?id=${resourceType.id}`}
                                            >
                                                <PencilAltIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t('DETAILS') || ''} aria-label={t('DETAILS') || ''}>
                                            <IconButton
                                                component={RouterLink}
                                                to={`detail?id=${resourceType.id}`}
                                            >
                                                <ArrowRightIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Box>
            </Scrollbar>
            <TablePagination
                component="div"
                count={paginationResult.totalCount}
                onRowsPerPageChange={(e) => {
                    setLimit(parseInt(e.target.value, 10));
                    handleLimitChange(e.target.value);
                }}
                onPageChange={(e, page) => {
                    setPage(page);
                    handlePageChange(e, page);
                }}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Card>);
}

export default ResourceTypeListTable;
