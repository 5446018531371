import axios from 'axios';
import { User } from 'oidc-client';
import { UserInfo } from '../models/UserInfo';
import DB from './AppLocalDb';
import { TenantService } from './TenantService';
import ServiceConfig from '../config/serviceConfig';
import { UserService } from './UserService';
import Tables from './Tables';

const userInfoTbl = 'userInfo';

class InitService {
    tenantService: TenantService;
    userService: UserService;

    constructor() {
        this.tenantService = new TenantService();
        this.userService = new UserService();
    }

    async initializeLocalStorage(): Promise<boolean> {
        var tableConfig: { [tableName: string]: string } = {};
        for (let [, tableName] of Object.entries(Tables)) {
            tableConfig[tableName.name] = tableName.fields;
        }
        await DB.initDb(tableConfig);
        return true;
    }

    async initializeUserProfile(user: User): Promise<UserInfo> {
        var networkError = null;
        try {
            const result = await axios.get<UserInfo>(ServiceConfig.serviceBaseUrl + '/GetUserInfo');
            if (result.status === 200) {
                const userInfo = {
                    _id: user.profile['odev-username'],
                    userName: user.profile['odev-username'],
                    tenantId: result.data.tenantId,
                    tenantName: result.data.tenantName,
                    roles: result.data.roles,
                    name: result.data.name,
                    surname: result.data.surname,
                    email: result.data.email,
                    tenants: result.data.tenants,
                    id: result.data.id,
                    avatar: result.data.avatar,
                    apiKey: result.data.apiKey
                };
                await DB.upsert(userInfoTbl, userInfo);
                return userInfo;
            }
        }
        catch (error) {
            networkError = error
        }
        var cachedUserInfo = await DB.findOne<UserInfo>(userInfoTbl, { _id: user.profile['odev-username'] });
        if (!!cachedUserInfo) {
            return cachedUserInfo;
        }
        else {
            throw new Error("Could not find any cached userInfo. Network error before was" + networkError);
        }
    }
}

export default new InitService();