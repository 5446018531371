import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@material-ui/core';
import ChevronRightIcon from '../icons/ChevronRight';
import LevelAddForm from '../components/level/LevelAddForm';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

interface LevelAddProps {
    isAdd: boolean;
}

const LevelAdd: FC<LevelAddProps> = (props) => {

    const { isAdd } = props;
    const { t } = useTranslation();
    var currentTheme = useTheme();

    const [buildingId, setBuildingId] = useState<string>();

    useEffect(() => {
        let idParam = window.location.href.split('id=')[1].split('&')[0] ?? '';
        setBuildingId(idParam);
    }, []);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 2,
                    [currentTheme.breakpoints.up('lg')]: {
                        paddingLeft: '300px'
                    }
                }}
            >
                <Container>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {isAdd ? t('LEVEL_ADD') : t('LEVEL_EDIT')}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    {t('DASHBOARD')}
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to={`/buildings`}
                                    variant="subtitle2"
                                >
                                    {t('BUILDINGS')}
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to={`/levels?buildingId=${buildingId}`}
                                    variant="subtitle2"
                                >
                                    {t('LEVELS')}
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    {t('LEVEL')}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box mt={3}>
                        <LevelAddForm isAdd={isAdd} />
                    </Box>
                </Container>
            </Box>
        </>);
}

export default LevelAdd;