import { FC, useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, IconButton, TextField, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HeightIcon from '@material-ui/icons/Height';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import SmartLamp from "./SmartLamp";

interface SmartHeightDeskProps {
    height: number;
    setHeight(value: number): any;
}

const HeightDesk: FC<SmartHeightDeskProps> = (props) => {
    const { t } = useTranslation();
    const { height, setHeight } = props;

    const [currentHeight, setCurrentHeight] = useState<number>(height);

    function valuetext(value: number) {
        return `${value} mm`;
    }


    useEffect(() => {
        setCurrentHeight(height);
    }, [height])

    return (
        <Card>
            <CardHeader
                disableTypography
                title={(
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography
                            color="textPrimary"
                            variant="h4"
                        >
                            {t('DESK_HEIGHT')}
                        </Typography>
                    </Box>
                )}
            />
            <CardContent>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        py: 0
                    }}>

                    <IconButton sx={{ mr: 2 }}
                        onClick={() => {
                            setCurrentHeight(currentHeight - 20);
                        }}>
                        <VerticalAlignBottomIcon fontSize="large" />
                    </IconButton>
                    <TextField
                        type="number"
                        InputProps={{
                            inputProps: {
                                max: 1190, min: 710
                            }
                        }}
                        value={currentHeight}
                        onChange={(e) => {
                            console.log(e.target.value);
                            var s = e.target.value as unknown as number;
                            setCurrentHeight(s);
                        }}
                        helperText={"L'altezza della scrivania deve essere compre tra 710 e 1190 mm."}
                    />

                    {/* <Slider
                            value={height}
                            defaultValue={100}
                            getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="on"
                            step={1}
                            marks
                            min={70}
                            max={120}
                            onChange={(e, number) => {
                                setHeight(number as number);
                            }}
                        /> */}
                    <IconButton sx={{ ml: 2 }}
                        onClick={() => {
                            setCurrentHeight(currentHeight + 20);
                        }}>
                        <VerticalAlignTopIcon fontSize="large" />
                    </IconButton>
                    <Button
                        color="primary"
                        onClick={() => {
                            console.log(currentHeight);
                            setHeight(currentHeight);
                        }}
                        size="large"
                        type="button"
                        variant="text"
                        disabled={currentHeight > 1190 || currentHeight < 710}
                    >
                        {t('IMPOSTA')}
                    </Button>
                </Box>

            </CardContent>
        </Card>
    );
};

export default HeightDesk;
