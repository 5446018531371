import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from '@material-ui/core';
import { Level } from '../types/level';
import { Building } from '../types/building';
import ChevronRightIcon from '../icons/ChevronRight';
import PlusIcon from '../icons/Plus';
import LevelListTable from '../components/level/LevelListTable';
import { LevelService } from '../services/LevelService';
import { BuildingService } from '../services/BuildingService';
import { PaginationResult } from '../types/paginationResult';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTheme } from '@material-ui/core/styles';

const LevelList: FC = () => {

    const history = useNavigate();
    const { t } = useTranslation();
    var currentTheme = useTheme();

    const [levels, setLevels] = useState<PaginationResult<Level>>();
    const [building, setBuilding] = useState<Building>();
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

    let buildingId = window.location.href.split('buildingId=')[1] ?? '';

    const getLevels = useCallback(async () => {
        setIsPageLoading(true);
        try {
            if (buildingId) {
                var buildingService = new BuildingService();
                var levelService = new LevelService();

                var [buildingRes, levelsRes] = await Promise.all([
                    buildingService.findBuilding(buildingId),
                    levelService.searchLevels(buildingId, 0, 5, '')]);

                setBuilding(buildingRes);
                setLevels(levelsRes);
            }
        } catch (e) {
            console.error(e);
            history('/500');
        }
        setIsPageLoading(false);
    }, []);

    useEffect(() => {
        getLevels();
    }, [getLevels]);

    const handlePageChange = (event: any, newPage: number): void => {
        setIsPageLoading(true);
        (async () => {
            try {
                if (buildingId) {
                    var levelService = new LevelService();
                    var res = await levelService.searchLevels(buildingId, newPage, 5, '');
                    setLevels(res);
                }
            } catch (e) {
                console.error(e);
                history('/500');
            }
        })();
        setIsPageLoading(false);
    };

    const handleLimitChange = (limit: any): void => {
        setIsPageLoading(true);
        (async () => {
            try {
                if (buildingId) {
                    var levelService = new LevelService();
                    var res = await levelService.searchLevels(buildingId, 0, limit, '');
                    setLevels(res);
                }
            } catch (e) {
                console.error(e);
                history('/500');
            }
        })();
        setIsPageLoading(false);
    };

    const handleQueryChange = (event: any): void => {
        setIsPageLoading(true);
        (async () => {
            try {
                if (buildingId) {
                    var levelService = new LevelService();
                    var res = await levelService.searchLevels(buildingId, 0, 5, event.target.value);
                    setLevels(res);
                }
            } catch (e) {
                console.error(e);
                history('/500');
            }
        })();
        setIsPageLoading(false);
    };

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 2,
                [currentTheme.breakpoints.up('lg')]: {
                    paddingLeft: '300px'
                }
            }}
        >
            <Container>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('LEVEL_LIST_IN_BUILDING')} {building?.name}
                        </Typography>
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<ChevronRightIcon fontSize="small" />}
                            sx={{ mt: 1 }}
                        >
                            <Link
                                color="textPrimary"
                                component={RouterLink}
                                to="/"
                                variant="subtitle2"
                            >
                                {t('DASHBOARD')}
                            </Link>
                            <Link
                                color="textPrimary"
                                component={RouterLink}
                                to={`/buildings`}
                                variant="subtitle2"
                            >
                                {t('BUILDINGS')}
                            </Link>
                            <Typography
                                color="textSecondary"
                                variant="subtitle2"
                            >
                                {t('LEVELS')}
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <Box sx={{ m: -1 }}>
                            <Button
                                component={RouterLink}
                                to={`../level/add?id=${building?.id}`}
                                color="primary"
                                startIcon={<PlusIcon fontSize="small" />}
                                sx={{ m: 1 }}
                                variant="contained"
                            >
                                {t('ADD_LEVEL')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 3 }}>
                    {!isPageLoading
                        ? levels && <LevelListTable
                            building={building!}
                            paginationResult={levels}
                            handlePageChange={handlePageChange}
                            handleLimitChange={handleLimitChange}
                            handleQueryChange={handleQueryChange} />
                        : <>
                            <Skeleton variant='text' width='100%' height={180} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                            <Skeleton variant='text' width='100%' height={100} animation="wave" />
                        </>}
                </Box>
            </Container>
        </Box>)
};

export default LevelList;
