import type { FC } from 'react';
import { ResponseResult } from "../../types/responseResult";
import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

interface CanInsertResultErrorProps {
    canInsertResult?: ResponseResult;
    action(): void;
    onClose(): void;
}

const CanInsertResultError: FC<CanInsertResultErrorProps> = (props) => {
    const { canInsertResult, action, onClose } = props;
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return (canInsertResult ?
        <>
            {canInsertResult.message === 'MULTIPLE_MESSAGE' && <>
                <Box sx={{ p: 2 }}>
                    <Typography
                        align="center"
                        color="textPrimary"
                        gutterBottom
                        variant="h5"
                    >
                        {'Non è possibile prenotare la risorsa per le seguenti date:'}
                    </Typography>
                    {canInsertResult.details?.map(detail => {
                        return (
                            <Typography
                                align="center"
                                color="textPrimary"
                                gutterBottom
                                variant="h6"
                            >
                                {detail.date ? format(new Date(detail.date), t('DATE_PICKER_FULL_DATE')) : ''}
                            </Typography>)
                    })}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                        <Button
                            color="primary"
                            variant="text"
                            onClick={onClose}
                        >
                            {t('CANCEL')}
                        </Button>

                        <Button
                            color="primary"
                            sx={{ ml: 1 }}
                            type="submit"
                            variant="contained"
                            onClick={() => {
                                action.call(null);
                                enqueueSnackbar(t('RERSERVATION_CREATED'), {
                                    anchorOrigin: {
                                        horizontal: 'right',
                                        vertical: 'top'
                                    },
                                    variant: 'success'
                                });
                                onClose.call(null);
                            }}
                        >
                            {t('CONTINUA')}
                        </Button>
                    </Box>
                </Box>
            </>}
            {canInsertResult.message === 'UNIT_FILL' && <>
                <Box sx={{ p: 2 }}>
                    <Typography
                        align="center"
                        color="textPrimary"
                        gutterBottom
                        variant="h5"
                    >
                        {'Non è possibile prenotare la risorsa perché è stata raggiunta la capienza massima:'}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                        color="primary"
                        sx={{ ml: 1 }}
                        type="submit"
                        variant="contained"
                        onClick={onClose}
                    >
                        {t('CANCEL')}
                    </Button>
                </Box>
            </>}
            {canInsertResult.message === 'RESOURCE_AlREADY_RESERVED' && <>
                <Box sx={{ p: 2 }}>
                    <Typography
                        align="center"
                        color="textPrimary"
                        gutterBottom
                        variant="h5"
                    >
                        {'Non è possibile prenotare la risorsa perché risultà già riservata:'}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                        color="primary"
                        sx={{ ml: 1 }}
                        type="submit"
                        variant="contained"
                        onClick={onClose}
                    >
                        {t('CANCEL')}
                    </Button>
                </Box>
            </>
            }
        </>
        : <></>);
}

export default CanInsertResultError;
