import type { FC } from 'react';
import { useEffect, useState } from 'react';
import MobileDateTimePicker from '@material-ui/lab/MobileDateTimePicker';
import {
    Avatar,
    Box,
    Card,
    Dialog,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import { ReservationService } from '../../services/ReservationService';
import { deepClone } from '../../utils/common';
import { useTranslation } from 'react-i18next';
import { BuildingDetail, BuildingWithDetail, LevelDetail, UnitDetail } from '../../types/building';
import { ResourceTypeService } from '../../services/ResourceTypeService';
import { ResourceTypeSelectItem, ResourceWithDetail } from '../../types/resourceType';
import getInitials from '../../utils/getInitials';
import { ResourceService } from '../../services/ResourceService';
import Scrollbar from '../Scrollbar';
import CalendarEventForm from './CalendarEventForm';
import { addMinutes } from 'date-fns';
import ArrowRightIcon from '../../icons/ArrowRight';
import XIcon from '../../icons/X';

interface AddCalendarEventFormProps {
    onAddComplete?: () => void;
    onCancel?: () => void;
    range?: { start: Date; end: Date };
}


const AddCalendarEvent: FC<AddCalendarEventFormProps> = (props) => {
    const { onAddComplete, onCancel, range } = props;

    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const { t } = useTranslation();

    const [resourceTypes, setResourceTypes] = useState<ResourceTypeSelectItem[]>();
    const [selectedResourceType, setSelectedResourceType] = useState<ResourceTypeSelectItem>();
    const [buildingWithDetails, setBuildingWithDetails] = useState<BuildingWithDetail>();
    const [selectedBuilding, setSelectedBuilding] = useState<BuildingDetail>();
    const [selectedLevel, setSelectedLevel] = useState<LevelDetail>();
    const [selectedUnit, setSelectedUnit] = useState<UnitDetail>();
    const [dateTimeFrom, setDateTimeFrom] = useState<Date>(range?.start || new Date());
    const [dateTimeTo, setDateTimeTo] = useState<Date>(range?.end || new Date());
    const [resourceWithDetails, setResourceWithDetails] = useState<ResourceWithDetail[]>([]);
    const [selectedResourceWithDetail, setSelectedResourceWithDetail] = useState<ResourceWithDetail>();
    const [isCalendarEventFormOpen, setIsCalendarEventFormOpen] = useState<boolean>(false);

    const reservationService = new ReservationService();
    const resourceTypeService = new ResourceTypeService();
    const resourceService = new ResourceService();

    useEffect(() => {
        try {
            (async () => {
                if (userInfo) {
                    let buildings = await reservationService.getBuildingsWithDetails(userInfo.tenantId);
                    let resourceTypesRes = await resourceTypeService.getResourceTypeSelectItems(userInfo.tenantId);
                    setResourceTypes(resourceTypesRes);
                    setBuildingWithDetails(buildings);
                }
            })();
        } catch {

        }
    }, [])

    useEffect(() => {
        try {

            (async () => {
                if (userInfo) {
                    let res = await resourceService.getAvailableResourceSelectItems(
                        userInfo.tenantId,
                        dateTimeFrom || new Date(),
                        dateTimeTo || new Date(),
                        selectedResourceType?.code || '',
                        selectedUnit?.id || '',
                        selectedLevel?.id || '',
                        selectedBuilding?.id || '',
                        undefined);

                    setResourceWithDetails(res);
                }
            })();

        } catch {

        }
    }, [dateTimeFrom, dateTimeTo, selectedResourceType, selectedUnit, selectedLevel, selectedBuilding])

    return (
        <>
            {!isCalendarEventFormOpen && <Card>
                <Box sx={{ pb: 2 }}>
                    <Box
                        sx={{
                            display: 'inline',
                            m: 2,
                            pr: 3
                        }}
                    >
                        <Typography
                            align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="h5"
                        >
                            {t('ADD_RESERVATION')} {'(1/2)'}

                            <IconButton
                                sx={{
                                    float: 'right',
                                    mr: 3
                                }}
                                onClick={() => {
                                    if (onCancel)
                                        onCancel();
                                }}>
                                <XIcon fontSize="small" />
                            </IconButton>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            alignSelf: 'center',
                            display: 'flex',
                            flexWrap: 'wrap',
                            m: 2,
                            p: 2,
                            pt: 0
                        }}
                    >
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'inline',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Typography
                                align="left"
                                color="textPrimary"
                                gutterBottom
                                variant="h6"
                                marginLeft="2px"
                            >
                                {t('WHEN')}
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                                <MobileDateTimePicker
                                    cancelText={t('CANCEL')}
                                    minDateTime={addMinutes(new Date(), -1)}
                                    inputFormat={t('DATE_PICKER_FULL_DATE')}
                                    ampm={false}
                                    ampmInClock={false}
                                    label={t('START_DATE')}
                                    onChange={(date) => {
                                        setDateTimeFrom(date ?? new Date());
                                    }}
                                    renderInput={(inputProps) => (
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            {...inputProps}
                                        />
                                    )}
                                    value={dateTimeFrom}
                                />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <MobileDateTimePicker
                                    cancelText={t('CANCEL')}
                                    minDateTime={new Date()}
                                    inputFormat={t('DATE_PICKER_FULL_DATE')}
                                    ampm={false}
                                    ampmInClock={false}
                                    label={t('END_DATE')}
                                    onChange={(date) => {
                                        setDateTimeTo(date ?? new Date());
                                    }}
                                    renderInput={(inputProps) => (
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            {...inputProps}
                                        />
                                    )}
                                    value={dateTimeTo}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'inline',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Typography
                                align="left"
                                color="textPrimary"
                                gutterBottom
                                variant="h6"
                                marginLeft="8px"
                            >
                                {t('WHAT')}
                                <Box
                                    sx={{
                                        mt: 2,
                                        width: 220
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        label="Tipologia di risorsa"
                                        name="tipologia"
                                        onChange={(e) => {
                                            let res = resourceTypes?.filter(res => res.description === e.target.value)[0];
                                            setSelectedResourceType(deepClone(res || { id: '', description: '' }));
                                        }}
                                        select
                                        SelectProps={{ native: true }}
                                        value={selectedResourceType?.description}
                                        variant="outlined"
                                    >
                                        <option
                                            key={''}
                                            value={''}
                                        >
                                            {''}
                                        </option>
                                        {resourceTypes?.map((resourceType) => (
                                            <option
                                                key={resourceType.id}
                                                value={resourceType.description}
                                            >
                                                {resourceType.description}
                                            </option>
                                        ))}
                                    </TextField>
                                </Box>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'inline',
                                flexWrap: 'wrap',
                                ml: 1
                            }}
                        >
                            <Typography
                                align="left"
                                color="textPrimary"
                                gutterBottom
                                variant="h6"
                                marginLeft="8px"
                            >
                                {t('WHERE')}
                            </Typography>
                            <Box
                                sx={{
                                    mt: 2,
                                    maxWidth: '100%',
                                    width: 220
                                }}
                            >
                                <TextField
                                    fullWidth
                                    label="Edificio"
                                    name="edificio"
                                    onChange={(e) => {
                                        let selBuilding = buildingWithDetails?.buildings.filter(building => building.name === e.target.value)[0];
                                        setSelectedBuilding(deepClone(selBuilding || { id: '', name: '' }));
                                    }}
                                    select
                                    SelectProps={{ native: true }}
                                    value={selectedBuilding?.name}
                                    variant="outlined"
                                >
                                    <option
                                        key={''}
                                        value={''}
                                    >
                                        {''}
                                    </option>
                                    {buildingWithDetails?.buildings.map((building) => (
                                        <option
                                            key={building.id}
                                            value={building.name}
                                        >
                                            {building.name}
                                        </option>
                                    ))}
                                </TextField>
                            </Box>
                            <Box
                                sx={{
                                    mt: 2,
                                    maxWidth: '100%',
                                    width: 220
                                }}
                            >
                                <TextField
                                    fullWidth
                                    label="Livello"
                                    name="livello"
                                    onChange={(e) => {
                                        let selLevel = buildingWithDetails?.levels.filter(level => level.name === e.target.value)[0];
                                        setSelectedLevel(deepClone(selLevel || { id: '', name: '' }));
                                    }}
                                    select
                                    SelectProps={{ native: true }}
                                    value={selectedLevel?.name}
                                    variant="outlined"
                                >
                                    <option
                                        key={''}
                                        value={''}
                                    >
                                        {''}
                                    </option>
                                    {buildingWithDetails?.levels.filter(l => l.buildingId === selectedBuilding?.id).map((level) => (
                                        <option
                                            key={level.id}
                                            value={level.name}
                                        >
                                            {level.name}
                                        </option>
                                    ))}
                                </TextField>
                            </Box>
                            <Box
                                sx={{
                                    mt: 2,
                                    maxWidth: '100%',
                                    width: 220
                                }}
                            >
                                <TextField
                                    fullWidth
                                    label={t('UNIT')}
                                    name="area"
                                    onChange={(e) => {
                                        let selUnit = buildingWithDetails?.units.filter(unit => unit.name === e.target.value)[0];
                                        setSelectedUnit(deepClone(selUnit || { id: '', name: '' }));
                                    }}
                                    select
                                    SelectProps={{ native: true }}
                                    value={selectedUnit?.name}
                                    variant="outlined"
                                >
                                    <option
                                        key={''}
                                        value={''}
                                    >
                                        {''}
                                    </option>
                                    {buildingWithDetails?.units.filter(l => l.levelId === selectedLevel?.id).map((unit) => (
                                        <option
                                            key={unit.id}
                                            value={unit.name}
                                        >
                                            {unit.name}
                                        </option>
                                    ))}
                                </TextField>
                            </Box>
                        </Box>
                    </Box>
                    <Scrollbar>
                        <Box sx={{
                            minWidth: 700,
                            maxHeight: '300px',
                            mx: 2,
                            px: 2
                        }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {t('RESOURCE')}
                                        </TableCell>
                                        <TableCell>
                                            {t('NAME')}
                                        </TableCell>
                                        <TableCell>
                                            {'Tipo risorsa'}
                                        </TableCell>
                                        <TableCell>
                                            {t('BUILDING')}
                                        </TableCell>
                                        <TableCell>
                                            {t('LEVEL')}
                                        </TableCell>
                                        <TableCell>
                                            {t('UNIT')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {t('ACTIONS')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resourceWithDetails.map(resource =>
                                        <TableRow
                                            className="App-clickableElement"
                                            hover
                                            key={resource.id}
                                            onClick={() => {
                                                setSelectedResourceWithDetail(resource);
                                                setIsCalendarEventFormOpen(true);
                                            }}
                                        >
                                            <TableCell>
                                                <Box
                                                    sx={{
                                                        alignItems: 'center',
                                                        display: 'flex'
                                                    }}
                                                >
                                                    <Avatar
                                                        src={undefined}
                                                        sx={{
                                                            height: 42,
                                                            width: 42
                                                        }}
                                                    >
                                                        {getInitials(resource.name)}
                                                    </Avatar>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body2"
                                                        >
                                                            {resource.name}
                                                        </Typography>
                                                        {/* <Link
                                                            color="textPrimary"
                                                            component={RouterLink}
                                                            to={`../resources/detail?id=${resource.id}`}
                                                            variant="subtitle2"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            onClick={(e: any) => e.stopPropagation()}
                                                        >
                                                            {resource.name}
                                                        </Link> */}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {resource.resourceTypeName}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {resource.building}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {resource.level}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {resource.unit}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton>
                                                    <ArrowRightIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>)}
                                </TableBody>
                            </Table>
                        </Box>
                    </Scrollbar>
                </Box>
            </Card>}
            {isCalendarEventFormOpen &&
                <Dialog
                    fullWidth
                    maxWidth="xl"
                    onClose={() => {
                        setIsCalendarEventFormOpen(false);
                        if (onCancel)
                            onCancel();
                    }}
                    open={isCalendarEventFormOpen}
                >
                    <CalendarEventForm
                        eventId={undefined}
                        isReadOnly={false}
                        onAddComplete={onAddComplete}
                        onCancel={() => setIsCalendarEventFormOpen(false)}
                        range={{
                            start: dateTimeFrom || new Date(),
                            end: dateTimeTo || new Date()
                        }}
                        selectedResource={{
                            id: selectedResourceWithDetail?.id || '',
                            name: selectedResourceWithDetail?.name || '',
                            errorCode: '',
                            unitId: selectedResourceWithDetail?.unitId || '',
                            typeCode: '',
                            effectiveCapacity: selectedResourceWithDetail?.effectiveCapacity || 0
                        }} />
                </Dialog>
            }
        </>);
}

export default AddCalendarEvent;