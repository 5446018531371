import type { FC } from 'react';
import { useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Dialog, Divider, Typography } from '@material-ui/core';
import TrashIcon from '../../icons/Trash';
import { ResourceService } from '../../services/ResourceService';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';

interface ResourceProps {
    id: string;
}

const ResourceAccessManagement: FC<ResourceProps> = (props) => {
    const { id } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useNavigate();

    const resourceService = new ResourceService();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function submitResourceInvalidateAsync(id: string) {
        try {
            await resourceService.invalidateResource(id);
            enqueueSnackbar(t('RESOURCE_DELETED'), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
            history('/resources');
        } catch {
            history('/500');
        }
    }

    return (
        <>
            <Card {...props}>
                <CardHeader title={t('DATA_MANAGEMENT')}/>
                <Divider/>
                <CardContent>
                    <Box
                        sx={{
                            mb: 2,
                            mt: 1
                        }}
                    >
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                            {t('DELETE_RESOURCE_DESCRIPTION')}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 2
                    }}>
                        <Button
                            startIcon={<TrashIcon fontSize="small"/>}
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                            sx={{
                                backgroundColor: 'error.main',
                                color: 'error.contrastText',
                                '&:hover': {
                                    backgroundColor: 'error.dark'
                                }
                            }}
                            variant="contained"
                        >
                            {t('DELETE')}
                        </Button>
                    </Box>
                </CardContent>
                <Loader isLoading={isLoading}/>
            </Card>
            <Dialog
                fullWidth
                maxWidth="sm"
                onClose={() => setIsModalOpen(false)}
                open={isModalOpen}
            >
                <Box sx={{ flexGrow: 1 }}/>
                <div style={{ padding: '30px', textAlign: 'center' }}>
                    {`${t('DO_YOU_WANT_DELETE')}`}
                </div>
                <Button
                    color="primary"
                    onClick={async () => {
                        setIsModalOpen(false);
                        setIsLoading(true);
                        await submitResourceInvalidateAsync(id);
                        setIsLoading(false);
                    }}
                    variant="text"
                >
                    {t('YES')}
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        setIsModalOpen(false)
                    }}
                    variant="text"
                >
                    {t('CANCEL')}
                </Button>
            </Dialog>
        </>);
};

export default ResourceAccessManagement;
