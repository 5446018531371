import { createUserManager } from 'redux-oidc';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import ServiceConfig from './config/serviceConfig'

const userManagerConfig: UserManagerSettings = {

  client_id: ServiceConfig.clientId,
  response_type: 'token id_token',
  scope: "openid profile WebAPI email",

  authority: ServiceConfig.authUrl,
  redirect_uri: ServiceConfig.appHostName + '/#/callback',
  silent_redirect_uri: ServiceConfig.appHostName + '/silentRenew.html',

  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
  userStore: new WebStorageStateStore({ store: localStorage})
};

const userManager = createUserManager(userManagerConfig);

export default userManager;