import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Avatar, Box, Card, Divider, Grid, Typography } from '@material-ui/core';
import { DashboardService } from '../../services/DashboardService';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import ChevronDownIcon from '../../icons/ChevronDown';
import ChevronUpIcon from '../../icons/ChevronUp';
import Chart from 'react-apexcharts';
import { alpha, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

export interface ReservationDifference {
    reservationsEventsCount: number;
    increase: number;
}

const ReservationDifferenceByMonthChart: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const [reservationDifference, setReservationDifference] = useState<ReservationDifference>();

    useEffect(() => {
        (async () => {
            if (userInfo) {
                var dashboardSerivice = new DashboardService();
                var res = await dashboardSerivice.getReservationDifferenceByMonth(userInfo.tenantId, userInfo.roles);
                setReservationDifference(res);
            }

        })();
    }, [])

    const chart = {
        options: {
            chart: {
                background: 'transparent',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            colors: ['#7783DB'],
            dataLabels: {
                enabled: false
            },
            grid: {
                show: false
            },
            stroke: {
                width: 3
            },
            theme: {
                mode: theme.palette.mode
            },
            tooltip: {
                enabled: false
            },
            xaxis: {
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                }
            },
            yaxis: {
                show: false
            }
        },
        series: [
            {
                data: [0, 60, 30, 60, 0, 30, 10, 30, 0]
            }
        ]
    };

    return (
        <Grid
            item
            md={4}
            sm={6}
            xs={12}
        >
            <Card>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 3
                    }}
                >
                    <div>
                        <Typography
                            color="textPrimary"
                            variant="subtitle2"
                        >
                            {t('RESERVATIONS_IN_THIS_MONTH')}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            sx={{ mt: 1 }}
                            variant="h5"
                        >
                            {reservationDifference?.reservationsEventsCount}
                        </Typography>
                    </div>
                    <Chart
                        type="line"
                        width={120}
                        {...chart}
                    />
                </Box>
                <Divider />
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        px: 3,
                        py: 2
                    }}
                >
                    <Avatar
                        sx={{
                            backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
                            color: 'success.main',
                            height: 36,
                            width: 36
                        }}
                    >
                        {reservationDifference && reservationDifference.increase > 0 && <ChevronUpIcon fontSize="small" />}
                        {reservationDifference && reservationDifference.increase < 0 && <ChevronDownIcon fontSize="small" />}
                    </Avatar>
                    <Typography
                        color="textSecondary"
                        sx={{ ml: 1 }}
                        variant="caption"
                    >
                        {reservationDifference?.increase === 0 && t('NO_DATA_IN_THE_PREVIOUS_MONTH')}
                        {reservationDifference && reservationDifference.increase > 0 && `${reservationDifference.increase.toFixed(1)}% ${t('MORE_THEN_LAST_MONTH')}`}
                        {reservationDifference && reservationDifference.increase < 0 && `${reservationDifference.increase.toFixed(1)}% ${t('LESS_THEN_LAST_MONTH')}`}
                    </Typography>
                </Box>
            </Card>
        </Grid>
    );
};

export default ReservationDifferenceByMonthChart;