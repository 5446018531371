import { FC, useState } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@material-ui/core';
import ChevronRightIcon from '../icons/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import SmartLedStrip from '../components/smartdesk/SmartLedStrip';
import HeightDesk from '../components/smartdesk/HeightDesk';
import SmartLamp from '../components/smartdesk/SmartLamp';
import { SmartDeskCommand } from '../types/smartDeskStatus';
import { deepClone } from '../utils/common';
import useInterval from '../utils/useInterval';
import { UserService } from '../services/UserService';
import { useSelector } from "react-redux";
import { AppState } from "../store";
import { UserInfo } from "../models/UserInfo";
import Loader from '../components/Loader';

const SmartDesk: FC = () => {

    const { t } = useTranslation();
    const currentTheme = useTheme();

    const userService = new UserService();

    const [isFirstStatusReceived, setisFirstStatusReceived] = useState<boolean>(false);

    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);

    const [status, setStatus] = useState<SmartDeskCommand | null>(
        null
    );


    //questo si potrebbe fare quando c'è una sessione attiva
    const checkStatus = (async () => {
        // ricevo dal service dell' utente il suo status della scrivania
        let st = await userService.getSmartDeskStatus(userInfo?.tenantId || '');
        if (st) {
            setisFirstStatusReceived(true);
            console.log("status received", st);
            setStatus(deepClone(st));
        }
        else {
            if (!isFirstStatusReceived) {
                setisFirstStatusReceived(true);
                setStatus(
                    {
                        deskEngine: { height: 720 },
                        ledStrip: { onOff: false, brightness: 0, effect: 0, rgbColor: "#0fffff" },
                        smartLamp: { onOff: false, brightness: 0, white: 0 },
                        smartSocket: { onOff: false },
                        data: {
                            smartLampEnergy: 0,
                            stripLedEnergy: 0,
                            smartSocketEnergy: 0
                        }
                    }
                );
            }
        }
    });

    useInterval(checkStatus, 3000);

    // useEffect(() => {
    //     (async () => {
    //         let st = await userService.getSmartDesk();
    //         console.log("status", st);
    //         setStatus(st);
    //     })();
    // }, []);

    const sendCommand = (async (value: SmartDeskCommand) => {
        // invio al service dell' utente il suo status della scrivania
        await userService.setSmartDesk(value);
        setStatus(deepClone(value));
    });

    const ledSwitched = (async (value: boolean) => {
        if (status) {
            status.ledStrip.onOff = value;
            await sendCommand(status);
        }
    });
    const smartLampSwitched = (async (value: boolean) => {
        if (status) {
            status.smartLamp.onOff = value;
            await sendCommand(status);
        }
    });
    const setHeight = (async (value: number) => {
        if (status) {
            status.deskEngine.height = value;
            await sendCommand(status);
        }
    });
    const ledBrightness = (async (value: number) => {
        if (status) {
            status.ledStrip.brightness = value;
            await sendCommand(status);
        }
    });
    const smartLampBrightness = (async (value: number) => {
        if (status) {
            status.smartLamp.brightness = value;
            await sendCommand(status);
        }
    });
    const smartLampWhite = (async (value: number) => {
        if (status) {
            status.smartLamp.white = value;
            await sendCommand(status);
        }
    });
    const ledColor = (async (value: string) => {
        if (status) {
            status.ledStrip.rgbColor = value;
            await sendCommand(status);
        }
    });

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                p: 2,
                [currentTheme.breakpoints.up('lg')]: {
                    paddingLeft: '300px'
                }
            }}
        >
            <Container>
                <Grid item>
                    <Typography
                        color="textPrimary"
                        variant="h5"
                    >
                        {t('MY_IGLOO')}
                    </Typography>
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={<ChevronRightIcon fontSize="small" />}
                        sx={{ mt: 1 }}
                    >
                        <Link
                            color="textPrimary"
                            component={RouterLink}
                            to="/"
                            variant="subtitle2"
                        >
                            {t('DASHBOARD')}
                        </Link>
                        <Link
                            color="textPrimary"
                            component={RouterLink}
                            to="/"
                            variant="subtitle2"
                        >
                            {t('SMART_DESK')}
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item
                    xl={12}
                    md={12}
                    xs={12}
                    sx={{ py: 2 }}>
                    {status ? <Grid
                        container
                        spacing={2}
                    >
                        <SmartLedStrip
                            isSwitched={status.ledStrip.onOff}
                            brightness={status.ledStrip.brightness}
                            color={status.ledStrip.rgbColor}
                            setIsSwitched={ledSwitched}
                            setBrightness={ledBrightness}
                            setColor={ledColor}
                        />
                        <Grid
                            sx={{ py: 2 }}
                            item
                            md={6}
                            sm={6}
                            xs={12}
                        >

                            <HeightDesk height={status.deskEngine.height} setHeight={setHeight} />
                            <SmartLamp
                                isSwitched={status.smartLamp.onOff}
                                white={status.smartLamp.white}
                                brightness={status.smartLamp.brightness}
                                setIsSwitched={smartLampSwitched}
                                setBrightness={smartLampBrightness}
                                setWhite={smartLampWhite}
                            />
                        </Grid>
                    </Grid>
                        : <Loader isLoading={true} />}
                    <Grid
                        container
                        spacing={2}
                    >
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default SmartDesk;
