import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Building } from '../types/building';
import { Box, Breadcrumbs, Button, Container, Divider, Grid, Link, Tab, Tabs, Typography } from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import BuildingDataDetails from '../components/building/BuildingDataDetails';
import ChevronRightIcon from '../icons/ChevronRight';
import PencilAltIcon from '../icons/PencilAlt';
import BuildingDataManagement from '../components/building/BuildingDataManagement';
import { BuildingService } from '../services/BuildingService';
import LayersIcon from '@material-ui/icons/Layers';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

const BuildingDetails: FC = () => {
    const [building, setBuilding] = useState<Building | null>(null);
    const [currentTab, setCurrentTab] = useState<string>('details');
    const [id, setId] = useState<string>('');
    const history = useNavigate();
    const { t } = useTranslation();
    var currentTheme = useTheme();

    const tabs = [
        { label: t('DETAILS'), value: 'details' }
    ];

    const getCustomer = useCallback(async () => {
        try {
            let idParam = window.location.href.split('id=')[1] ?? '';
            setId(idParam!);
            var buildingService = new BuildingService();
            var res = await buildingService.findBuilding(idParam);
            setBuilding(res);
        } catch (err) {
            console.error(err);
            history('/500');
        }
    }, []);

    useEffect(() => {
        getCustomer();
    }, [getCustomer]);

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    if (!building) {
        return null;
    }

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 2,
                    [currentTheme.breakpoints.up('lg')]: {
                        paddingLeft: '300px'
                    }
                }}
            >
                <Container>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {building.name}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    {t('DASHBOARD')}
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/buildings"
                                    variant="subtitle2"
                                >
                                    {t('BUILDINGS')}
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    {t('DETAILS')}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Divider />
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<PencilAltIcon fontSize="small" />}
                                    sx={{ m: 1 }}
                                    to={`/buildings/edit?id=${id}`}
                                    variant="contained"
                                >
                                    {t('EDIT')}
                                </Button>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<LayersIcon fontSize="small" />}
                                    sx={{ m: 1 }}
                                    to={`/levels?buildingId=${id}`}
                                    variant="contained"
                                >
                                    {t('LEVELS')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Tabs
                            indicatorColor="primary"
                            onChange={handleTabsChange}
                            scrollButtons="auto"
                            textColor="primary"
                            value={currentTab}
                            variant="scrollable"
                        >
                            {tabs.map((tab) => (
                                <Tab
                                    key={tab.value}
                                    label={tab.label}
                                    value={tab.value}
                                />
                            ))}
                        </Tabs>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 3 }}>
                        {currentTab === 'details' && (
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    lg={6}
                                    md={6}
                                    xl={6}
                                    xs={12}
                                >
                                    <BuildingDataDetails
                                        id={building.id}
                                        name={building.name}
                                        tenantId={building.tenantId}
                                        altName={building.altName}
                                        address={building.address}
                                        locality={building.locality}
                                        province={building.province}
                                        country={building.country}
                                        postalCode={building.postalCode}
                                        postalCodeExt={building.postalCodeExt}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    md={6}
                                    xl={6}
                                    xs={12}
                                >
                                    <BuildingDataManagement id={building.id} />
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default BuildingDetails;
