import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    Divider,
    FormControlLabel,
    Grid,
    Slider,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { deepClone } from '../../utils/common';
import { Anchor, Coordinates, Corners, Level, Planimetry, Unit } from '../../types/level';
import FileDropzone from '../FileDropzone';
import { FeatureGroup, ImageOverlay, Map, Marker, Polygon, Popup } from 'react-leaflet';
import L, { CRS } from 'leaflet';
import DeleteIcon from '@material-ui/icons/Delete';
import PlusIcon from '../../icons/Plus';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import { ResourceService } from '../../services/ResourceService';
import { ResourceSelectItem } from '../../types/resource';
import { BuildingService } from '../../services/BuildingService';
import { useNavigate } from "react-router-dom";
import { Building } from '../../types/building';
import { LevelService } from '../../services/LevelService';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import markerBlue from '../../icons/pureSvg/blue-marker.svg';
import markerGreen from '../../icons/pureSvg/green-marker.svg';
import markerYellow from '../../icons/pureSvg/yellow-marker.svg';
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { forEach } from 'lodash';
import { alpha } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import InformationCircleIcon from '../../icons/InformationCircle';


interface LevelAddFormProps {
    isAdd: boolean;
}

const LevelAddForm: FC<LevelAddFormProps> = (props) => {

    const { isAdd } = props;
    const { t } = useTranslation();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const history = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [level, setLevel] = useState<Level>();
    const [anchors, setAnchors] = useState<Anchor[]>([]);
    const [units, setUnits] = useState<Unit[]>([]);
    const [currentUnit, setCurrentUnit] = useState<Unit>();
    const [selectedResourceSelectItem, setSelectedResourceSelectItem] = useState<ResourceSelectItem>();
    const [resourceSelectItems, setResourceSelectItems] = useState<ResourceSelectItem[]>([]);
    const [fullResourceSelectItems, setFullResourceSelectItems] = useState<ResourceSelectItem[]>([]);
    const [backgroundMediaAsBase64, setBackgroundMediaAsBase64] = useState<string>();
    const [backgroundMediaSizes, setBackgroundMediaSizes] = useState<{ w: number, h: number }>();
    const [files, setFiles] = useState<any[]>([]);
    const [isAddAnchorModalOpen, setIsAddAnchorModalOpen] = useState(false);
    const [isAddUnitDetailModalOpen, setIsAddUnitDetailModalOpen] = useState(false);
    const [isErrorUnitsPositionModalOpen, setIsErrorUnitsPositionModalOpen] = useState(false);
    const [newAnchor, setNewAnchor] = useState<Anchor>();
    const [clickedNewAnchorId, setClickedNewAnchorId] = useState<string>();
    const [warningAnchorIds, setWarningAnchorIds] = useState<string[]>([]);
    const [building, setBuilding] = useState<Building>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isNewAnchorPositioned, setIsNewAnchorPositioned] = useState<boolean>(false);
    const [isDeletingLevel, setIsDeletingLevel] = useState<boolean>(false);

    var levelService = new LevelService();
    var buildingService = new BuildingService();
    var resourceService = new ResourceService();

    const getBuilding = useCallback(async () => {
        try {
            let idParam = window.location.href.split('id=')[1].split('&')[0] ?? '';
            var res = await buildingService.findBuilding(idParam);

            setBuilding(res);
        } catch (e) {
            console.error(e);
            history('/500');
        }
    }, []);

    useEffect(() => {
        getBuilding();
    }, [getBuilding]);

    const handleUpdateAnchor = (id: string, updatedCoordinates: number[]): void => {
        var anchor = anchors.filter(a => a.id === id)[0];

        anchor.coordinates = updatedCoordinates;
        setIsNewAnchorPositioned(false);
        if (anchor.id === clickedNewAnchorId) {
            setClickedNewAnchorId('');
        }
    }

    const onUnitCreated = (unitCreated: any) => {
        if (unitCreated?.layer?.editing?.latlngs) {
            var currentUnit: Unit = {
                id: uuidv4(),
                fillPercentage: 100,
                drawedId: unitCreated.layer._renderer._leaflet_id,
                name: '',
                alternativeName: '',
                coordinates: []
            }

            forEach(unitCreated.layer.editing.latlngs, coordinates => {
                forEach(coordinates, coordinate => {
                    forEach(coordinate, value => {
                        var s = [value.lat, value.lng];
                        currentUnit.coordinates.push(s);
                    })
                })
            })

            setCurrentUnit(deepClone(currentUnit));
            setIsAddUnitDetailModalOpen(true);
        }
    }

    const onUnitEdited = (unitEdited: any) => {
        unitEdited.layers.eachLayer((layer: any) => {
            forEach(units, unit => {
                if (unit.coordinates[0][0] == layer._latlngs[0][0].lat) {
                    unit.coordinates = [];
                    forEach(layer.editing.latlngs[0][0], coordinates => {
                        unit.coordinates.push([coordinates.lat, coordinates.lng]);
                    })
                }
            });

            setUnits(deepClone(units))
        });

    }

    const onUnitDeleted = (unitDeleted: any) => {

        unitDeleted.layers.eachLayer((layer: any) => {
            var updatedUnits = units.filter(unit => unit.coordinates[0][0] != layer.editing.latlngs[0][0][0].lat
                && unit.coordinates[0][1] != layer.editing.latlngs[0][0][0].lng);

            setUnits(deepClone(updatedUnits));
        });

        setIsDeletingLevel(false);
    }

    const handleDeleteAnchor = (id: string): void => {
        var anchorToBeDelete = anchors.filter(a => a.id === id)[0];

        if (anchorToBeDelete) {
            var resource = fullResourceSelectItems.filter(resource => resource?.id === anchorToBeDelete.resourceId)[0];
            resourceSelectItems.push(resource);
            setResourceSelectItems(deepClone(resourceSelectItems));
        }

        var updatedAnchors = anchors.filter(a => a.id !== id);
        setAnchors(deepClone(updatedAnchors));
    }

    function isAnchorInUnits(units: Unit[]): boolean {

        var allAnchorInUnit = true;

        forEach(anchors, anchor => {
            if (!anchorIsInThisUnit(units, anchor)) {
                allAnchorInUnit = false;
                if (!warningAnchorIds?.includes(anchor.id)) {
                    warningAnchorIds?.push(anchor.id);
                    setWarningAnchorIds(deepClone(warningAnchorIds));
                }
            }
        });

        return allAnchorInUnit;
    }

    const handleDrop = (newFiles: any): void => {

        newFiles.map((file: any) => {
            var reader = new FileReader();

            reader.onload = () => {
                var image = new Image();
                image.src = reader.result as string;
                image.onload = () => {
                    setBackgroundMediaAsBase64(reader.result as string);
                    var sizes: any = {
                        w: image.width as number,
                        h: image.height as number
                    };
                    setBackgroundMediaSizes(sizes);
                };
            };

            reader.readAsDataURL(newFiles[0]);
        });

        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const handleRemove = (file: any): void => {
        setFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
        setBackgroundMediaAsBase64('');
    };

    useEffect(() => {

        if (isAdd) {
            setLevel(getEmptyLevel());
            setAnchors(getEmptyAnchors());
        } else {
            (async () => {
                if (userInfo) {
                    let idParam = window.location.href.split('levelId=')[1] ?? '';

                    var level = await levelService.findLevel(idParam);
                    var anchors = await levelService.getAnchors(userInfo?.tenantId, level.units?.map(unit => unit.id));

                    let image = new Image();
                    image.src = level.planimetry.base64;
                    image.onload = () => {
                        var sizes: any = {
                            w: image.width as number,
                            h: image.height as number
                        };
                        setBackgroundMediaSizes(sizes);
                    }

                    setBackgroundMediaAsBase64(level.planimetry.base64);
                    setLevel(level);
                    setUnits(level.units);
                    setAnchors(anchors);
                }

            })();

        }

        (async () => {
            if (userInfo) {
                var resourceItems = await resourceService.getResourceSelectItemsForLevel(userInfo.tenantId);

                //setSelectedResourceSelectItem(deepClone(resourceItems[0]));
                setResourceSelectItems(deepClone(resourceItems));
                setFullResourceSelectItems(deepClone(resourceItems));
            }
        })();

    }, []);

    async function addLevel() {
        try {
            setIsLoading(true);
            if (level) {
                level.tenantId = userInfo?.tenantId || '';
                level.addressId = building?.addressId || '';
                level.buildingIds = [building?.id || ''];
                level.planimetry.base64 = backgroundMediaAsBase64 || '';
                level.units = backgroundMediaAsBase64 ? units : [];


                anchors?.map(anchor => {
                    anchor.tenantId = userInfo?.tenantId || '';
                });

                await levelService.upsertLevel(level);
                await levelService.upsertAnchors(userInfo?.tenantId || '', level.id, anchors);

                enqueueSnackbar(`${t('LEVEL')} ${isAdd ? t('CREATED') : t('UPDATED')}`, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });

                history(`/levels?buildingId=${building?.id}`);
            }
        } catch (e) {
            history('/500');
        }
        setIsLoading(false);
    }

    return (
        <> {level &&
            <Box sx={{ p: 3 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xl={12}
                        xs={12}
                    >
                        <Card>
                            <Box sx={{ p: 3 }}>
                                <CardContent>
                                    <Grid
                                        container
                                        spacing={4}
                                    >
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label={t('NAME')}
                                                name="name"
                                                required
                                                onChange={(e) => {
                                                    level.name = e.target.value;
                                                    setLevel(deepClone(level));
                                                }}
                                                value={level.name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label={t('SHORT_NAME')}
                                                name="short name"
                                                onChange={(e) => {
                                                    level.shortName = e.target.value;
                                                    setLevel(deepClone(level));
                                                }}
                                                value={level.shortName}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label={t('LEVEL_NUMBER')}
                                                name="type"
                                                type="number"
                                                onChange={(e: any) => {
                                                    level.ordinal = e.target.value;
                                                    setLevel(deepClone(level));
                                                }}
                                                SelectProps={{ native: true }}
                                                value={level.ordinal}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <FormControlLabel
                                                control={(
                                                    <Switch
                                                        checked={level.outdoor}
                                                        onChange={(e: any) => {
                                                            level.outdoor = e.target.checked;
                                                            setLevel(deepClone(level));
                                                        }}
                                                        color="primary"
                                                        name="Is Outdoor"
                                                    />
                                                )}
                                                label={t('IS_OUTDOOR')}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                        >
                                            {!backgroundMediaAsBase64
                                                ? <FileDropzone
                                                    accept="image/*"
                                                    files={files}
                                                    onDrop={handleDrop}
                                                    onRemove={handleRemove} />
                                                : backgroundMediaSizes &&
                                                <div>
                                                    <Button
                                                        color="secondary"
                                                        startIcon={<DeleteIcon fontSize="small" />}
                                                        sx={{ m: 1, float: 'left' }}
                                                        variant="contained"
                                                        onClick={() => {
                                                            setFiles([]);
                                                            setBackgroundMediaAsBase64('');
                                                            level.units = [];
                                                            level.planimetry.base64 = '';
                                                            setAnchors([]);
                                                            setLevel(deepClone(level));
                                                        }}
                                                    >
                                                        {t('DELETE')}
                                                    </Button>
                                                    <Button
                                                        disabled={isNewAnchorPositioned || !units || units.length === 0}
                                                        color="primary"
                                                        startIcon={<PlusIcon fontSize="small" />}
                                                        sx={{ m: 1, float: 'right' }}
                                                        variant="contained"
                                                        onClick={() => {
                                                            setIsAddAnchorModalOpen(true);

                                                            var emptyAnchor: Anchor = {
                                                                id: uuidv4(),
                                                                tenantId: userInfo?.tenantId || '',
                                                                coordinates: [backgroundMediaSizes.h / 2, backgroundMediaSizes.w / 2],
                                                                resourceId: '',
                                                                addressId: building?.addressId || '',
                                                                resourceName: '',
                                                                unitId: uuidv4()
                                                            }

                                                            setNewAnchor(deepClone(emptyAnchor));
                                                            setClickedNewAnchorId(emptyAnchor.id);
                                                        }}
                                                    >
                                                        {t('ADD_RESOURCE')}
                                                    </Button>
                                                    <div>
                                                        <Map
                                                            zoom={0}
                                                            minZoom={-10}
                                                            maxZoom={10}
                                                            scrollWheelZoom={true}
                                                            crs={CRS.Simple}
                                                            center={[backgroundMediaSizes.h / 2, backgroundMediaSizes.w / 2]} >

                                                            <ImageOverlay
                                                                bounds={[[0, 0], [backgroundMediaSizes.h, backgroundMediaSizes.w]]}
                                                                url={backgroundMediaAsBase64} />

                                                            <FeatureGroup>
                                                                <EditControl
                                                                    position="topright"
                                                                    onCreated={onUnitCreated}
                                                                    onEdited={onUnitEdited}
                                                                    onDeleted={onUnitDeleted}
                                                                    onDeleteStart={() => setIsDeletingLevel(true)}
                                                                    draw={{
                                                                        rectangle: false,
                                                                        circle: false,
                                                                        circlemarker: false,
                                                                        marker: false,
                                                                        polyline: false,
                                                                        polygon: true
                                                                    }}
                                                                />
                                                                {level.units?.map(unit => {
                                                                    var positions = unit.coordinates.map(coordinate => {
                                                                        return {
                                                                            lat: coordinate[0],
                                                                            lng: coordinate[1]
                                                                        }
                                                                    });

                                                                    return (<Polygon
                                                                        key={unit.id}
                                                                        onclick={(p) => {
                                                                            console.log('clicked poligon')
                                                                            if (!isDeletingLevel) {
                                                                                console.log(isDeletingLevel)
                                                                                var Xvalues = unit.coordinates.map(coordinate => coordinate[0]);
                                                                                var Yvalues = unit.coordinates.map(coordinate => coordinate[1]);

                                                                                if (isInPolygon(unit.coordinates.length, Xvalues, Yvalues, p.latlng.lat, p.latlng.lng)) {
                                                                                    setCurrentUnit(unit);
                                                                                    setIsAddUnitDetailModalOpen(true);
                                                                                }
                                                                            }
                                                                        }}
                                                                        positions={positions} />)
                                                                })}

                                                            </FeatureGroup>

                                                            {anchors.map(anchor => {
                                                                return (<DraggableMarker
                                                                    anchorProp={anchor}
                                                                    handleUpdateAnchor={handleUpdateAnchor}
                                                                    handleDeleteAnchor={handleDeleteAnchor}
                                                                    clickedNewAnchorId={clickedNewAnchorId}
                                                                    warningAnchorIds={warningAnchorIds} />)
                                                            })}

                                                        </Map>
                                                    </div>
                                                </div>}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    mt: 2
                                }}>
                                    <Button
                                        disabled={!level.name || !units || units.length === 0}
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        onClick={async () => {
                                            if (!isAnchorInUnits(units)) {
                                                setIsErrorUnitsPositionModalOpen(true);
                                            } else {
                                                setWarningAnchorIds([]);
                                                await addLevel()
                                            }
                                        }}
                                    >
                                        {isAdd ? t('ADD_LEVEL') : t('LEVEL_EDIT')}
                                    </Button>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    onClose={() => setIsAddAnchorModalOpen(false)}
                    open={isAddAnchorModalOpen}
                >
                    <Box sx={{ p: 3 }}>
                        <Typography
                            align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="h5"
                        >
                            {t('ADD_RESOURCE_MARKER')}
                        </Typography>
                        <Box sx={{ flexGrow: 1, p: 2 }} />
                        <Box sx={{ mt: 2 }} style={{ marginBottom: '10px' }}>
                            {<Autocomplete
                                value={selectedResourceSelectItem}
                                getOptionLabel={(option) => option?.name}
                                options={resourceSelectItems || ''}
                                onChange={(event, selectedResource) => {
                                    if (newAnchor) {
                                        newAnchor.resourceId = selectedResource?.id || '';
                                        newAnchor.resourceName = selectedResource?.name || '';
                                        setNewAnchor(deepClone(newAnchor));
                                    }
                                    setSelectedResourceSelectItem(deepClone(selectedResource));
                                }}
                                onInputChange={(event, newInputValue) => {
                                }}
                                renderInput={(params): JSX.Element => (
                                    <TextField
                                        label={t('RESOURCE')}
                                        name="resource"
                                        required
                                        variant="outlined"
                                        {...params}>
                                    </TextField>
                                )}
                            />}
                        </Box>
                        <Box sx={{ flexGrow: 1, pt: 2, float: 'right' }} >
                            <Button
                                color="primary"
                                onClick={() => {
                                    if (backgroundMediaSizes) {
                                        var emptyAnchor: Anchor = {
                                            id: uuidv4(),
                                            tenantId: userInfo?.tenantId || '',
                                            coordinates: [backgroundMediaSizes.h / 2, backgroundMediaSizes.w / 2],
                                            resourceId: '',
                                            resourceName: '',
                                            addressId: '',
                                            unitId: ''
                                        }
                                        setNewAnchor(deepClone(emptyAnchor));
                                        setIsAddAnchorModalOpen(false);
                                    }
                                }}
                                variant="text"
                            >
                                {t('CANCEL')}
                            </Button>

                            <Button
                                color="primary"
                                disabled={!selectedResourceSelectItem}
                                sx={{ ml: 1 }}
                                type="submit"
                                variant="contained"
                                onClick={() => {
                                    if (newAnchor) {
                                        var updatedResources = resourceSelectItems?.filter(resource => resource.id !== newAnchor.resourceId);
                                        if (!newAnchor.resourceId) {
                                            newAnchor.resourceId = updatedResources[0].id;
                                            newAnchor.resourceName = updatedResources[0].name;
                                        }

                                        anchors.push(newAnchor);
                                        setResourceSelectItems(deepClone(updatedResources));
                                        setSelectedResourceSelectItem(undefined);
                                        setAnchors(deepClone(anchors));
                                    }
                                    setIsAddAnchorModalOpen(false);
                                    setIsNewAnchorPositioned(true);
                                    enqueueSnackbar(t('ADD_ANCHOR_MESSAGE_SUCCESSFULLY'), {
                                        anchorOrigin: {
                                            horizontal: 'left',
                                            vertical: 'bottom'
                                        },
                                        variant: 'success'
                                    });
                                }}
                            >
                                {t('CONFIRM')}
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
                {currentUnit && <Dialog
                    fullWidth
                    maxWidth="sm"
                    onClose={() => {
                        if (!currentUnit.name) {
                            currentUnit.name = 'default';
                            currentUnit.alternativeName = 'default';
                        }
                        setIsAddUnitDetailModalOpen(false)
                    }}
                    open={isAddUnitDetailModalOpen}
                >
                    <Box sx={{ p: 3 }}>
                        <Typography
                            align="center"
                            color="textPrimary"
                            gutterBottom
                            variant="h5"
                        >
                            {t('ADD_UNIT_DETAIL')}
                        </Typography>
                        <Box sx={{ flexGrow: 1, p: 2 }}>
                            <TextField
                                fullWidth
                                label={t('NAME')}
                                name="name"
                                required
                                onChange={(e) => {
                                    currentUnit.name = e.target.value;
                                    currentUnit.alternativeName = e.target.value;
                                    setCurrentUnit(deepClone(currentUnit));
                                }}
                                value={currentUnit.name}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ flexGrow: 1, pt: 2, float: 'right' }} >
                            <Button
                                color="primary"
                                disabled={!currentUnit.name}
                                sx={{ ml: 1 }}
                                type="submit"
                                variant="contained"
                                onClick={() => {
                                    setIsAddUnitDetailModalOpen(false);

                                    units.forEach((unit, index) => {
                                        if (unit.id === currentUnit.id)
                                            units[index] = currentUnit
                                    })

                                    units.push(currentUnit);
                                    level.units = units;
                                    setLevel(deepClone(level));
                                    setCurrentUnit(undefined);
                                }} >

                                {t('CONFIRM')}
                            </Button>
                        </Box>
                    </Box>
                </Dialog>}
                {isErrorUnitsPositionModalOpen &&
                    <Dialog
                        fullWidth
                        maxWidth="sm"
                        onClose={() => { setIsErrorUnitsPositionModalOpen(false) }}
                        open={isErrorUnitsPositionModalOpen}
                    >
                        <ModalResourcesErrorPosition setIsErrorUnitsPositionModalOpen={setIsErrorUnitsPositionModalOpen} />
                    </Dialog>}
                <Loader isLoading={isLoading} />
            </Box>}
        </>)
};


function DraggableMarker(props) {
    const { anchorProp, handleUpdateAnchor, handleDeleteAnchor, clickedNewAnchorId, warningAnchorIds } = props;

    const [position, setPosition] = useState<number[]>([0, 0]);
    const markerRef = useRef<any>(null);

    useEffect(() => {
        if (anchorProp)
            setPosition(deepClone(anchorProp.coordinates))
    }, [anchorProp]);

    const iconGreen = L.icon({
        iconUrl: markerGreen,
        iconSize: [32, 34],
        iconAnchor: [16, 0]
    });

    const iconBlue = L.icon({
        iconUrl: markerBlue,
        iconSize: [32, 34],
        iconAnchor: [16, 0]
    });

    const iconYellow = L.icon({
        iconUrl: markerYellow,
        iconSize: [32, 34],
        iconAnchor: [16, 0]
    });

    function dragend(e: any) {
        setPosition(deepClone([e.target._latlng.lat, e.target._latlng.lng]));
        handleUpdateAnchor(anchorProp.id, [e.target._latlng.lat, e.target._latlng.lng]);
    }

    return (position ?
        <Marker
            ondragend={(e) => dragend(e)}
            icon={anchorProp.id === clickedNewAnchorId
                ? iconGreen
                : warningAnchorIds?.includes(anchorProp.id)
                    ? iconYellow
                    : iconBlue}
            draggable={true}
            position={[position && position[0] ? position[0] : 0, position && position[1] ? position[1] : 0]}
            ref={markerRef}>
            <Popup minWidth={100} position={{ lat: 34, lng: 34 }}>
                {anchorProp.resourceName}
                <DeleteIcon
                    fontSize="small"
                    style={{ marginLeft: '8px' }}
                    onClick={() => {
                        handleDeleteAnchor(anchorProp.id);
                    }} />
            </Popup>
        </Marker>
        : <></>)
}

function getEmptyAnchors() {
    var anchors: Anchor[] = [];
    return anchors;
}

function anchorIsInThisUnit(units: Unit[], anchor: Anchor) {
    var isInUnit = false;
    forEach(units, unit => {
        var Xvalues = unit.coordinates.map(coordinate => coordinate[0]);
        var Yvalues = unit.coordinates.map(coordinate => coordinate[1]);

        if (isInPolygon(unit.coordinates.length, Xvalues, Yvalues, anchor.coordinates[0], anchor.coordinates[1])) {
            isInUnit = true;
            anchor.unitId = unit.id;
        }
    });
    return isInUnit;
}

function isInPolygon(nvert: number, vertx: number[], verty: number[], testx: number, testy: number) {
    var i = 0;
    var j = 0;
    var c = false;
    for (i = 0, j = nvert - 1; i < nvert; j = i++) {
        if (((verty[i] > testy) !== (verty[j] > testy)) && (testx < (vertx[j] - vertx[i]) * (testy - verty[i]) / (verty[j] - verty[i]) + vertx[i]))
            c = !c;
    }
    return c;
}

function getEmptyLevel() {

    var emptyCoordinates: Coordinates = {
        latitude: 0,
        longitude: 0
    }

    var emptyCorners: Corners = {
        northWest: emptyCoordinates,
        northEast: emptyCoordinates,
        southWest: emptyCoordinates,
        southEast: emptyCoordinates
    }

    var emptyPlanimetry: Planimetry = {
        corners: emptyCorners,
        center: emptyCoordinates,
        zoom: 0,
        width: 0,
        height: 0,
        scaleFactor: 0,
        base64: ''
    }

    var emptyLevel: Level = {
        id: uuidv4(),
        addressId: '',
        ordinal: 0,
        tenantId: '',
        outdoor: false,
        name: '',
        shortName: '',
        buildingIds: [],
        coordinates: null,
        planimetry: emptyPlanimetry,
        units: []
    }

    return emptyLevel;
}

const ModalResourcesErrorPosition: FC<any> = (props) => {

    const { setIsErrorUnitsPositionModalOpen } = props;

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                p: 3
            }}
        >
            <Container maxWidth="sm">
                <Box
                    sx={{
                        display: 'flex',
                        pb: 2,
                        pt: 3,
                        px: 3
                    }}
                >
                    <Avatar
                        sx={{
                            backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                            color: 'warning.main',
                            mr: 2
                        }}
                    >
                        <WarningIcon />
                    </Avatar>
                    <Box>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            Errore di posizionamento delle risorse
                        </Typography>
                        <Typography
                            color="textSecondary"
                            sx={{ mt: 1 }}
                            variant="body2"
                        >
                            Le risorse inserite nella mappa devono essere contenute all'interno del perimetro delle aree.
                            Controlla il loro corretto posizionamento.
                         </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        px: 3,
                        py: 1.5
                    }}
                >
                    <Button
                        sx={{
                            backgroundColor: 'warning.main',
                            '&:hover': {
                                backgroundColor: 'warning.dark'
                            }
                        }}
                        variant="contained"
                        onClick={() => setIsErrorUnitsPositionModalOpen(false)}
                    >
                        Chiudi
                    </Button>
                </Box>
            </Container>
        </Box>)
};

export default LevelAddForm;
