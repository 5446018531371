declare global {
    interface Window {
      odevConfig: any;
    }
  }
const config = window.odevConfig;

interface ServiceConfig {
    appHostName: string;
    serviceBaseUrl: string;
    apiBaseUrl: string;
    authUrl: string;
    clientId: string;
    environmentName: string;
    appUrl: string;
}
const location = window.location;
const hostName: string = (location.protocol + '//' + location.host);
const authUrl: string = config?.IdentityServerUrl || process.env.REACT_APP_IDENTITY_SERVER_URL || '';
const serviceBaseUrl: string = config?.AppServiceBaseUrl || process.env.REACT_APP_SERVICE_BASE_URL || ''; 
const apiBaseUrl: string = config?.ApiServiceBaseUrl || process.env.REACT_APP_API_URL || ''; 
const clientId: string = config?.IdentityServerClientId || process.env.REACT_APP_IDENTITY_CLIENT_ID || '';
const appUrl: string = config?.IdentityServerClientId || process.env.REACT_APP_APP_URL || '';

if (!authUrl)
    throw new Error("Missing env REACT_APP_IDENTITY_SERVER_URL or config value 'IdentityServerUrl'");
if (!serviceBaseUrl)
    throw new Error("Missing env REACT_APP_SERVICE_BASE_URL or config value 'AppServiceBaseUrl'");
if (!clientId)
    throw new Error("Missing env REACT_APP_IDENTITY_CLIENT_ID or config value 'IdentityServerClientId'");

let serviceConfig: ServiceConfig = {
    appHostName: hostName,
    serviceBaseUrl: serviceBaseUrl + '/api',
    apiBaseUrl: apiBaseUrl,
    authUrl: authUrl,
    clientId: clientId,
    environmentName: config?.EnvironmentName || process.env.NODE_ENV,
    appUrl: appUrl
};

export default serviceConfig;