import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@material-ui/core';
import type { Resource, ResourceDocument } from '../../types/resource';
import ResourceAccessManagement from '../resource/ResourceAccessManagement';
import FileDropzone from '../FileDropzone';
import { ResourceService } from '../../services/ResourceService';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import { ResourceTypeSelectItem } from '../../types/resourceType';
import { ResourceTypeService } from '../../services/ResourceTypeService';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import ResourceDocumentList from './ResourceDocumentList';
import { capitalizeFirstLetter } from '../../utils/common';
import AvatarDropzone from '../AvatarDropzone';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';

interface ResourceAddFormProps {
  resource: Resource;
  isAdd: boolean;
  updateResource?: (resource: Resource) => void;
}

const ResourceAddForm: FC<ResourceAddFormProps> = (props) => {
  const { resource, isAdd, updateResource, ...other } = props;
  const history = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);

  const [avatar, setAvatar] = useState<string>();
  const [avatarFiles, setAvatarFiles] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [resourceTypeSelectItems, setResourceTypeSelectItems] = useState<ResourceTypeSelectItem[]>([]);
  const [selectedResourceTypeSelectItem, setSelectedResourceTypeSelectItem] = useState<string>();
  const [buttonSaveText, setButtonSaveText] = useState<string>(t('UPDATE_RESOURCE'));
  const [currentTab, setCurrentTab] = useState<string>('resource');
  const [documents, setDocuments] = useState<ResourceDocument[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [capacity, setCapacity] = useState<number>(1);
  const [effectiveCapacity, setEffectiveCapacity] = useState<number>(1);

  const tabs = [
    { label: t('RESOURCE'), value: 'resource' },
    //{ label: t('ACCESSES'), value: 'accesses' }
  ];

  var resourceService = new ResourceService();

  const handleRemoveDocument = (id: string): void => {
    resource.documents = resource.documents.filter((_file) => _file.id !== id);
    if (updateResource)
      updateResource(resource);
  }

  useEffect(() => {
    if (resource.avatar) {
      setAvatar(resource.avatar);
    } else {
      setAvatar(undefined);
    }

    setCapacity(resource.capacity);
    setEffectiveCapacity(resource.effectiveCapacity);

    (async () => {
      if (userInfo) {
        try {
          var resourceTypeService = new ResourceTypeService();
          var selectItems = await resourceTypeService.getResourceTypeSelectItems(userInfo?.tenantId);
          selectItems.unshift({ id: '', description: '', code: '', impactOnCapacity: false });
          setResourceTypeSelectItems(selectItems);
        }
        catch (err) {
          console.error(err);
          history('/500');
        }
      }
    })();
    if (isAdd) {
      setButtonSaveText(t('ADD_RESOURCE'));
    }
    setSelectedResourceTypeSelectItem(resource.resourceTypeCode);

  }, [userInfo, resource]);

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const handleAvatarDrop = (newFiles: any): void => {
    var reader = new FileReader();
    reader.readAsDataURL(newFiles[0]);
    reader.onload = () => {
      setAvatar(reader.result as string);
    };

    setAvatarFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleAvatarRemove = (): void => {
    setAvatarFiles([]);
    setAvatar(undefined);
  };

  const handleDrop = (newFiles: any): void => {

    newFiles.map((file: any) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        var doc: ResourceDocument = {
          id: uuidv4(),
          base64: reader.result as string,
          fileName: file.name,
          lenght: 0
        }
        documents?.push(doc);
      }
    });

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file: any): void => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path
      !== file.path));
    setDocuments(documents.filter((_file) => _file.fileName !== file.path));
  };

  const handleRemoveAll = (): void => {
    setFiles([]);
    setDocuments([]);
  };

  return (resource ?
    <>
      <Box sx={{ mt: 3 }}>
        <Tabs
          indicatorColor="primary"
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {!isAdd && tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
      </Box>
      <Divider />
      {currentTab === 'resource' &&
        <Formik
          initialValues={{
            name: resource.name || '',
            type: resource.resourceTypeCode || '',
            description: resource.description || '',
            users: resource.users || [""],
            id: resource.id || '',
            tenantId: resource.tenantId || '',
            documents: resource.documents || [],
            submit: null,
            capacity: resource.capacity,
            effectiveCapacity: resource.effectiveCapacity

          }}
          validationSchema={
            Yup
              .object()
              .shape({
                name: Yup
                  .string()
                  .max(255)
                  .required(t('NAME_REQUIRED')),
                description: Yup
                  .string()
                  .max(255)
              })
          }
          onSubmit={async (values, {
            resetForm,
            setErrors,
            setStatus,
            setSubmitting
          }): Promise<void> => {
            try {
              setIsLoading(true);

              documents?.map(doc => resource.documents.push(doc));

              const resourceModel: Resource = {
                id: isAdd ? uuidv4() : values.id,
                tenantId: userInfo?.tenantId ?? '',
                name: capitalizeFirstLetter(values.name),
                description: values.description,
                resourceTypeCode: selectedResourceTypeSelectItem ?? '',
                users: values.users,
                documents: resource.documents,
                avatar: avatar ?? '',
                capacity: capacity,
                effectiveCapacity: effectiveCapacity
              };

              var responseResult = await resourceService.upsertResource(resourceModel);

              var msgResource = isAdd ? t('RESOURCE_CREATED') : t('RESOURCE_UPDATED');

              enqueueSnackbar(responseResult.result ? msgResource : t(responseResult.message), {
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                },
                variant: responseResult.result ? 'success' : 'error'
              });
              if (responseResult.result) {
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                history('/resources');
              }
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
              history('/500');
            }
            setIsLoading(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }): JSX.Element => (
            <form
              onSubmit={handleSubmit}
              {...other}
            >
              <Box sx={{ pt: 3 }}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    lg={4}
                    md={6}
                    xl={3}
                    xs={12}
                  >
                    <Card>
                      <CardHeader title={t('AVATAR')} />
                      <Divider />
                      <CardContent>
                        {avatar
                          ? <div style={{ textAlign: 'center' }}>
                            <img src={avatar} alt="avatar" style={{ padding: '20px', borderRadius: '50%', width: '250px', height: '250px' }}>
                            </img>
                            <Divider />
                            <Box sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              mt: 2
                            }}>
                              <Button
                                sx={{
                                  backgroundColor: 'error.main',
                                  color: 'error.contrastText',
                                  '&:hover': {
                                    backgroundColor: 'error.dark'
                                  }
                                }}
                                variant="contained"
                                onClick={handleAvatarRemove}
                              >
                                {t('DELETE')}
                              </Button>
                            </Box>
                          </div>
                          : <Box sx={{ mt: 3 }}>
                            <AvatarDropzone
                              accept="image/*"
                              files={avatarFiles}
                              maxFiles={1}
                              maxSize={5000000}
                              onDrop={handleAvatarDrop}
                              onRemove={handleAvatarRemove}
                            />
                          </Box>}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    md={6}
                    xl={9}
                    xs={12}
                  >
                    <Card>
                      <CardHeader title={t('RESOURCE')} />
                      <Divider />
                      <CardContent>
                        <Grid
                          container
                          spacing={4}
                        >
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              label={t('NAME')}
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.name}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(!selectedResourceTypeSelectItem)}
                              fullWidth
                              helperText={touched.type && errors.type}
                              label={t('TYPE')}
                              name="type"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setSelectedResourceTypeSelectItem(e.target.value);
                              }}
                              required
                              select
                              SelectProps={{ native: true }}
                              value={selectedResourceTypeSelectItem || ''}
                              variant="outlined"
                            >
                              {resourceTypeSelectItems.map((resourceTypeSelectItem) => (
                                <option
                                  key={resourceTypeSelectItem.code}
                                  value={resourceTypeSelectItem.code}
                                >
                                  {resourceTypeSelectItem.description}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.description && errors.description)}
                              fullWidth
                              helperText={touched.description && errors.description}
                              label={t('DESCRIPTION')}
                              name="description"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.description}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              type="number"
                              label={t('CAPACITY')}
                              name="capacity"
                              onChange={(e) => {
                                setCapacity(parseInt(e.target.value))
                              }}
                              required
                              value={capacity}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              type="number"
                              label={t('EFFECTIVE_CAPACITY')}
                              name="effectiveCapacity"
                              onChange={(e) => {
                                setEffectiveCapacity(parseInt(e.target.value))
                              }}
                              required
                              value={effectiveCapacity}
                              variant="outlined"
                            />
                          </Grid>
                          {(!capacity || capacity < 0 || effectiveCapacity < 0 || effectiveCapacity > capacity) ?
                            <Grid
                              item
                              md={12}
                              xs={12}
                            >
                              <Typography
                                color="error"
                                variant="subtitle2"
                              >
                                {t('EFFECTIVE_CAPACITY_LESS_CAPACITY')}
                              </Typography>
                            </Grid> : <></>}
                        </Grid>
                        <Box sx={{ mt: 3 }}>
                          <CardHeader title={t('DOCUMENTS')} />
                          <CardContent>
                            {!isAdd && resource.documents?.length > 0 &&
                              <ResourceDocumentList
                                files={resource.documents}
                                resourceId={resource.id}
                                isDetail={false}
                                handleRemoveDocument={handleRemoveDocument} />}
                            <FileDropzone
                              files={files}
                              onDrop={handleDrop}
                              onRemove={handleRemove}
                              onRemoveAll={handleRemoveAll}
                            />
                          </CardContent>
                        </Box>
                        <Divider />
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          mt: 2
                        }}>
                          <Button
                            color="primary"
                            disabled={isSubmitting || !capacity || effectiveCapacity > capacity}
                            type="submit"
                            variant="contained"
                          >
                            {buttonSaveText}
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Loader isLoading={isLoading} />
              </Box>
            </form>
          )}
        </Formik>}
      <Box sx={{ mt: 1 }}>
        {currentTab === 'accesses' &&
          <ResourceAccessManagement resource={resource} />}
      </Box>
    </> : <></>
  );
};

export default ResourceAddForm;
