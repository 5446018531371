import type { FC } from 'react';
import { useRef, useState } from 'react';
import { Avatar, Box, Button, ButtonBase, Divider, Popover, Typography } from '@material-ui/core';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import userManager from '../../userManager';
import { useTranslation } from 'react-i18next';

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
  const { t } = useTranslation();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (event: any): Promise<void> => {
    event.preventDefault();
    userManager.signoutRedirect();
    userManager.removeUser();
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <Avatar
          src={userInfo?.avatar}
          sx={{
            height: 32,
            width: 32
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {userInfo?.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {userInfo?.email}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            {t('LOGOUT')}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
