import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { ResourceType } from '../types/resourceType';
import { Box, Breadcrumbs, Button, Container, Divider, Grid, Link, Tab, Tabs, Typography } from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ResourceTypeDataDetails from '../components/resourceType/ResourceTypeDataDetails';
import ChevronRightIcon from '../icons/ChevronRight';
import PencilAltIcon from '../icons/PencilAlt';
import ResourceTypeDataManagement from '../components/resourceType/ResourceTypeDataManagement';
import { ResourceTypeService } from '../services/ResourceTypeService';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

const ResourceTypeDetails: FC = () => {
    const [resourceType, setResourceType] = useState<ResourceType | null>(null);
    const [currentTab, setCurrentTab] = useState<string>('details');
    const [id, setId] = useState<string>('');
    const history = useNavigate();
    const { t } = useTranslation();

    const tabs = [
        { label: t('DETAILS'), value: 'details' }
    ];

    const getCustomer = useCallback(async () => {
        try {
            let idParam = window.location.href.split('id=')[1] ?? '';
            setId(idParam!);
            var resourceTypeService = new ResourceTypeService();
            var res = await resourceTypeService.findResourceType(idParam);
            setResourceType(res);
        } catch (err) {
            console.error(err);
            history('/500');
        }
    }, []);

    useEffect(() => {
        getCustomer();
    }, [getCustomer]);

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    var currentTheme = useTheme();

    if (!resourceType) {
        return null;
    }

    return (
        <>
            <div style={{ margin: 0, padding: 0 }}>
                <Box
                    sx={{
                        backgroundColor: 'background.default',
                        minHeight: '100%',
                        py: 2,
                        [currentTheme.breakpoints.up('lg')]: {
                            paddingLeft: '300px'
                        }
                    }}
                >
                    <Container>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <Grid item>
                                <Typography
                                    color="textPrimary"
                                    variant="h5"
                                >
                                    {resourceType.name}
                                </Typography>
                                <Breadcrumbs
                                    aria-label="breadcrumb"
                                    separator={<ChevronRightIcon fontSize="small" />}
                                    sx={{ mt: 1 }}
                                >
                                    <Link
                                        color="textPrimary"
                                        component={RouterLink}
                                        to="/"
                                        variant="subtitle2"
                                    >
                                        {t('DASHBOARD')}
                                    </Link>
                                    <Link
                                        color="textPrimary"
                                        component={RouterLink}
                                        to="/resourceTypes"
                                        variant="subtitle2"
                                    >
                                        {t('RESOURCE_TYPES')}
                                    </Link>
                                    <Typography
                                        color="textSecondary"
                                        variant="subtitle2"
                                    >
                                        {t('DETAILS')}
                                    </Typography>
                                </Breadcrumbs>
                            </Grid>
                            <Divider />
                            <Grid item>
                                <Box sx={{ m: -1 }}>
                                    <Button
                                        color="primary"
                                        component={RouterLink}
                                        startIcon={<PencilAltIcon fontSize="small" />}
                                        sx={{ m: 1 }}
                                        to={`/edit?id=${id}`}
                                        variant="contained"
                                    >
                                        {t('EDIT')}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 3 }}>
                            <Tabs
                                indicatorColor="primary"
                                onChange={handleTabsChange}
                                scrollButtons="auto"
                                textColor="primary"
                                value={currentTab}
                                variant="scrollable"
                            >
                                {tabs.map((tab) => (
                                    <Tab
                                        key={tab.value}
                                        label={tab.label}
                                        value={tab.value}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                        <Divider />
                        <Box sx={{ mt: 3 }}>
                            {currentTab === 'details' && (
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        xl={6}
                                        xs={12}
                                    >
                                        <ResourceTypeDataDetails
                                            id={resourceType.id}
                                            code={resourceType.code}
                                            name={resourceType.name}
                                            minimumEventMinutes={resourceType.minimumEventMinutes}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        xl={6}
                                        xs={12}
                                    >
                                        <ResourceTypeDataManagement id={resourceType.id} />
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </Container>
                </Box>
            </div>
        </>
    )
}

export default ResourceTypeDetails;
