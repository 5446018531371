import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { UserInfo } from "../../../models/UserInfo";
import { ResourceTypeService } from '../../../services/ResourceTypeService';
import { ResourceType } from '../../../types/resourceType';

interface EventTypeProps {
    step1IsSingleSelectedProp: boolean;

    setStep1IsSingleSelectedProp(val: boolean): void;
    setStep1SelectedResourceTypeProp(val?: ResourceType): void;
}

const EventType: FC<EventTypeProps> = (props) => {

    const { step1IsSingleSelectedProp, setStep1IsSingleSelectedProp, setStep1SelectedResourceTypeProp } = props;
    const { t } = useTranslation();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);

    const resourceTypeService = new ResourceTypeService();

    const [resourceTypes, setResourceTypes] = useState<ResourceType[]>();

    useEffect(() => {
        (async () => {
            if (userInfo) {
                let res = await resourceTypeService.searchResourceTypes(userInfo.tenantId, 0, 100, '');
                setResourceTypes(res?.items);
            }
        })();
    }, [])

    return (<>
        <Grid
            container
            justifyContent="center"
        >
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Box sx={{ width: '350px', display: 'inline-block', py: 3 }}>
                    <Autocomplete
                        getOptionLabel={(option) => option?.name}
                        options={resourceTypes || []}
                        onChange={(event, selResource) => {
                            if (selResource)
                                setStep1SelectedResourceTypeProp(selResource);
                        }}
                        onInputChange={(event, newInputValue) => {
                        }}
                        renderInput={(params): JSX.Element => (
                            <TextField
                                label={t('RESOURCE_TYPE')}
                                name="resource"
                                variant="outlined"
                                {...params}>
                            </TextField>
                        )}
                    />
                </Box>
            </div>
            <Box sx={{ textAlign: 'center' }}>
                <Button
                    onClick={() => {
                        setStep1IsSingleSelectedProp(!step1IsSingleSelectedProp);
                    }}
                    sx={{ m: 1, backgroundColor: step1IsSingleSelectedProp ? '#688eff' : 'slategray' }}
                    variant="contained"
                    size="large"
                >
                    {t('SINGLE')}
                </Button>
                <Button
                    onClick={() => {
                        setStep1IsSingleSelectedProp(!step1IsSingleSelectedProp);
                    }}
                    sx={{ m: 1, backgroundColor: !step1IsSingleSelectedProp ? '#688eff' : 'slategray' }}
                    variant="contained"
                    size="large"
                >
                    {t('IS_PERIODIC')}
                </Button>
            </Box>
        </Grid>
    </>);
}

export default EventType;

