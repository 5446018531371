import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@material-ui/core';
import ResourceTypeAddForm from '../components/resourceType/ResourceTypeAddForm';
import ChevronRightIcon from '../icons/ChevronRight';
import type { ResourceType } from '../types/resourceType';
import { ResourceTypeService } from '../services/ResourceTypeService';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

interface ResourceTypeAddProps {
  isAdd: boolean;
}

const ResourceTypeAdd: FC<ResourceTypeAddProps> = (props) => {
  const { isAdd } = props;
  const [resourceType, setResourceType] = useState<ResourceType | null>(null);
  const [isAddMode, setIsAddMode] = useState<boolean>();
  const history = useNavigate();
  const { t } = useTranslation();
  var currentTheme = useTheme();

  useEffect(() => {
    setIsAddMode(isAdd);
    (async () => {
      if (isAdd) {
        const rsx: ResourceType = {
          id: '',
          code: '',
          name: '',
          tenantId: '',
          impactOnCapacity: false,
          minimumEventMinutes: 60
        };
        setResourceType(rsx);
      } else {
        try {
          let resourceTypeId = window.location.href.split('id=')[1] ?? '';
          var resourceTypeService = new ResourceTypeService();
          var res = await resourceTypeService.findResourceType(resourceTypeId);

          setResourceType(res);

        } catch (err) {
          console.error(err);
          history('/500');
        }
      }
    })();
  }, [isAdd, isAddMode]);


  if (!resourceType) {
    return null;
  }

  let labelTitle = t('UPDATE_RESOURCE_TYPE');
  let navigationTitle = t('EDIT');

  if (isAdd) {
    labelTitle = t('ADD_RESOURCE_TYPE');
    navigationTitle = t('ADD');
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 2,
          [currentTheme.breakpoints.up('lg')]: {
            paddingLeft: '300px'
          }
        }}
      >
        <Container>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {labelTitle}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  {t('DASHBOARD')}
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/resourceTypes"
                  variant="subtitle2"
                >
                  {t('RESOURCE_TYPES')}
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {navigationTitle}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box mt={3}>
            <ResourceTypeAddForm resourceType={resourceType} isAdd={isAddMode!} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ResourceTypeAdd;
