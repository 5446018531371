import type { FC } from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Card,
    Divider,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import type { ResourceType } from '../../types/resourceType';
import { ResourceTypeService } from '../../services/ResourceTypeService';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import { useSnackbar, VariantType } from 'notistack';
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from '../../utils/common';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import InformationCircleIcon from '../../icons/InformationCircle';

interface ResourceTypeAddFormProps {
  resourceType: ResourceType;
  isAdd: boolean;
}

const ResourceTypeAddForm: FC<ResourceTypeAddFormProps> = (props) => {
  const { resourceType, isAdd, ...other } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [impactOnCapacityIsSelected, setImpactOnCapacityIsSelected] = useState<boolean>(false);
  const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
  const history = useNavigate();

  useEffect(() => {
    setImpactOnCapacityIsSelected(resourceType.impactOnCapacity)
  }, [resourceType])

  return (resourceType ?
    <Formik
      initialValues={{
        name: resourceType.name || '',
        code: resourceType.code || '',
        id: resourceType.id || '',
        tenantId: resourceType.tenantId || '',
        submit: null,
        impactOnCapacity: resourceType.impactOnCapacity,
        minimumEventMinutes: resourceType.minimumEventMinutes
      }}
      validationSchema={
        Yup
          .object()
          .shape({
            code: Yup
              .string()
              .max(255)
              .required(t('CODE_REQUIRED')),
            name: Yup
              .string()
              .max(255)
              .required(t('NAME_REQUIRED'))
          })
      }
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }): Promise<void> => {
        try {
          setIsLoading(true);
          var msgResourceType = t('RESOURCE_TYPE_UPDATED');
          var msgVariant: VariantType = 'success';

          const resourceTypeModel: ResourceType = {
            id: uuidv4(),
            tenantId: userInfo?.tenantId ?? '',
            name: capitalizeFirstLetter(values.name),
            code: values.code,
            impactOnCapacity: values.impactOnCapacity,
            minimumEventMinutes: values.minimumEventMinutes
          };

          var resourceTypeService = new ResourceTypeService();
          var returnToList = true;

          if (isAdd) {
            msgResourceType = t('RESOURCE_TYPE_ADDED');
            var checkAdd = await resourceTypeService.canInsertResourceType(resourceTypeModel.code, resourceTypeModel.tenantId);
            if (checkAdd.result) {
              await resourceTypeService.upsertResourceType(resourceTypeModel);
            }
            else {
              msgVariant = 'error';
              msgResourceType = checkAdd.message;
              if (msgResourceType === "RESOURCE_TYPE_EXIST") {
                msgResourceType = t('RESOURCE_TYPE_EXIST');
                returnToList = false;
              }
            }
          }
          else {
            resourceTypeModel.id = values.id;
            await resourceTypeService.upsertResourceType(resourceTypeModel);
          }
          enqueueSnackbar(msgResourceType, {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            variant: msgVariant
          });
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          if (returnToList) {
            history('/resourceTypes');
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
          history('/500');
        }
        setIsLoading(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form
          onSubmit={handleSubmit}
          {...other}
        >
          <Card>
            <Box sx={{ p: 3 }}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.code && errors.code)}
                    fullWidth
                    helperText={touched.code && errors.code}
                    label={t('CODE')}
                    name="code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    disabled={!isAdd}
                    value={values.code}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label={t('NAME')}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography id="discrete-slider" >

                      <FormControlLabel
                        control={(
                          <Switch
                            checked={impactOnCapacityIsSelected}
                            onChange={(e: any) => {
                              setImpactOnCapacityIsSelected(e.target.checked);
                              values.impactOnCapacity = e.target.checked;
                            }}
                            color="primary"
                          />
                        )}
                        label={t('IMPACT_ON_CAPACITY')}
                      />
                      <Tooltip sx={{
                        mb: 1
                      }}
                        title={t('IMPACT_ON_CAPACITY_DESCRIPTION') || ''}
                      >
                        <InformationCircleIcon fontSize="small" />
                      </Tooltip>
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label={t('MINIMUM_EVENT_MINUTES')}
                    name="minimumEventMinutes"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.minimumEventMinutes}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 3 }}>
              </Box>
              <Divider />
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2
              }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {isAdd ? t('ADD_RESOURCE_TYPE') : t('UPDATE_RESOURCE_TYPE')}
                </Button>
              </Box>
              <Loader isLoading={isLoading} />
            </Box>
          </Card>
        </form>
      )}
    </Formik> : <></>
  );
};

export default ResourceTypeAddForm;
