import type { FC } from 'react';
import {  Button, Grid,Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Reservation } from "../../types/reservation";
import Clock from 'react-live-clock';

interface ScreenSaverProps {
    deviceId?: string;
    deviceName?: string;
    reservation?: Reservation; 
    setIsCheckinMaked?: () => void;
  }

const ScreenSaver: FC<ScreenSaverProps> = (props) => {
    const { t } = useTranslation();
    const { setIsCheckinMaked } = props;
    console.log(props.deviceName);
    return (

        <Grid
            container
            spacing={3}
            sx={{ textAlign: 'center' }}>
            <Grid item xs={12} >
                <Typography align="left" 
                style={{ fontSize: '200px' }}
                        color="textPrimary"> <Clock format={'HH:mm'} ticking={true} timezone={'Europe/Rome'} /></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center" 
                    style={{ fontSize: '150px' }}
                    color="textPrimary"
                    >    
                        {props.deviceName}
                </Typography>
            </Grid> 
            <Grid item xs>
                {props.reservation && <Button
                        color="primary"
                        sx={{ m: 1, mt: 5 }}
                        variant="contained"
                        onClick={ setIsCheckinMaked}
                    >
                    {'Check-in'}
                </Button>}
            </Grid>
        </Grid>
    );
};

export default ScreenSaver;
