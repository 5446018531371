import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Divider, Grid, Link, Tab, Tabs, Typography } from '@material-ui/core';
import UserContactDetails from '../components/user/UserContactDetails';
import UserDataManagement from '../components/user/UserDataManagement';
import ChevronRightIcon from '../icons/ChevronRight';
import PencilAltIcon from '../icons/PencilAlt';
import { User } from '../models/User';
import { useSelector } from "react-redux";
import { UserService } from '../services/UserService';
import { AppState } from "../store";
import { UserInfo } from "../models/UserInfo";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

const tabs = [
  { label: 'Details', value: 'details' }
];

const UserDetails: FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [currentTab, setCurrentTab] = useState<string>('details');
  const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
  const history = useNavigate();
  const { t } = useTranslation();
  var currentTheme = useTheme();

  const getUser = useCallback(async () => {
    try {
      if (userInfo) {
        let userId = window.location.href.split('userId=')[1] ?? '';
        var userService = new UserService();
        var user = await userService.findUser(userId, userInfo.tenantId);
        setUser(user);
      }
    } catch (err) {
      console.error(err);
      history('/500');
    }
  }, [userInfo]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  if (!user) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 2,
        [currentTheme.breakpoints.up('lg')]: {
          paddingLeft: '300px'
        }
      }}
    >
      <Container >
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {user.name} {user.surname}
            </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRightIcon fontSize="small" />}
              sx={{ mt: 1 }}
            >
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/"
                variant="subtitle2"
              >
                {t('DASHBOARD')}
              </Link>
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/users"
                variant="subtitle2"
              >
                {t('USERS')}
              </Link>
              <Typography
                color="textSecondary"
                variant="subtitle2"
              >
                {t('DETAILS')}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <Box sx={{ m: -1 }}>
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<PencilAltIcon fontSize="small" />}
                sx={{ m: 1 }}
                to={`/users/edit?userId=${user.id}`}
                variant="contained"
              >
                {t('EDIT')}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box sx={{ mt: 3 }}>
          {currentTab === 'details' && (
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={6}
                md={6}
                xl={6}
                xs={12}
              >
                <UserContactDetails
                  name={user.name}
                  surname={user.surname}
                  address={user.address}
                  country={user.country}
                  email={user.email}
                  phone={user.phone}
                  state={user.state}
                  city={user.city}
                  zipCode={user.zipCode}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xl={6}
                xs={12}
              >
                <UserDataManagement email={user.email} tenantId={userInfo?.tenantId ?? ''} />
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default UserDetails;
