import axios from 'axios';
import ServiceConfig from "../config/serviceConfig";
import { PaginationResult } from '../types/paginationResult';
import { Anchor, Level } from '../types/level';

export class LevelService {

    async findLevel(id: string): Promise<Level> {
        try {
            const response = await axios.get<Level>(ServiceConfig.serviceBaseUrl + "/FindLevelById", {
                params: {
                    id: id
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("findLevel error");
            }
        }
        catch (error) {
            throw new Error("findLevel error:" + error);
        }
    }

    async searchLevels(buildingId: string, pageNumber: number, pageSize: number, text: string): Promise<PaginationResult<Level>> {
        try {
            const response = await axios.get<PaginationResult<Level>>(ServiceConfig.serviceBaseUrl + "/SearchLevels", {
                params: {
                    buildingId: buildingId,
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                    text: text
                }
            });
            if (response.status === 200) {
                return response.data;

            }
            else {
                throw new Error("searchLevels error");
            }
        }
        catch (error) {
            throw new Error("searchLevels error:" + error);
        }
    }

    async upsertLevel(level: Level): Promise<boolean> {
        try {
            const response = await axios.post<Level>(ServiceConfig.serviceBaseUrl + "/UpsertLevel", level);
            if (response.status === 200) {
                return Boolean(response.data);
            }
            else {
                throw new Error("upsertLevel error");
            }
        }
        catch (error) {
            throw new Error("upsertLevel error:" + error);
        }
    }

    async upsertAnchors(tenantId: string, levelId: string, anchors: Anchor[]): Promise<boolean> {
        try {
            var request: any = {
                tenantId: tenantId,
                anchors: anchors,
                levelId: levelId
            }

            const response = await axios.post<Anchor[]>(ServiceConfig.serviceBaseUrl + "/UpsertAnchors", request);
            if (response.status === 200) {
                return Boolean(response.data);
            }
            else {
                throw new Error("upsertAnchors error");
            }
        }
        catch (error) {
            throw new Error("upsertAnchors error:" + error);
        }
    }

    async invalidateLevel(id: string): Promise<boolean> {
        try {
            const response = await axios.get(ServiceConfig.serviceBaseUrl + "/InvalidateLevel", {
                params: {
                    id: id
                }
            });
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("invalidateLevel error");
            }
        }
        catch (error) {
            throw new Error("invalidateLevel error:" + error);
        }
    }

    async getAnchors(tenantId: string, unitIds: string[]): Promise<Anchor[]> {
        try {

            var request = {
                tenantId: tenantId,
                unitIds: unitIds
            }

            const response = await axios.post<Anchor[]>(ServiceConfig.serviceBaseUrl + "/GetAnchors", request);
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new Error("findLevel error");
            }
        }
        catch (error) {
            throw new Error("findLevel error:" + error);
        }
    }

}