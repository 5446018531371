import { Button, Card, CardContent, Container, Grid, Typography, CardHeader, Box } from "@material-ui/core";
import WattUsage from "../components/smartdesk/WattUsage";
import SmartLedStrip from "../components/smartdesk/SmartLedStrip";
import HeightDesk from "../components/smartdesk/HeightDesk";
import { FC, useEffect, useState } from "react";
import { Reservation } from "../types/reservation";
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ScreenSaver from "../components/smartdesk/ScreenSaver";
import { Resource } from "../types/resource";
import { SmartDeskService } from "../services/SmartDeskService";
import { Session, SmartDeskCommand, SmartDeskStatus } from "../types/smartDeskStatus";
import SmartLamp from "../components/smartdesk/SmartLamp";
import { deepClone } from "../utils/common";
import useInterval from "../utils/useInterval";
import { NumberLiteralType } from "typescript";

const SmartDeskContainer: FC = () => {
    const { t } = useTranslation();
    const mobileDevice = useMediaQuery(useTheme().breakpoints.down('sm'));
    const apiKey = window.location.href?.split('externaldashboard=')[1]?.split('&')[0] ?? '';
    const smartDeskService = new SmartDeskService(apiKey);

    const [session, setSession] = useState<Session | null>();
    // const [reservation, setReservation] = useState<Reservation>();
    const [device, setDevice] = useState<Resource>();
    const [status, setStatus] = useState<SmartDeskCommand>({
        deskEngine: { height: 720 },
        ledStrip: { onOff: false, brightness: 0, effect: 0, rgbColor: "#0fffff" },
        smartLamp: { onOff: false, brightness: 0, white: 0 },
        smartSocket: { onOff: false },
        data: {
            smartLampEnergy: 0,
            stripLedEnergy: 0,
            smartSocketEnergy: 0
        }
    });


    const checkStatus = (async () => {
        console.log("new tick");
        if (device) {
            if (!session) {
                let currentSession = await smartDeskService.anySessioForDevice(device.id, device.tenantId);
                console.log("session", session);
                if (currentSession) {
                    setSession(currentSession);
                    console.log("session id", session);
                }
                else if (!currentSession) {
                    console.log("session stopped");
                    setSession(null);
                }
            }
            if (session) {
                let st = await smartDeskService.getStatus(session.user, device.tenantId);
                if (st) {
                    console.log("status received", st);
                    setStatus(deepClone(st));
                }
            }
        }
    });

    useInterval(checkStatus, 3000);

    const handleCheckIn = () => { };

    const checkOut = (async () => {
        if (device && session) {
            let result = await smartDeskService.stopSession(session.sessionId, device.tenantId);
            if (result) {
                setSession(null);
            }
        }
    });

    const sendCommand = (async (value: SmartDeskCommand) => {
        if (device) {
            await smartDeskService.setStatus(value, device.id);
        }
        setStatus(deepClone(value));
    });

    const ledSwitched = (async (value: boolean) => {
        status.ledStrip.onOff = value;
        console.log("status led", status);
        await sendCommand(status);
    });
    const smartLampSwitched = (async (value: boolean) => {
        status.smartLamp.onOff = value;
        console.log("status smart lamp", status);
        await sendCommand(status);
    });
    const setHeight = (async (value: number) => {
        console.log("status height", value);
        status.deskEngine.height = value;
        await sendCommand(status);
    });

    const ledBrightness = (async (value: number) => {
        console.log("status color led", value);
        status.ledStrip.brightness = value;
        await sendCommand(status);
    });
    const smartLampBrightness = (async (value: number) => {
        console.log("status brightness smart lamp", value);
        status.smartLamp.brightness = value;
        await sendCommand(status);
    });
    const smartLampWhite = (async (value: number) => {
        console.log("status white smart lamp", value);
        status.smartLamp.white = value;
        await sendCommand(status);
    });
    const ledColor = (async (value: string) => {
        console.log("status color led", value);
        status.ledStrip.rgbColor = value;
        await sendCommand(status);
    });

    useEffect(() => {
        (async () => {
            let deviceId = window.location.href?.split('deviceid=')[1]?.split('&')[0] ?? '';
            if (deviceId) {
                let device = await smartDeskService.findDevice(deviceId);
                if (device) {
                    setDevice(device);
                }
            }
        })();
    }, []);

    return (<>
        {!device && <>
            <Container maxWidth="lg">
                <Typography
                    color="textPrimary"
                    variant={mobileDevice ? 'h4' : 'h1'}
                >
                    {t('INTERNAL_SERVER_ERROR')}
                </Typography>
            </Container>
        </>
        }

        {!session && <ScreenSaver deviceId={device?.id} deviceName={device?.description} setIsCheckinMaked={handleCheckIn} />}

        {session && <>
            <Grid item
                xl={12}
                md={12}
                xs={12}
                sx={{ py: 2 }}>
                <Card>
                    <CardContent>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 0
                            }}>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {device?.description} - {session?.user}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item
                xl={12}
                md={12}
                xs={12}
                sx={{ py: 2 }}>
                <Grid
                    container
                    spacing={2}
                >
                    <SmartLedStrip
                        isSwitched={status.ledStrip.onOff}
                        brightness={status.ledStrip.brightness}
                        color={status.ledStrip.rgbColor}
                        setIsSwitched={ledSwitched}
                        setBrightness={ledBrightness}
                        setColor={ledColor}
                    />
                    <Grid
                        sx={{ py: 2 }}
                        item
                        md={6}
                        sm={6}
                        xs={12}
                    >

                        <HeightDesk height={status.deskEngine.height} setHeight={setHeight} />
                        <SmartLamp
                            isSwitched={status.smartLamp.onOff}
                            white={status.smartLamp.white}
                            brightness={status.smartLamp.brightness}
                            setIsSwitched={smartLampSwitched}
                            setBrightness={smartLampBrightness}
                            setWhite={smartLampWhite}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{ py: 2 }}
                    container
                    md={12}
                    sm={12}
                    xs={12}
                >
                    <WattUsage smartLampEnergy={status.data.smartLampEnergy} smartSocketEnergy={status.data.smartSocketEnergy} stripLedEnergy={status.data.stripLedEnergy} />
                    <Grid
                        sx={{ py: 0 }}
                        item
                        md={6}
                        sm={6}
                        xs={6}
                    >
                        <Card>
                            <CardContent>
                                <Button onClick={checkOut} sx={{width: '100%'}} size="large">CHECK OUT</Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                >
                </Grid>
            </Grid>
        </>}
    </>);
}

export default SmartDeskContainer;