import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Chart from 'react-apexcharts';
import { Box, Button, Card, Divider, Grid, Typography } from '@material-ui/core';
import ArrowRightIcon from '../../icons/ArrowRight';
import { DashboardService } from '../../services/DashboardService';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const ResourceNumberChart: FC = () => {
    const theme = useTheme();
    const history = useNavigate();
    const { t } = useTranslation();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const [numberOfResources, setNumberOfResources] = useState<number>();

    useEffect(() => {
        (async () => {
            if (userInfo) {
                var dashboardSerivice = new DashboardService();
                var res = await dashboardSerivice.getNumberOfResources(userInfo.tenantId, userInfo.roles);
                setNumberOfResources(res);
            }
        })();
    }, [])

    const chart = {
        options: {
            chart: {
                background: 'transparent',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            colors: ['#7783DB'],
            dataLabels: {
                enabled: false
            },
            grid: {
                show: false
            },
            stroke: {
                width: 3
            },
            theme: {
                mode: theme.palette.mode
            },
            tooltip: {
                enabled: false
            },
            xaxis: {
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                }
            },
            yaxis: {
                show: false
            }
        },
        series: [
            {
                data: [0, 60, 30, 60, 0, 30, 10, 30, 0]
            }
        ]
    };

    return (
        <Grid
            sx={{ py: 2 }}
            item
            md={4}
            sm={6}
            xs={12}
        >
            <Card>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 3
                    }}
                >
                    <div>
                        <Typography
                            color="textPrimary"
                            variant="subtitle2"
                        >
                            {t('RESOURCES')}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            sx={{ mt: 1 }}
                            variant="h5"
                        >
                            {numberOfResources}
                        </Typography>
                    </div>
                    <Chart
                        type="line"
                        width={120}
                        {...chart}
                    />
                </Box>
                <Divider />
                <Box
                    sx={{
                        px: 3,
                        py: 2
                    }}
                >
                    <Button
                        color="primary"
                        endIcon={<ArrowRightIcon fontSize="small" />}
                        variant="text"
                        onClick={() => history('/resources')}
                    >
                        {t('SEE_RESOURCES')}
                    </Button>
                </Box>
            </Card>
        </Grid>
    );
};

export default ResourceNumberChart;
