import type { FC } from 'react';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ChevronRightIcon from "../icons/ChevronRight";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const AiEngine: FC = () => {
    const currentTheme = useTheme();
    const history = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 2,
                    [currentTheme.breakpoints.up('lg')]: {
                        paddingLeft: '300px'
                    }
                }}
            >
                <Container>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {t('AI_ENGINE')}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    {t('DASHBOARD')}
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    {t('AI_ENGINE')}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Box mt={3}>
                            <Typography
                                align="center"
                                color="textSecondary"
                                sx={{ mt: 0.5 }}
                                variant="subtitle2"
                            >
                                {t('NO_DATA_IN_AIENGINE')}
                            </Typography>
                        </Box>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default AiEngine;
