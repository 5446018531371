import type { FC } from 'react';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Box,
    Breadcrumbs,
    Button,
    Container,
    Grid,
    Link,
    Step,
    StepLabel,
    Stepper,
    Typography
} from '@material-ui/core';
import ChevronRightIcon from '../icons/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import EventType from '../components/calendar/Steps/EventType';
import SingleEvent, { Step2 } from '../components/calendar/Steps/SingleEvent';
import PeriodicEvent from '../components/calendar/Steps/PeriodicEvent';
import AvailableResources from '../components/calendar/Steps/AvailableResources';
import { ResourceType, ResourceWithDetail } from '../types/resourceType';
import CompleteEvent from '../components/calendar/Steps/CompleteEvent';
import { Pattern, Reservation } from "../types/reservation";
import { AppState } from "../store";
import { useSelector } from "react-redux";
import { UserInfo } from "../models/UserInfo";
import { v4 as uuidv4 } from 'uuid';
import { ReservationService } from "../services/ReservationService";
import { useSnackbar } from "notistack";
import Loader from "../components/Loader";

const NewCalendarEvent: FC = (props) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useNavigate();
    const currentTheme = useTheme();
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);

    const [activeStep, setActiveStep] = useState(0);
    const [step1IsSingleSelected, setStep1IsSingleSelected] = useState<boolean>(true);
    const [step1SelectedResourceType, setStep1SelectedResourceType] = useState<ResourceType>();
    const [step2, setStep2] = useState<Step2>();
    const [step3SelectedResource, setStep3SelectedResource] = useState<ResourceWithDetail>();
    const [participants, setParticipants] = useState<string[]>([]);
    const [reservationName, setReservationName] = useState<string>();
    const [currentPattern, setCurrentPattern] = useState<Pattern>();

    const steps = [t('SELECT_TYPOLOGY'), t('SELECT_DATE'), t('SELECT_RESOURCE'), t('RESERVE')];
    const reservationService = new ReservationService();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === 0 && step1IsSingleSelected) {
            setCurrentPattern(undefined);
        }
        // if(activeStep === 0){
        //     setStep1SelectedResourceType(undefined);
        // }
        if (activeStep === 3) {

            if (step1IsSingleSelected) {
                setCurrentPattern(undefined);
            }

            let reservation: Reservation = {
                id: uuidv4(),
                title: reservationName || '',
                description: reservationName || '',
                tenantId: userInfo?.tenantId,
                isPeriodic: !step1IsSingleSelected,
                resourceId: step3SelectedResource?.id,
                timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
                dateTimeFrom: getDateTimeFrom(step2, step1IsSingleSelected),
                dateTimeTo: getDateTimeTo(step2, step1IsSingleSelected),
                isPublic: true,
                pattern: currentPattern,
                emails: participants?.map(participant => participant.toLowerCase()),
                editAllNewEvents: false,
            };

            (async () => {
                await reservationService.upsertReservation(reservation);

                enqueueSnackbar(t('RERSERVATION_CREATED'), {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });

                history('/calendar')
            })();


        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const isNextButtonIsDisabled =
        activeStep === 1 && step2 && step2.from > step2.to
        || activeStep === 2 && !step3SelectedResource
        || step1IsSingleSelected && step2 && step2.to < step2.from
        || step1IsSingleSelected && step2 && step2.from < new Date()
        || !step1IsSingleSelected && step2 && step2.to.valueOf() < step2.from.valueOf()
        || !step1IsSingleSelected && step2 && step2.from < new Date()
        || !step1IsSingleSelected && step2 && step2.to.valueOf() === step2.from.valueOf() && step2.toTime.valueOf() < step2.fromTime.valueOf()
        || !step1IsSingleSelected && step2 && currentPattern && new Date(currentPattern.toDate).getTime() - new Date(step2.from).getTime() > 31556952000
        || !step1IsSingleSelected && step2 && currentPattern && currentPattern.type === 'weekly' && currentPattern.daysOfWeek.length < 1
        || activeStep === 3 && participants.length < 1;

    return (<>
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 2,
                [currentTheme.breakpoints.up('lg')]: {
                    paddingLeft: '300px'
                }
            }}
        >
            <Container>
                {header(t)}
                <Box mt={3}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <>
                            <Loader isLoading={true}/>
                        </>
                    ) : (
                        <>
                            {activeStep === 0 &&
                            <EventType
                                step1IsSingleSelectedProp={step1IsSingleSelected}
                                setStep1IsSingleSelectedProp={setStep1IsSingleSelected}
                                setStep1SelectedResourceTypeProp={setStep1SelectedResourceType}/>}
                            {activeStep === 1 && step1IsSingleSelected &&
                            <SingleEvent
                                step2Prop={step2}
                                setStep2Prop={setStep2}/>}
                            {activeStep === 1 && !step1IsSingleSelected &&
                            <PeriodicEvent
                                step2Prop={step2}
                                setStep2Prop={setStep2}
                                setCurrentPattern={setCurrentPattern}
                                currentPattern={currentPattern}/>}
                            {activeStep === 2 && step2 &&
                            <AvailableResources
                                step1IsSingle={step1IsSingleSelected}
                                step1SelectedResourceType={step1SelectedResourceType}
                                step2={step2}
                                setStep3SelectedResource={setStep3SelectedResource}
                                step3SelectedResource={step3SelectedResource}
                                currentPattern={currentPattern}/>}
                            {activeStep === 3 && <CompleteEvent
                                step1IsSingleSelectedProp={step1IsSingleSelected}
                                step2Prop={step2}
                                step3SelectedResource={step3SelectedResource}
                                setParticipants={setParticipants}
                                participants={participants}
                                setReservationName={setReservationName}
                                reservationName={reservationName || ''}/>}
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="primary"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ m: 1 }}
                                    variant="contained"
                                >
                                    {t('BACK')}
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }}/>
                                <Button
                                    disabled={isNextButtonIsDisabled}
                                    onClick={handleNext}
                                    sx={{ m: 1 }}
                                    variant="contained">
                                    {activeStep === steps.length - 1 ? t('RESERVE') : t('GO')}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Container>
        </Box>
    </>);
}

export default NewCalendarEvent;

function getDateTimeFrom(step2: Step2 | undefined, step1IsSingleSelected: boolean) {
    if (step1IsSingleSelected) {
        return step2?.from || new Date();
    }
    return step2
        ? new Date(new Date(
            new Date(step2.from).setHours(0, 0, 0, 0))
            .setHours(new Date(step2.fromTime).getHours(), new Date(step2.fromTime).getMinutes(), new Date(step2.fromTime).getSeconds(), 0))
        : new Date();
}

function getDateTimeTo(step2: Step2 | undefined, step1IsSingleSelected: boolean) {
    if (step1IsSingleSelected) {
        return step2?.to || new Date();
    }
    return step2
        ? new Date(new Date(
            new Date(step2.from).setHours(0, 0, 0, 0))
            .setHours(new Date(step2.toTime).getHours(), new Date(step2.toTime).getMinutes(), new Date(step2.toTime).getSeconds(), 0))
        : new Date();
}

function header(t) {
    return <Grid
        container
        justifyContent="space-between"
        spacing={3}
    >
        <Grid item>
            <Typography
                color="textPrimary"
                variant="h5"
            >
                {t('NEW_EVENT')}
            </Typography>
            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small"/>}
                sx={{ mt: 1 }}
            >
                <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/"
                    variant="subtitle2"
                >
                    {t('DASHBOARD')}
                </Link>
                <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/calendar"
                    variant="subtitle2"
                >
                    {t('CALENDAR')}
                </Link>
                <Typography
                    color="textSecondary"
                    variant="subtitle2"
                >
                    {t('NEW_EVENT')}
                </Typography>
            </Breadcrumbs>
        </Grid>
    </Grid>;
}

