import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      // Quando si modifica il nome di un ruolo, bisogna aggiornare anche il suo complementare
      'Building contributor': 'building_contributor',
      'Planner admin': 'planner_admin',
      'Planner contributor': 'planner_contributor',
      'Resource contributor': 'resource_contributor',
      'User manager': 'user_manager',
      'building_contributor': 'Building contributor',
      'planner_admin': 'Planner admin',
      'planner_contributor': 'Planner contributor',
      'resource_contributor': 'Resource contributor',
      'user_manager': 'User manager',
      'ROLE_DESCRIPTION_BUILDING_CONTRIBUTOR': 'Building contributor: Can view, create, edit and delete buildings.',
      'ROLE_DESCRIPTION_PLANNER_ADMIN': 'Planner admin: Can view, create, edit, and delete your tenant\'s full resource schedule.',
      'ROLE_DESCRIPTION_PLANNER_CONTRIBUTOR': 'Planner contributor: Can view, create, edit and delete created schedules.',
      'ROLE_DESCRIPTION_RESOURCE_CONTRIBUTOR': 'Resource contributor: It can view, create, modify, delete and assign resources for each building belonging to its tenant.',
      'ROLE_DESCRIPTION_USER_MANAGER': 'User manager: Can view, create, edit, delete and manage the roles of users belonging to their tenant.',
      // ----------------------------------------------------------------------------
      // Quando si modifica questa sezione, bisogna aggiornare anche il suo complementare
      'daily': 'Daily',
      'weekly': 'Weekly',
      'monthly': 'Monthly',
      'Daily': 'daily',
      'Weekly': 'weekly',
      'Monthly': 'monthly',
      // ----------------------------------------------------------------------------
      // Days of week
      'MON': 'MON',
      'TUE': 'TUE',
      'WED': 'WED',
      'THU': 'THU',
      'FRI': 'FRI',
      'SAT': 'SAT',
      'SUN': 'SUN',
      // ----------------------------------------------------------------------------
      'ACCESSES': 'Accesses',
      'ACCESSES_ASSIGNED_SUCCESSFULLY': 'Accesses assigned successfully!',
      'ACTIONS': 'Actions',
      'ADDRESS': 'Address',
      'ADD': 'Add',
      'ADD_ANCHOR_MESSAGE_SUCCESSFULLY': 'The resource has been placed in the center of the map. You can move it by clicking on it',
      'ADD_BUILDING': 'Add building',
      'ADD_LEVEL': 'Add level',
      'ADD_NAME_RESERVATION': 'Enter the name of your reservation',
      'ADD_RESERVATION': 'Add reservation',
      'ADD_RESOURCE': 'Add resourcee',
      'ADD_RESOURCE_MARKER': 'Add resource marker',
      'ADD_RESOURCE_TYPE': 'Add resource type',
      'ADD_UNIT_DETAIL': 'Unit details',
      'ADD_USER': 'Add user',
      'AGENDA': 'Agenda',
      'AI_ENGINE': 'AI Engine',
      'ALL': 'All',
      'ALTERNATIVE_NAME': 'Alternative name',
      'ASSIGN': 'Assign',
      'AUTHORIZATION_REQUIRED': 'Authorization required',
      'AUTHORIZATION_REQUIRED_DESCRIPTION': 'You either tried some shady route or you came here by mistake.Whichever it is, try using the navigation.',
      'AVATAR': 'Avatar',
      'BACK': 'Back',
      'BACK_TO_DASHBOARD': 'Back to dashboard',
      'BROWSE': 'browse',
      'BUILDING': 'Building',
      'BUILDING_LIST': 'Building list',
      'BUILDING_ADD': 'Add building',
      'BUILDING_ADDED': 'Building added',
      'BUILDING_DETAILS': 'Building details',
      'BUILDING_EDIT': 'Edit building',
      'BUILDING_DELETED': 'Building removed',
      'BUILDING_UPDATED': 'Building edited',
      'BUILDINGS': 'Buildings',
      'CALENDAR': 'Calendar',
      'CANCEL': 'Cancel',
      'CAPACITY': 'Capacity',
      'CAPACITY_PERCENTAGE': 'Capacity percentage',
      'CHANGE_TENANT': 'Change Tenant',
      'CHECKIN_COMPLETED': 'Check-in completed',
      'CHECKIN_NOT_AVAILABLE': 'Check-in not yet available',
      'CITY': 'City',
      'CODE': 'Code',
      'CODE_REQUIRED': 'Code is required',
      'CONFIRM': 'Confirm',
      'CONFIRM_CHECKIN': 'Do you want to check-in for the following reservation?',
      'COUNTRY': 'Country',
      'CREATED': 'Created',
      'CULTURE': 'en',
      'DASHBOARD': 'Dashboard',
      'DATA_MANAGEMENT': 'Data Management',
      'DATE_PICKER_FULL_DATE': 'MM/dd/yyyy HH:mm',
      'DATE_PICKER_ONLY_DATE': 'MM/dd/yyyy',
      'DAY': 'Day',
      'DELETE': 'Delete',
      'DELETE_BUILDING_DESCRIPTION': 'Remove this building\'s chart if he requested that, if not please be aware that what has been deleted can never brought back',
      'DELETE_RESOURCE_DESCRIPTION': 'Attention, removing the resource will eliminate all accesses to it and all events subsequent to today\'s date',
      'DELETE_RESOURCE_TYPE_DESCRIPTION': 'Remove this resource type\'s chart if he requested that, if not please be aware that what has been deleted can never brought back',
      'DELETE_USER_DESCRIPTION': 'Remove this user\'s chart if he requested that, if not please be aware that what has been deleted can never brought back',
      'DESCRIPTION': 'Description',
      'DESCRIPTION_500': 'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
      'DESCRIPTION_401': 'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
      'DESCRIPTION_INIT_ERROR': 'Application loading error',
      'DESCRIPTION_INIT_USERINFO': 'Loading user profile',
      'DESCRIPTION_INIT_INFO_NOT_OFFLINE': 'Loading application settings',
      'DESCRIPTION_INIT_INFO': 'Loading application',
      'DESK_LAMP': 'Desk lamp',
      'DETAILS': 'Details',
      'DESK_HEIGHT': 'Desk height',
      'DOCUMENTS': 'Documents',
      'DOWNLOAD': 'Download',
      'DROP_FILE': 'Drop file',
      'DO_YOU_WANT_TO': 'Do you want to',
      'DO_YOU_WANT_DELETE': 'Do you want to delete?',
      'DUE_DATE': 'Due',
      'DUPLICATE': 'Duplicate',
      'EDIT': 'Edit',
      'EDIT_LEVEL': 'Edit level',
      'EDIT_RESERVATION': 'Edit reservation',
      'EDIT_RESOURCE': 'Edit resource',
      'EFFECTIVE_CAPACITY': 'Effective capacity',
      'EFFECTIVE_CAPACITY_LESS_CAPACITY': 'The capacity cannot be less than zero and the available capacity cannot be greater than the capacity.',
      'EMAIL_ADDRESS': 'Email address',
      'END_DATE': 'End',
      'END_HOUR': 'End hour',
      'ENGLISH': 'English',
      'ERROR_EDIT_RESERVATION': 'Error on updating reservation',
      'EVERY': 'Every',
      'FILTER_AVAILABLE_RESOURCES': 'There are no resources for the selected dates and filters. Try choosing a new date or changing the filters above.',
      'FIRST_DAY_OF_WEEK': '0',
      'FOLLOWING_ONES': 'Following ones',
      'GO': 'Go',
      'HERES_THE_RESERVATIONS': 'Here\'s the reservations',
      'IMPACT_ON_CAPACITY': 'Can be positioned on the map',
      'INTENSITY': 'Intensity',
      'INTERNAL_SERVER_ERROR': 'Server error',
      'INTERNAL_SERVER_ERROR_DESCRIPTION': 'Sorry, something went wrong. Try again a little later or ask to your sistem admin.',
      'INFO': 'Info',
      'IS_OUTDOOR': 'Is outdoor',
      'IS_PERIODIC': 'Is periodic',
      'IS_PERIODIC_WHITESPACE': ' periodic ',
      'ITALIAN': 'Italian',
      'LAMP': 'Lamp',
      'LED_STRIP': 'Strip LED',
      'LESS_THEN_LAST_MONTH': 'Less then last month',
      'LESS_THEN_LAST_WEEK': 'Less then last week',
      'LEVEL': 'Level',
      'LEVEL_CAPACITY_PERCENTAGE': 'Level capacity percentage',
      'LEVEL_DETAIL': 'Level detail',
      'LEVEL_DETAILS': 'Level details',
      'LEVELS': 'Levels',
      'LEVEL_LIST_IN_BUILDING': 'Level list in building',
      'LEVEL_NUMBER': 'Level number',
      'LOCALIZE': 'Localize',
      'LOGOUT': 'Logout',
      'MAP': 'Map',
      'MAXIMUM_USERS_RESOURCE': 'The maximum number of users that can be selected for the chosen resource has been reached.',
      'MY_IGLOO': 'My Smart desk',
      'MINIMUM_EVENT_MINUTES' : 'Minimum timespan event (min)' ,
      'MONTH': 'Month',
      'MORE_THEN_LAST_MONTH': 'More then last month',
      'MORE_THEN_LAST_WEEK': 'More then last week',
      'NAME': 'Name',
      'NAME_ALREADY_IN_USE': 'Resource name already in use.',
      'NAME_REQUIRED': 'Name is required',
      'NEXT': 'Next',
      'NEW_EVENT': 'New event',
      'NO_DATA_IN_AIENGINE': 'There is not enough data to make predictions.',
      'NO_DATA_IN_THE_PREVIOUS_MONTH': 'No data in the previous month',
      'NO_DATA_IN_THE_PREVIOUS_WEEK': 'No data in the previous week',
      'OF': 'of',
      'ONLY_THIS': 'Only this',
      'OPTIONAL_NOTE': 'Optional note',
      'PARTICIPANTS': 'Participants',
      'PERIOD': 'Period',
      'PHONE_NUMBER': 'Phone number',
      'POSITION': 'Position',
      'PREV': 'Prev',
      'PROFILE': 'Profile',
      'REMOVE': 'Remove',
      'REMOVE_ALL': 'Remove all',
      'REPEAT': 'Repeat',
      'RERSERVATION_CREATED': 'Reservation created',
      'RERSERVATION_DELETED': 'Reservation deleted',
      'RERSERVATION_DELETED_MESSAGE_FINAL': 'only this event or also the following ones?',
      'RERSERVATION_UPDATED': 'Reservation updated',
      'RERSERVATIONS_BY_RESOURCE_TYPE': 'Reservations by resource type',
      'RESERVATIONS_IN_THIS_MONTH': 'Reservations in this month',
      'RESERVATIONS_IN_THE_LAST_7_DAYS': 'Reservations in the last 7 days',
      'RESERVE': 'Reserve',
      'RESERVED': 'The resource has been reserved',
      'RESOURCE': 'Resource',
      'RESOURCES': 'Resources',
      'RESOURCE_ADDED': 'Resource added',
      'RESOURCE_CREATED': 'Resource created',
      'RESOURCE_DELETED': 'Resource deleted',
      'RESOURCE_DETAILS': 'Resource details',
      'RESOURCE_LIST': 'Resource list',
      'RESOURCE_NAME': 'Resource name',
      'RESOURCE_TYPE': 'Resource type',
      'RESOURCE_BY_TYPE': 'Resource by type',
      'RESOURCE_TYPE_ADDED': 'Resource type added',
      'RESOURCE_TYPE_DELETED': 'Resource type deleted',
      'RESOURCE_TYPE_DETAILS': 'Resource type details',
      'RESOURCE_TYPE_EXIST': 'Resource type already exist',
      'RESOURCE_TYPE_LIST': 'Resource type list',
      'RESOURCE_TYPE_UPDATED': 'Resource type updated',
      'RESOURCE_TYPES': 'Resource types',
      'RESOURCE_UPDATED': 'Resource updated',
      'ROLES': 'Roles',
      'ROLES_REQUIRED': 'Roles *',
      'ROLES_DESCRIPTION': 'Roles identify the actions that users can perform within the app.',
      'ROWS_PER_PAGE': 'Rows per page',
      'S_LOWERCASE': 's',
      'SHOW_ON_MAP': "Show on map",
      'SHORT_NAME': 'Short name',
      'SEARCH_BUILDINGS': 'Search buildings',
      'SEARCH_RESOURCES': 'Search resources',
      'SEARCH_RESOURCE_TYPES': 'Search resource type',
      'SEARCH_USERS': 'Search users',
      'SEE_ALL_RESOURCE_TYPES': 'See all resource types',
      'SEE_RESOURCES': 'See resources',
      'SELECT_AVATAR': 'Select avatar',
      'SELECT_DATE': 'Select dates',
      'SELECT_FILE': 'Select file',
      'SELECT_FILES': 'Select files',
      'SELECT_LEVELS': 'Select levels',
      'SELECT_TYPOLOGY': 'Select typology',
      'SELECT_RESOURCE': 'Select resource',
      'SELECTED_RESOURCE': 'Selected resource',
      'SINGLE': 'Single',
      'SINGLE_WHITESPACE': ' single ',
      'SMART_DESK': 'Smart desk',
      'SMART_DISPLAY': 'Smart display',
      'SMART_LED_STRIP': 'Smart Strip LED',
      'START_DATE': 'Start',
      'START_HOUR': 'Start hour',
      'STATE': 'State',
      'SURNAME': 'Surname',
      'TENANT': 'Tenant',
      'THROUGH_YOUR_MACHINE': 'your device',
      'TIME_FORMAT': 'HH:mm',
      'TITLE': 'Title',
      'TITLE_500': '500: Internal Server Error',
      'TITLE_401': 'Authorization required',
      'TODAY': 'Today',
      'TO_DELETE': 'delete',
      'TO_EDIT': 'Modify',
      'TYPE': 'Type',
      'TYPE_OF_RESOURCE': 'Type of resource',
      'TYPOLOGY_OF_RESOURCE': 'Typology of resource',
      'UNIT': 'Room',
      'UNIT_FILL': 'The maximum room capacity for this resource has been reached',
      'UPDATED': 'Updated',
      'UPDATE_BUILDING': 'Update building',
      'UPDATE_LEVEL': 'Update level',
      'UPDATE_RESOURCE': 'Update resource',
      'UPDATE_RESOURCE_TYPE': 'Update resource type',
      'UPDATE_USER': 'Update user',
      'USER': 'User',
      'USER_DETAILS': 'User detils',
      'USER_EXIST': 'User already exist',
      'USER_LIST': 'User list',
      'USERS_REQUIRED': 'Users *',
      'USERS': 'Users',
      'VALIDATION_CALENDAR_START_DATE': '* Due date cannot be less than Start date',
      'VALIDATION_CALENDAR_YEAR': '* The maximum Due date is one year',
      'VALIDATION_CALENDAR_WEEK': '* The selection of days of week is mandatory',
      'WHAT': 'What',
      'WEEK': 'Week',
      'WHEN': 'When',
      'WHERE': 'Where',
      'WHITE': 'White',
      'YES': 'Yes',
      'YOUR_MESSAGE': 'Il tuo messaggio',
      'YOUR_NEXT_RESERVATION': 'Your next reservation',
      'ZIP_CODE': 'Zip Code'
    }
  },
  it: {
    translation: {
      // Quando si modifica il nome di un ruolo, bisogna aggiornare anche il suo complementare
      'Collaboratore degli edifici': 'building_contributor',
      'Amministratore delle pianificazioni': 'planner_admin',
      'Collaboratore delle pianificazioni': 'planner_contributor',
      'Collaboratore delle risorse': 'resource_contributor',
      'Gestore degli utenti': 'user_manager',
      'building_contributor': 'Collaboratore degli edifici',
      'planner_admin': 'Amministratore delle pianificazioni',
      'planner_contributor': 'Collaboratore delle pianificazioni',
      'resource_contributor': 'Collaboratore delle risorse',
      'user_manager': 'Gestore degli utenti',
      'ROLE_DESCRIPTION_BUILDING_CONTRIBUTOR': 'Collaboratore degli edifici: Può visualizzare, creare, modificare ed eliminare gli edifici.',
      'ROLE_DESCRIPTION_PLANNER_ADMIN': 'Amministratore delle pianificazioni: Può visualizzare, creare, modificare ed eliminare la pianificazione completa delle risorse del proprio tenant.',
      'ROLE_DESCRIPTION_PLANNER_CONTRIBUTOR': 'Collaboratore delle pianificazioni: Può visualizzare, creare, modificare ed eliminare le pianificazioni create.',
      'ROLE_DESCRIPTION_RESOURCE_CONTRIBUTOR': 'Collaboratore delle risorse: Può visualizzare, creare, modificare, eliminare ed assegnare risorse per ogni edificio appartenente al proprio tenant.',
      'ROLE_DESCRIPTION_USER_MANAGER': 'Gestore degli utenti: Può visualizzare, creare, modificare, eliminare e gestire i ruoli degli utenti appartenenti al proprio tenant.',
      // ----------------------------------------------------------------------------
      // Quando si modifica questa sezione, bisogna aggiornare anche il suo complementare
      'daily': 'Giornaliero',
      'weekly': 'Settimanale',
      'monthly': 'Mensile',
      'Giornaliero': 'daily',
      'Settimanale': 'weekly',
      'Mensile': 'monthly',
      // ----------------------------------------------------------------------------
      // Days of week
      'MON': 'LUN',
      'TUE': 'MAR',
      'WED': 'MER',
      'THU': 'GIO',
      'FRI': 'VEN',
      'SAT': 'SAB',
      'SUN': 'DOM',
      // ----------------------------------------------------------------------------
      'ACCESSES': 'Accessi',
      'ACCESSES_ASSIGNED_SUCCESSFULLY': 'Gli accessi alla risorsa sono stati aggiornati!',
      'ACTIONS': 'Azioni',
      'ADDRESS': 'Indirizzo',
      'ADD': 'Aggiungi',
      'ADD_ANCHOR_MESSAGE_SUCCESSFULLY': 'La risorsa è stata posizionata al centro della mappa. Puoi spostarlo facendo clic su di esso',
      'ADD_BUILDING': 'Aggiungi edificio',
      'ADD_LEVEL': 'Aggiungi piano',
      'ADD_NAME_RESERVATION': 'Inserisci il nome della tua prenotazione',
      'ADD_RESERVATION': 'Aggiungi prenotazione',
      'ADD_RESOURCE': 'Aggiungi risorsa',
      'ADD_RESOURCE_MARKER': 'Aggiungi marker risorsa',
      'ADD_RESOURCE_TYPE': 'Aggiungi tipologia di risorsa',
      'ADD_UNIT_DETAIL': 'Dettagli ambiente',
      'ADD_USER': 'Aggiungi utente',
      'AGENDA': 'Agenda',
      'AI_ENGINE': 'AI Engine',
      'ALL': 'Tutti',
      'ALTERNATIVE_NAME': 'Nome alternativo',
      'ASSIGN': 'Assegna',
      'AUTHORIZATION_REQUIRED': 'Autorizzazione negata',
      'AUTHORIZATION_REQUIRED_DESCRIPTION': 'Hai provato ad accedere ad un percorso per il quale non possiedi le autorizzazioni. Prova a contattare il tuo amministratore di sistema per ulteriori info.',
      'AVATAR': 'Avatar',
      'BACK': 'Indietro',
      'BACK_TO_DASHBOARD': 'Ritorna alla dashoboard',
      'BROWSE': 'sfoglia',
      'BUILDING': 'Edificio',
      'BUILDING_ADD': 'Aggiungi Edificio',
      'BUILDING_ADDED': 'Edificio aggiunto',
      'BUILDING_EDIT': 'Modifica Edificio',
      'BUILDING_DELETED': 'Edificio eliminato',
      'BUILDING_DETAILS': 'Dettagli edificio',
      'BUILDING_LIST': 'Lista Edifici',
      'BUILDING_UPDATED': 'Edificio modificato',
      'BUILDINGS': 'Edifici',
      'CALENDAR': 'Calendario',
      'CANCEL': 'Annulla',
      'CAPACITY': 'Capacità',
      'CAPACITY_PERCENTAGE': 'Percentuale capacità',
      'CHANGE_TENANT': 'Cambio Tenant',
      'CHECKIN_COMPLETED': 'Check-in effettuato',
      'CHECKIN_NOT_AVAILABLE': 'Check-In non ancora disponibile',
      'CITY': 'Città',
      'CODE': 'Codice',
      'CODE_REQUIRED': 'Codice è obbligatorio',
      'CONFIRM': 'Conferma',
      'CONFIRM_CHECKIN': ' Vuoi effettuare il Check-in per la seguente prenotazione?',
      'COUNTRY': 'Stato',
      'CREATED': 'Aggiunto',
      'CULTURE': 'it',
      'DASHBOARD': 'Dashboard',
      'DATA_MANAGEMENT': 'Gestione dati',
      'DATE_PICKER_FULL_DATE': 'dd/MM/yyyy HH:mm',
      'DATE_PICKER_ONLY_DATE': 'dd/MM/yyyy',
      'DAY': 'Giorno',
      'DELETE': 'Elimina',
      'DELETE_LEVEL_DESCRIPTION': 'Rimuovi il grafico di questo livello se lo richiede, in caso contrario tieni presente che ciò che è stato eliminato non potrà mai essere ripristinato',
      'DELETE_BUILDING_DESCRIPTION': 'Attenzione, la rimozione dell\'edificio eliminerà la pianimetria e i piani collegati ad esso',
      'DELETE_RESOURCE_DESCRIPTION': 'Attenzione, la rimozione della risorsa eliminerà tutti gli accessi ad essa e tutti gli eventi successivi alla data odierna.',
      'DELETE_RESOURCE_TYPE_DESCRIPTION': 'Attenzione, la rimozione di una tipologia di risorsa non permetterà di aggiugere risorse di questo tipo ma rimarrà comunque collegata alle risorse esistenti',
      'DELETE_USER_DESCRIPTION': 'Rimuovi il grafico di questo utente se lo richiede, in caso contrario tieni presente che ciò che è stato eliminato non potrà mai essere ripristinato',
      'DESCRIPTION': 'Descrizione',
      'DESCRIPTION_500': 'Hai tentato un percorso ombroso o sei venuto qui per errore. Qualunque sia, prova a utilizzare la navigazione.',
      'DESCRIPTION_401': 'Hai tentato un percorso ombroso o sei venuto qui per errore. Qualunque sia, prova a utilizzare la navigazione.',
      'DESCRIPTION_INIT_ERROR': 'Errore di caricamento dell\'applicazione',
      'DESCRIPTION_INIT_USERINFO': 'Caricamento del profilo utente',
      'DESCRIPTION_INIT_INFO_NOT_OFFLINE': 'Caricamento delle impostazioni dell\'applicazione',
      'DESCRIPTION_INIT_INFO': 'Caricamento dell\'applicazione',
      'DESK_LAMP': 'Lampada scrivania',
      'DESK_HEIGHT': 'Altezza scrivania',
      'DETAILS': 'Dettagli',
      'DOCUMENTS': 'Documenti',
      'DOWNLOAD': 'Scarica',
      'DO_YOU_WANT_TO': 'Sei sicuro di voler',
      'DO_YOU_WANT_DELETE': 'Sei sicuro di voler eliminare?',
      'DROP_FILE': 'Trascina i file',
      'DUE_DATE': 'Scadenza',
      'DUPLICATE': 'Duplica',
      'EDIT': 'Modifica',
      'EDIT_LEVEL': 'Modifica piano',
      'EDIT_RESERVATION': 'Modifica prenotazione',
      'EDIT_RESOURCE': 'Modifica risorsa',
      'EFFECTIVE_CAPACITY': 'Capacità disponibile',
      'EFFECTIVE_CAPACITY_LESS_CAPACITY': 'La capacità non può essere minore di zero e la capacità disponbile non può essere superiore della capacità.',
      'EMAIL_ADDRESS': 'Indirizzo email',
      'END_DATE': 'Data di fine',
      'END_HOUR': 'Ora di fine',
      'ENGLISH': 'Inglese',
      'ERROR_EDIT_RESERVATION': 'Errore durante la modifica della prenotazione',
      'EVERY': 'Ogni',
      'FILTER_AVAILABLE_RESOURCES': 'Non sono presenti risorse per le date ed i filtri selezionati. Prova a scegliere una nuova data o a cambiare i filtri sovrastanti.',
      'FIRST_DAY_OF_WEEK': '1',
      'FOLLOWING_ONES': 'Tutti i seguenti',
      'GO': 'Avanti',
      'HERES_THE_RESERVATIONS': 'Ecco le prenotazioni',
      'IMPACT_ON_CAPACITY': 'Posizionabile in mappa',
      'IMPACT_ON_CAPACITY_DESCRIPTION': 'Se attivo, le risorse di questa tipologia avranno un impatto sulla capacità dell\'area.',
      'INTERNAL_SERVER_ERROR': 'Errore del server',
      'INTERNAL_SERVER_ERROR_DESCRIPTION': 'Scusaci, qualcosa è andato storto. Riprova più tardi o chiedi al tuo amministratore di sistema.',
      'INTENSITY': 'Intensità',
      'INFO': 'Info',
      'IS_OUTDOOR': 'Esterno',
      'IS_PERIODIC': 'Periodico',
      'IS_PERIODIC_WHITESPACE': ' periodico ',
      'ITALIAN': 'Italiano',
      'LAMP': 'Lampada',
      'LED_STRIP': 'Striscia LED',
      'LESS_THEN_LAST_MONTH': 'In meno rispetto all\'ultimo mese',
      'LESS_THEN_LAST_WEEK': 'In meno rispetto all\'ultima settimana',
      'LEVEL': 'Piano',
      'LEVEL_CAPACITY_PERCENTAGE': 'Percentuale capacità dell\'area',
      'LEVEL_ADD': 'Aggiungi Piano',
      'LEVEL_EDIT': 'Modifica Piano',
      'LEVEL_DELETED': 'Piano eliminato',
      'LEVEL_DETAIL': 'Dettaglio piano',
      'LEVEL_DETAILS': 'Dettagli piano',
      'LEVELS': 'Piani',
      'LEVEL_LIST_IN_BUILDING': 'Lista dei piani nell\'edificio',
      'LEVEL_NUMBER': 'Livello',
      'LOCALIZE': 'Individua',
      'LOGOUT': 'Esci',
      'MAP': 'Mappa',
      'MY_IGLOO': 'La mia Smart desk',
      'MAXIMUM_USERS_RESOURCE': 'É stato raggiunto il massino numero di utenti selezionabili per la risorsa scelta.',
      'MINIMUM_EVENT_MINUTES' : 'Durata minima evento (min)' ,
      'MONTH': 'Mese',
      'MORE_THEN_LAST_MONTH': 'In più rispetto all\'ultimo mese',
      'MORE_THEN_LAST_WEEK': 'In più rispetto all\'ultima settimana',
      'NAME': 'Nome',
      'NAME_ALREADY_IN_USE': 'Il nome di questa risorsa è già in uso.',
      'NAME_REQUIRED': 'Nome obbligatorio',
      'NEXT': 'Prossimo',
      'NEW_EVENT': 'Nuova prenotazione',
      'NO_DATA_IN_AIENGINE': 'Non ci sono abbastanza dati per effettuare le previsioni.',
      'NO_DATA_IN_THE_PREVIOUS_MONTH': 'Nessun dato nel mese precedente',
      'NO_DATA_IN_THE_PREVIOUS_WEEK': 'Nessun dato nella settimana precedente',
      'OF': 'di',
      'ONLY_THIS': 'Solo questo',
      'OPTIONAL_NOTE': 'Inserisci una nota opzionale',
      'PARTICIPANTS': 'Partecipanti',
      'PERIOD': 'Periodo',
      'PHONE_NUMBER': 'Cellulare',
      'POSITION': 'Posizione',
      'PREV': 'Precedente',
      'PROFILE': 'Profilo',
      'REMOVE': 'Rimuovi',
      'REMOVE_ALL': 'Rimuovi tutti',
      'REPEAT': 'Ripeti',
      'RERSERVATION_CREATED': 'Prenotazione creata',
      'RERSERVATION_DELETED': 'Prenotazione eliminata',
      'RERSERVATION_DELETED_MESSAGE_FINAL': 'solo questa prenotazione o anche le successive?',
      'RERSERVATION_UPDATED': 'Prenotazione modificata',
      'RERSERVATIONS_BY_RESOURCE_TYPE': 'Prenotazioni per tipi di risorse',
      'RESERVATIONS_IN_THIS_MONTH': 'Prenotazioni in questo mese',
      'RESERVATIONS_IN_THE_LAST_7_DAYS': 'Prenotazioni negli ultimi 7 giorni',
      'RESERVE': 'Prenota',
      'RESERVED': 'La risorsa è stata prenotata',
      'RESOURCE': 'Risorsa',
      'RESOURCES': 'Risorse',
      'RESOURCE_ADDED': 'Risorsa aggiunta',
      'RESOURCE_CREATED': 'Risorsa creata',
      'RESOURCE_DELETED': 'Risorsa eliminata',
      'RESOURCE_DETAILS': 'Dettagli risorsa',
      'RESOURCE_LIST': 'Lista risorse',
      'RESOURCE_NAME': 'Nome risorsa',
      'RESOURCE_TYPE': 'Tipi di risorse',
      'RESOURCE_BY_TYPE': 'Risorse per tipo',
      'RESOURCE_TYPE_ADDED': 'Tipo di risorse aggiunto',
      'RESOURCE_TYPE_DETAILS': 'Dettagli sul tipo di risorse',
      'RESOURCE_TYPE_DELETED': 'Tipo di risorse eliminato',
      'RESOURCE_TYPE_EXIST': 'Tipo di Risorsa già esistente',
      'RESOURCE_TYPE_LIST': 'Lista dei tipi di risorse',
      'RESOURCE_TYPE_UPDATED': 'Tipo di risorse modificata',
      'RESOURCE_TYPES': 'Tipi di risorse',
      'RESOURCE_UPDATED': 'Risorsa modificata',
      'ROLES': 'Ruoli',
      'ROLES_REQUIRED': 'Ruoli *',
      'ROLES_DESCRIPTION': 'I ruoli identificano le azioni che gli utenti possono eseguire all\'interno dell\'app.',
      'ROWS_PER_PAGE': 'Righe per pagina',
      'S_LOWERCASE': 's',
      'SHORT_NAME': 'Nome abbreviato',
      'SEARCH_BUILDINGS': 'Cerca edifici',
      'SEARCH_LEVELS': 'Cerca piani',
      'SEARCH_RESOURCES': 'Cerca risorse',
      'SEARCH_RESOURCE_TYPES': 'Cerca tipi di risorse',
      'SEARCH_USERS': 'Cerca utenti',
      'SEE_ALL_RESOURCE_TYPES': 'Vedi tutti i tipi di risorse',
      'SEE_RESOURCES': 'Vedi risorse',
      'SHOW_ON_MAP': "Visualizza sulla mappa",
      'SELECT_AVATAR': 'Seleziona avatar',
      'SELECT_DATE': 'Seleziona le date',
      'SELECT_FILE': 'Seleziona file',
      'SELECT_FILES': 'Seleziona più file',
      'SELECT_LEVELS': 'Seleziona piani',
      'SELECT_TYPOLOGY': 'Seleziona tipologia',
      'SELECT_RESOURCE': 'Seleziona risorsa',
      'SELECTED_RESOURCE': 'Risorsa selezionata',
      'SINGLE': 'Singolo',
      'SINGLE_WHITESPACE': ' singolo ',
      'SMART_DESK': 'Smart desk',
      'SMART_DISPLAY': 'Smart display',
      'START_DATE': 'Data di inizio',
      'START_HOUR': 'Ora di inizio',
      'SMART_LED_STRIP': 'Striscia LED smart',
      'STATE': 'Provincia',
      'SURNAME': 'Cognome',
      'TENANT': 'Tenant',
      'THROUGH_YOUR_MACHINE': 'il tuo dispositivo',
      'TIME_FORMAT': 'HH:mm',
      'TITLE': 'Titolo',
      'TITLE_500': '500: Errore interno del server',
      'TITLE_401': 'Autorizzazione richiesta',
      'TODAY': 'Oggi',
      'TO_DELETE': 'eliminare',
      'TO_EDIT': 'modificare',
      'TYPE': 'Tipo',
      'TYPE_OF_RESOURCE': 'Tipo di risorsa',
      'TYPOLOGY_OF_RESOURCE': 'Tipologia di risorsa',
      'UNIT': 'Area',
      'UNIT_FILL': 'É stata raggiunta la capacità massima per questa area',
      'UPDATED': 'Modificato',
      'UPDATE_BUILDING': 'Modifica edificio',
      'UPDATE_LEVEL': 'Modifica piano',
      'UPDATE_RESOURCE': 'Modifica risorsa',
      'UPDATE_RESOURCE_TYPE': 'Modifica tipo di risorsa',
      'UPDATE_USER': 'Modifica utente',
      'USER': 'Utente',
      'USER_DETAILS': 'Dettagli dell\'utente',
      'USER_EXIST': 'Utente già esistente',
      'USER_LIST': 'Lista degli utenti',
      'USER_CREATED': 'Utente creato',
      'USER_UPDATED': 'Utente modificato',
      'USERS_REQUIRED': 'Utenti *',
      'USERS': 'Utenti',
      'VALIDATION_CALENDAR_START_DATE': '* La data di fine non può essere precedente alla data di inizio',
      'VALIDATION_CALENDAR_YEAR': '* La data di scadenza massima è di un anno',
      'VALIDATION_CALENDAR_WEEK': '* La selezione dei giorni della settimana è obbligatoria',
      'WEEK': 'Settimana',
      'WHAT': 'Cosa',
      'WHEN': 'Quando',
      'WHITE': 'Bianco',
      'WHERE': 'Dove',
      'YES': 'Si',
      'YOUR_MESSAGE': 'Il tuo messaggio',
      'YOUR_NEXT_RESERVATION': 'La tua prossima prenotazione',
      'ZIP_CODE': 'CAP'
    }
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it',
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false
    }
  });
