import * as React from 'react';
import { ReactElement } from 'react';
import userManager from '../../userManager';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { User } from 'oidc-client';
import { push } from 'connected-react-router';

const CallbackHandler = (): ReactElement => {
    const dispatch = useDispatch();
    const isLoadingUser = useSelector<AppState, boolean>(state => state.oidc.isLoadingUser);
    const location = useSelector<AppState, any>(state => state.router.location, (left, right) => JSON.stringify(left) === JSON.stringify(right));
    const url = location.pathname.substring(0, 9);
    const isCallbackUrl = url === '/callback';

    // by default userManager gets params from the current route
    // eg. 'localhost:5200/callback#token_id=...&session_state=...
    // this doesn't work when using hash history as the first hash messed up the process
    // eg. 'localhost:5200/#/callback#token_id=...&session_state=...
    // need to pass the token manually to signinRedirectCallback function
    React.useEffect(() => {
        const successCallback = (user: User) => {
            // get the user's previous location, passed during signinRedirect()
            var redirectPath = user.state.path as string;
            dispatch(push(redirectPath));
        };

        const errorCallback = (error: Error) => {
            console.log(error);
            dispatch(push('/'));
        };
        if (!isLoadingUser) {
            // if location is callback page, return only CallbackPage route to allow signin process
            // IdentityServer 'bug' with hash history: if callback page contains a '#' params are appended with no delimiter
            // eg. /callbacktoken_id=...
            if (isCallbackUrl) {
                const rest = location.pathname.substring(9);
                userManager
                    .signinRedirectCallback(`${url}#${rest}`)
                    .then(user => successCallback(user))
                    .catch(error => errorCallback(error));
            }
        }
    }, [location.pathname, isLoadingUser]);

    return <React.Fragment />;
};

export default CallbackHandler;