import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import { DashboardService } from '../../services/DashboardService';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import ArrowRightIcon from '../../icons/ArrowRight';
import Chart from 'react-apexcharts';
import { useTheme } from '@material-ui/core/styles';
import { availableColors } from '../../utils/colorPicker';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export interface ResourcesCountsDonut {
    counts: number[];
    labels: string[];
}

const ResourcesCountDonutChart: FC = () => {
    const theme = useTheme();
    const history = useNavigate();
    const { t } = useTranslation();

    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const [resourcesCounts, setResourcesCounts] = useState<ResourcesCountsDonut>();
    const [labels, setLabels] = useState<string[]>([]);
    const [counts, setCounts] = useState<number[]>([]);

    useEffect(() => {
        (async () => {
            if (userInfo) {
                var dashboardSerivice = new DashboardService();
                var res = await dashboardSerivice.getResourceCountByType(userInfo.tenantId, userInfo.roles);

                setLabels(res.labels);
                setCounts(res.counts);
                setResourcesCounts(res);
            }

        })();
    }, [])

    const chart = {
        options: {
            chart: {
                background: 'transparent',
                stacked: false,
                toolbar: {
                    show: false
                },
                zoom: false
            },
            colors: availableColors(),
            dataLabels: {
                enabled: false
            },
            labels: labels,
            legend: {
                fontSize: 14,
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.subtitle2.fontWeight,
                itemMargin: {
                    vertical: 8
                },
                labels: {
                    colors: theme.palette.text.primary
                },
                markers: {
                    width: 8,
                    height: 8
                },
                show: true
            },
            stroke: {
                width: 0
            },
            theme: {
                mode: theme.palette.mode
            }
        },
        series: counts
    };

    return (<> {resourcesCounts &&
        <Grid
            item
            md={12}
            sm={12}
            xs={12}
        >
            <Card>
                <CardHeader
                    disableTypography
                    title={(
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography
                                color="textPrimary"
                                variant="h6"
                            >
                                {t('RESOURCE_BY_TYPE')}
                            </Typography>
                        </Box>
                    )}
                />
                <CardContent>
                    <Chart
                        height={300}
                        type="donut"
                        {...chart}
                    />
                </CardContent>
                <Divider />
                <Box
                    sx={{
                        px: 3,
                        py: 2
                    }}
                >
                    <Button
                        color="primary"
                        endIcon={<ArrowRightIcon fontSize="small" />}
                        variant="text"
                        onClick={() => history('/resourceTypes')}
                    >
                        {t('SEE_ALL_RESOURCE_TYPES')}
                    </Button>
                </Box>
            </Card>
        </Grid>}</>
    );
};

export default ResourcesCountDonutChart;