import { HashRouter, Route, Routes as RoutesDom } from "react-router-dom";
import InitPage from "./components/init/InitPage";
import { useSelector } from "react-redux";
import { AppState } from "./store";
import Layout from "./components/Layout";
import ResourceList from './pages/ResourceList';
import ResourceDetails from './pages/ResourceDetails';
import ResourceAdd from './pages/ResourceAdd';
import ResourceTypeList from './pages/ResourceTypeList';
import ResourceTypeDetails from './pages/ResourceTypeDetails';
import ResourceTypeAdd from './pages/ResourceTypeAdd';
import UserList from './pages/UserList';
import UserAdd from './pages/UserAdd';
import UserDetails from './pages/UserDetails';
import Calendar from './pages/Calendar';
import BuildingList from './pages/BuildingList';
import BuildingDetails from './pages/BuildingDetails';
import BuildingAdd from './pages/BuildingAdd';
import LevelList from './pages/LevelList';
import ServerError from './components/ServerError';
import AuthorizationRequired from './components/AuthorizationRequired';
import Dashboard from './pages/Dashboard';
import LevelAdd from './pages/LevelAdd';
import { UserInfo } from "./models/UserInfo";
import { userCanUseIfRoles } from './utils/common';
import {
    BuildingContributor,
    PlannerAdmin,
    PlannerContributor,
    ResourceContributor,
    UserManager
} from './types/rolesConst';
import LevelDetails from './pages/LevelDetails';
import SmartDesk from "./pages/SmartDesk";
import NewCalendarEvent from "./pages/NewCalendarEvent";
import { FC } from "react";
import AIEngine from "./icons/AiEngine";
import AiEngine from "./pages/AiEngine";

const Routes: FC = () => {

    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const isInitialized = useSelector<AppState, boolean>((state) => state.appInit.isInitialized);

    return (
        <>
            {isInitialized ?
                <>
                    <HashRouter>
                        <Layout />
                        <RoutesDom>
                            <Route path="/" element={<Dashboard />} />

                            <Route path="/users" element={
                                userCanUseIfRoles(userInfo, [UserManager]) ? <UserList /> : <AuthorizationRequired />} />

                            <Route path="/users/add" element={
                                userCanUseIfRoles(userInfo, [UserManager]) ? <UserAdd isAdd={true} /> :
                                    <AuthorizationRequired />} />

                            <Route path="/users/edit" element={
                                userCanUseIfRoles(userInfo, [UserManager]) ? <UserAdd isAdd={false} /> :
                                    <AuthorizationRequired />} />

                            <Route path="/users/detail" element={
                                userCanUseIfRoles(userInfo, [UserManager]) ? <UserDetails /> :
                                    <AuthorizationRequired />} />

                            <Route path="/resources" element={
                                userCanUseIfRoles(userInfo, [ResourceContributor]) ? <ResourceList /> :
                                    <AuthorizationRequired />} />

                            <Route path="/aiengine" element={
                                userCanUseIfRoles(userInfo, [PlannerAdmin]) ? <AiEngine /> :
                                    <AuthorizationRequired />} />

                            <Route path="/resources/detail" element={<ResourceDetails />} />

                            <Route path="/resources/add" element={
                                userCanUseIfRoles(userInfo, [ResourceContributor]) ? <ResourceAdd isAdd={true} /> :
                                    <AuthorizationRequired />} />

                            <Route path="/resources/edit" element={
                                userCanUseIfRoles(userInfo, [ResourceContributor]) ? <ResourceAdd isAdd={false} /> :
                                    <AuthorizationRequired />} />

                            <Route path="/resources/duplicate" element={
                                userCanUseIfRoles(userInfo, [ResourceContributor]) ? <ResourceAdd isAdd={true} /> :
                                    <AuthorizationRequired />} />

                            <Route path="/resourceTypes" element={
                                userCanUseIfRoles(userInfo, [ResourceContributor]) ? <ResourceTypeList /> :
                                    <AuthorizationRequired />} />

                            <Route path="/resourceTypes/detail" element={
                                userCanUseIfRoles(userInfo, [ResourceContributor]) ? <ResourceTypeDetails /> :
                                    <AuthorizationRequired />} />

                            <Route path="/resourceTypes/add" element={
                                userCanUseIfRoles(userInfo, [ResourceContributor]) ? <ResourceTypeAdd isAdd={true} /> :
                                    <AuthorizationRequired />} />

                            <Route path="/resourceTypes/edit" element={
                                userCanUseIfRoles(userInfo, [ResourceContributor]) ? <ResourceTypeAdd isAdd={false} /> :
                                    <AuthorizationRequired />} />

                            <Route path="/calendar" element={
                                userCanUseIfRoles(userInfo, [PlannerContributor, PlannerAdmin]) ? <Calendar /> :
                                    <AuthorizationRequired />} />

                            <Route path="/newCalendarEvent" element={
                                userCanUseIfRoles(userInfo, [BuildingContributor]) ? <NewCalendarEvent /> :
                                    <AuthorizationRequired />} />

                            <Route path="/buildings" element={
                                userCanUseIfRoles(userInfo, [BuildingContributor]) ? <BuildingList /> :
                                    <AuthorizationRequired />} />

                            <Route path="/buildings/detail" element={
                                userCanUseIfRoles(userInfo, [BuildingContributor]) ? <BuildingDetails /> :
                                    <AuthorizationRequired />} />

                            <Route path="/buildings/add" element={
                                userCanUseIfRoles(userInfo, [BuildingContributor]) ? <BuildingAdd isAdd={true} /> :
                                    <AuthorizationRequired />} />

                            <Route path="/buildings/edit" element={
                                userCanUseIfRoles(userInfo, [BuildingContributor]) ? <BuildingAdd isAdd={false} /> :
                                    <AuthorizationRequired />} />

                            <Route path="/levels" element={
                                userCanUseIfRoles(userInfo, [BuildingContributor]) ? <LevelList /> :
                                    <AuthorizationRequired />} />

                            <Route path="/level/add" element={
                                userCanUseIfRoles(userInfo, [BuildingContributor]) ? <LevelAdd isAdd={true} /> :
                                    <AuthorizationRequired />} />

                            <Route path="/level/edit" element={
                                userCanUseIfRoles(userInfo, [BuildingContributor]) ? <LevelAdd isAdd={false} /> :
                                    <AuthorizationRequired />} />

                            <Route path="/level/detail" element={
                                userCanUseIfRoles(userInfo, [BuildingContributor]) ? <LevelDetails /> :
                                    <AuthorizationRequired />} />

                            <Route path="/smartdesk" element={<SmartDesk />} />

                            <Route path="/500" element={<ServerError />} />

                            <Route path="/401" element={<AuthorizationRequired />} />
                        </RoutesDom>
                    </HashRouter>
                </> : <InitPage />}
        </>
    )
}



export default Routes;