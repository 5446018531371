import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Card, CardHeader, Divider, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { Anchor, Level } from '../../types/level';
import { FeatureGroup, ImageOverlay, Map, Marker, Polygon, Popup } from 'react-leaflet';
import L, { CRS } from 'leaflet';
import { LevelService } from '../../services/LevelService';
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { UserInfo } from "../../models/UserInfo";
import { deepClone } from '../../utils/common';
import LevelDataManagement from './LevelDataManagement';
import { useTranslation } from 'react-i18next';
import markerBlue from '../../icons/pureSvg/blue-marker.svg';
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

interface LevelDataDetailsProp {
    level: Level;
}

const LevelDataDetails: FC<LevelDataDetailsProp> = (props) => {
    const { level, ...other } = props;

    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);

    const [backgroundMediaAsBase64, setBackgroundMediaAsBase64] = useState<string>();
    const [backgroundMediaSizes, setBackgroundMediaSizes] = useState<{ w: number, h: number }>();
    const [anchors, setAnchors] = useState<Anchor[]>();
    const { t } = useTranslation();


    useEffect(() => {
        let image = new Image();
        image.src = level.planimetry.base64;
        image.onload = () => {
            var sizes: any = {
                w: image.width as number,
                h: image.height as number
            };
            setBackgroundMediaSizes(sizes);
        }

        setBackgroundMediaAsBase64(level.planimetry.base64);

        (async () => {
            if (userInfo && level.planimetry?.base64) {
                var levelService = new LevelService();
                var anchorsRes = await levelService.getAnchors(userInfo?.tenantId, level.units?.map(unit => unit.id));
                setAnchors(anchorsRes);
            }
        })();

    }, []);


    return (<>
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                lg={6}
                md={6}
                xl={6}
                xs={6}
            >
                <Card {...other}>
                    <CardHeader title={t('LEVEL_DETAILS')} />
                    <Divider />
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('NAME')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {level.name}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('SHORT_NAME')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {level.shortName}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('LEVEL_NUMBER')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {level.ordinal}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('IS_OUTDOOR')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {level.outdoor ? 'Yes' : 'No'}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            </Grid>
            <Grid
                item
                lg={6}
                md={6}
                xl={6}
                xs={6}
            >
                <Card {...other}>
                    <LevelDataManagement level={level} />
                </Card>
            </Grid>
        </Grid>
        {backgroundMediaAsBase64 && backgroundMediaSizes &&
            <Card {...other} style={{ marginTop: '15px' }}>
                <CardHeader title="Map" />
                <Divider />
                <Table>
                    <TableRow>
                        <div>
                            <Map
                                zoom={0}
                                minZoom={-10}
                                maxZoom={10}
                                scrollWheelZoom={true}
                                crs={CRS.Simple}
                                center={[backgroundMediaSizes.h / 2, backgroundMediaSizes.w / 2]} >

                                <ImageOverlay
                                    bounds={[[0, 0], [backgroundMediaSizes.h, backgroundMediaSizes.w]]}
                                    url={backgroundMediaAsBase64} />

                                <FeatureGroup>
                                    {level.units?.map(unit => {
                                        let positions = unit.coordinates.map(coordinate => {
                                            return {
                                                lat: coordinate[0],
                                                lng: coordinate[1]
                                            }
                                        });

                                        return (<Polygon positions={positions} />)
                                    })}
                                </FeatureGroup>

                                {anchors?.map(anchor => {
                                    return (<DraggableMarker anchorProp={anchor} />)
                                })}

                            </Map>
                        </div>
                    </TableRow>
                </Table>
            </Card>
        }</>
    );
};

function DraggableMarker(props) {
    const { anchorProp } = props;

    const [position, setPosition] = useState<number[]>([0, 0]);
    const markerRef = useRef<any>(null);


    useEffect(() => {
        if (anchorProp)
            setPosition(deepClone(anchorProp.coordinates))
    }, [anchorProp]);

    const iconBlue = L.icon({
        iconUrl: markerBlue,
        iconSize: [32, 34],
        iconAnchor: [16, 0]
    });

    return (position ?
        <Marker
            icon={iconBlue}
            draggable={false}
            position={[position && position[0] ? position[0] : 0, position && position[1] ? position[1] : 0]}
            ref={markerRef}>
            <Popup minWidth={100}>
                <span>
                    {anchorProp.resourceName}
                </span>
            </Popup>
        </Marker>
        : <></>
    )
}

export default LevelDataDetails;
