import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Container, Divider, Grid, Link, Tab, Tabs, Typography } from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LevelDataDetails from '../components/level/LevelDataDetails';
import ChevronRightIcon from '../icons/ChevronRight';
import PencilAltIcon from '../icons/PencilAlt';
import { LevelService } from '../services/LevelService';
import { Level } from '../types/level';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

const LevelDetails: FC = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const currentTheme = useTheme();

    const [level, setLevel] = useState<Level>();
    const [currentTab, setCurrentTab] = useState<string>('details');

    const tabs = [
        { label: t('DETAILS'), value: 'details' }
    ];


    useEffect(() => {
        try {
            (async () => {
                let idParam = window.location.href.split('id=')[1] ?? '';
                let levelService = new LevelService();
                let res = await levelService.findLevel(idParam);
                console.log(res);
                setLevel(res);
            })();
        } catch (e) {
            console.error(e);
            history('/500');
        }

    }, []);

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    if (!level) {
        return null;
    }

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 2,
                    [currentTheme.breakpoints.up('lg')]: {
                        paddingLeft: '300px'
                    }
                }}
            >
                <Container>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {level.name}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    {t('DASHBOARD')}
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to={`/buildings`}
                                    variant="subtitle2"
                                >
                                    {t('BUILDINGS')}
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to={`/levels?buildingId=${level.buildingIds[0]}`}
                                    variant="subtitle2"
                                >
                                    {t('LEVELS')}
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    {t('LEVEL_DETAIL')}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Divider />
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<PencilAltIcon fontSize="small" />}
                                    sx={{ m: 1 }}
                                    to={`/edit?id=${level.buildingIds[0]}&levelId=${level.id}`}
                                    variant="contained"
                                >
                                    {t('EDIT')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Tabs
                            indicatorColor="primary"
                            onChange={handleTabsChange}
                            scrollButtons="auto"
                            textColor="primary"
                            value={currentTab}
                            variant="scrollable"
                        >
                            {tabs.map((tab) => (
                                <Tab
                                    key={tab.value}
                                    label={tab.label}
                                    value={tab.value}
                                />
                            ))}
                        </Tabs>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 3 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                lg={12}
                                md={12}
                                xl={12}
                                xs={12}
                            >
                                <LevelDataDetails level={level} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default LevelDetails;
