import { ReactElement, useEffect } from 'react';
import userManager from '../../userManager';
import logoApp from '../../images/logo_app.svg'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { User } from 'oidc-client';
import { actionCreators as appInitAction } from '../../store/AppInit';
import CallbackHandler from './CallbackHandler'
import InitStatus from './InitStatus'
import InitService from '../../services/InitService'
import { Box, LinearProgress } from '@material-ui/core';

const InitPage = (): ReactElement => {

    const dispatch = useDispatch();
    const isLoadingUser = useSelector<AppState, boolean>(state => state.oidc.isLoadingUser);
    const location = useSelector<AppState, any>(state => state.router.location, (left, right) => JSON.stringify(left) === JSON.stringify(right));
    const user = useSelector<AppState, User>(state => state.oidc.user);
    const url = location.pathname.substring(0, 9);
    const isCallbackUrl = url === '/callback';

    useEffect(() => {
        if (!!user) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access_token;
            (async () => {
                try {
                    await InitService.initializeLocalStorage();
                    const userInfo = await InitService.initializeUserProfile(user);
                    dispatch(appInitAction.setUserInfo(userInfo));
                    dispatch(appInitAction.setIsOfflineConfigInitialized(true));
                    dispatch(appInitAction.setIsInitialized(true));
                }
                catch (e) {
                    console.log(e);
                    dispatch(appInitAction.setInitializationError(true));
                }
            })();
        }
    }, [user]);

    useEffect(() => {
        if (!user && !isCallbackUrl && !isLoadingUser) {
            userManager.signinRedirect({
                data: { path: location.pathname },
            });
        }
    }, [user, isLoadingUser]);

    return (
        <Box
            sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(23,28,36,255)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                left: 0,
                p: 3,
                position: 'fixed',
                top: 0,
                width: '100%',
                zIndex: 2000
            }}
        >
            <CallbackHandler />
            <img alt="logoApp" src={logoApp} height={30} />
            <div style={{ flexGrow: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', color: 'rgb(145, 158, 171)', width: 300 }}>
                <LinearProgress
                    color="primary"
                    variant="indeterminate"
                />
            </div>
            <div style={{ color: 'rgb(145, 158, 171)' }}>
                <InitStatus />
            </div>
            <div style={{ flexGrow: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '50px' }}>
                <div style={{ color: 'rgb(145, 158, 171)' }}>Powered by</div>
                <img alt="logoCompany" src='/static/logo_company.svg' height={40} />
            </div>
        </Box>
    );
};

export default InitPage;