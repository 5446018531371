import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import type { Theme } from '@material-ui/core';
import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Card,
    Container,
    Dialog,
    Grid,
    Link,
    TextField,
    Typography
} from '@material-ui/core';
import { alpha, experimentalStyled, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CalendarToolbar from '../components/calendar/CalendarToolbar';
import CalendarEventForm from '../components/calendar/CalendarEventForm';
import ChevronRightIcon from '../icons/ChevronRight';
import PlusIcon from '../icons/Plus';
import '../App.css';
import type { CalendarEvent, CalendarView } from '../types/calendar';
import { useSelector } from "react-redux";
import { AppState } from "../store";
import { UserInfo } from "../models/UserInfo";
import { ReservationService } from '../services/ReservationService';
import { EventDropArg } from '@fullcalendar/common';
import { useSnackbar } from 'notistack';
import { ResourceSelectItem } from '../types/resource';
import { ResourceService } from '../services/ResourceService';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import AddCalendarEvent from '../components/calendar/AddCalendarEvent';
import { format } from "date-fns";

const FullCalendarWrapper = experimentalStyled('div')(
    ({ theme }) => (
        {
            '& .fc-license-message': {
                display: 'none'
            },
            '& .fc': {
                '--fc-bg-event-opacity': 1,
                '--fc-border-color': theme.palette.divider,
                '--fc-daygrid-event-dot-width': '10px',
                '--fc-event-text-color': theme.palette.text.primary,
                '--fc-list-event-hover-bg-color': theme.palette.background.default,
                '--fc-neutral-bg-color': theme.palette.background.default,
                '--fc-page-bg-color': theme.palette.background.default,
                '--fc-today-bg-color': alpha(theme.palette.primary.main, 0.25),
                color: theme.palette.text.primary,
                fontFamily: theme.typography.fontFamily
            },
            '& .fc .fc-col-header-cell-cushion': {
                paddingBottom: '10px',
                paddingTop: '10px'
            },
            '& .fc .fc-day-other .fc-daygrid-day-top': {
                color: theme.palette.text.secondary
            },
            '& .fc-daygrid-event': {
                padding: '10px'
            }
        }
    )
);

const Calendar: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const calendarRef = useRef<FullCalendar | null>(null);
    const mobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const userInfo = useSelector<AppState, UserInfo | undefined>((state) => state.appInit.userInfo);
    const history = useNavigate();
    var currentTheme = useTheme();

    const [date, setDate] = useState<Date>(new Date());
    const [view, setView] = useState<CalendarView>(mobileDevice ? 'listWeek' : 'dayGridMonth');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEventRange, setSelectedEventRange] = useState<any>();
    const [events, setEvents] = useState<CalendarEvent[]>();
    const [selectedEventId, setSelectedEventId] = useState<string>();
    const [selectedResource, setSelectedResource] = useState<ResourceSelectItem>();
    const [selectedResourceIds, setSelectedResourceIds] = useState<string[]>();
    const [resourceSelectItems, setResourceSelectItems] = useState<ResourceSelectItem[]>();
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const [eventIsReadOnly, setEventIsReadOnly] = useState<boolean>(false);
    const [isNewEventModalOpen, setIsNewEventModalOpen] = useState<boolean>(false);

    const reservationService = new ReservationService();
    const resourceService = new ResourceService();

    useEffect(() => {

        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            const newView = mobileDevice ? 'listWeek' : 'dayGridMonth';

            calendarApi.changeView(newView);
            setView(newView);
        }

        (async () => {
            setIsPageLoading(true);
            try {
                if (userInfo) {
                    if (calendarEl) {
                        const calendarApi = calendarEl.getApi();

                        let [evnts, rsrcs] = await Promise.all([
                            reservationService.getReservationEvents([], userInfo.tenantId, calendarApi.view.activeStart, calendarApi.view.activeEnd),
                            resourceService.getResourceSelectItems(userInfo.tenantId, userInfo.email)]);

                        setEvents(evnts);
                        let emptyResource: ResourceSelectItem = {
                            id: '',
                            name: '',
                            errorCode: '',
                            unitId: '',
                            typeCode: '',
                            effectiveCapacity: 0
                        };
                        rsrcs.unshift(emptyResource);
                        setResourceSelectItems(rsrcs);
                    }
                }
            } catch (e) {
                console.log(e);
                history('/500');
            }
            setIsPageLoading(false);
        })();

    }, [mobileDevice, userInfo]);


    function updateEventsByDates(startDate: Date, endDate: Date) {
        setIsPageLoading(true);
        try {
            (async () => {
                if (userInfo) {
                    let res = await reservationService.getReservationEvents(selectedResourceIds || [], userInfo.tenantId, startDate, endDate);
                    setEvents(res);
                }
            })();
        } catch {
            history('/500');
        }
        setIsPageLoading(false);
    }

    const handleDateToday = (): void => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.today();
            updateEventsByDates(calendarApi.view.activeStart, calendarApi.view.activeEnd);
            setDate(calendarApi.getDate());
        }
    };

    const handleViewChange = (newView: CalendarView): void => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.changeView(newView);
            setView(newView);
        }
    };

    const handleDatePrev = (): void => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.prev();
            updateEventsByDates(calendarApi.view.activeStart, calendarApi.view.activeEnd)
            setDate(calendarApi.getDate());
        }
    };

    const handleDateNext = (): void => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.next();
            updateEventsByDates(calendarApi.view.activeStart, calendarApi.view.activeEnd)
            setDate(calendarApi.getDate());
        }
    };

    const handleModalClose = (): void => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            setIsNewEventModalOpen(false);
            setIsModalOpen(false);
            updateEventsByDates(calendarApi.view.activeStart, calendarApi.view.activeEnd);
            setSelectedEventId(undefined);
            setEventIsReadOnly(false);
        }
    };

    const handleEventSelect = (arg: any): void => {
        if (!events?.filter(e => e.id === arg.event.id)[0]?.isReadOnly) {
            setSelectedEventId(arg.event.id);
            setEventIsReadOnly(events?.filter(e => e.id === arg.event.id)[0]?.isReadOnly === true);
            setIsModalOpen(true);
        }
    };

    const handleRangeSelect = (arg: any): void => {
        let start = format(arg.start, t('DATE_PICKER_ONLY_DATE'));
        history(`/NewCalendarEvent?start=${start}`);
    };

    const handleEventDrop = (eventDrop: EventDropArg): void => {
        try {
            if (!events?.filter(e => e.id === eventDrop.event.id)[0]?.isReadOnly) {
                (async () => {

                    if (eventDrop.event.id && eventDrop.event.start && eventDrop.event.end) {
                        let changedResult = await reservationService.changeDateReservationEvent(eventDrop.event.id, eventDrop.event.start, eventDrop.event.end);

                        enqueueSnackbar(changedResult?.result === false ? t('RERSERVATION_UPDATED') : t('ERROR_EDIT_RESERVATION'), {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: changedResult?.result === false ? 'success' : 'error'
                        });

                        const calendarEl = calendarRef.current;

                        if (calendarEl && userInfo) {
                            const calendarApi = calendarEl.getApi();
                            let res = await reservationService.getReservationEvents(selectedResourceIds || [], userInfo.tenantId, calendarApi.view.activeStart, calendarApi.view.activeEnd)
                            setEvents(res);
                        }
                    }

                })();
            }
        } catch {
            history('/500');
        }
    }

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 2,
                    [currentTheme.breakpoints.up('lg')]: {
                        paddingLeft: '300px'
                    }
                }}
            >
                <Container>
                    {!isPageLoading ?
                        <>
                            <Grid
                                container
                                justifyContent="space-between"
                                spacing={3}
                            >
                                <Grid item>
                                    <Typography
                                        color="textPrimary"
                                        variant="h5"
                                    >
                                        {t('HERES_THE_RESERVATIONS')}
                                    </Typography>
                                    <Breadcrumbs
                                        aria-label="breadcrumb"
                                        separator={<ChevronRightIcon fontSize="small"/>}
                                        sx={{ mt: 1 }}
                                    >
                                        <Link
                                            color="textPrimary"
                                            component={RouterLink}
                                            to="/"
                                            variant="subtitle2"
                                        >
                                            {t('DASHBOARD')}
                                        </Link>
                                        <Typography
                                            color="textSecondary"
                                            variant="subtitle2"
                                        >
                                            {t('CALENDAR')}
                                        </Typography>
                                        <Box sx={{ mt: 1 }} style={{ width: '350px' }}>
                                            <Autocomplete
                                                value={selectedResource}
                                                getOptionLabel={(option) => option?.name}
                                                options={resourceSelectItems || []}
                                                onChange={async (event, selResource) => {
                                                    try {
                                                        const calendarEl = calendarRef.current;
                                                        if (calendarEl && userInfo) {
                                                            const calendarApi = calendarEl.getApi();
                                                            let searchResources = selResource?.id ? [selResource?.id] : [];
                                                            setSelectedResourceIds(searchResources);
                                                            let res = await reservationService.getReservationEvents(searchResources, userInfo.tenantId, calendarApi.view.activeStart, calendarApi.view.activeEnd)
                                                            setEvents(res);
                                                        }
                                                    } catch {
                                                        history('/500')
                                                    }
                                                }}
                                                onInputChange={(event, newInputValue) => {
                                                }}
                                                renderInput={(params): JSX.Element => (
                                                    <TextField
                                                        label={t('RESOURCE')}
                                                        name="resource"
                                                        variant="outlined"
                                                        {...params}>
                                                    </TextField>
                                                )}
                                            />
                                        </Box>
                                    </Breadcrumbs>
                                </Grid>
                                <Grid item>
                                    <Box sx={{ m: -1 }}>
                                        <Button
                                            color="primary"
                                            onClick={() => history('/NewCalendarEvent')}
                                            startIcon={<PlusIcon fontSize="small"/>}
                                            sx={{ m: 1 }}
                                            variant="contained"
                                        >
                                            {t('NEW_EVENT')}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box sx={{ mt: 3 }}>
                                <CalendarToolbar
                                    date={date}
                                    onDateNext={handleDateNext}
                                    onDatePrev={handleDatePrev}
                                    onDateToday={handleDateToday}
                                    onViewChange={handleViewChange}
                                    view={view}
                                />
                            </Box>
                            <Card
                                sx={{
                                    mt: 3,
                                    p: 2
                                }}
                            >
                                <FullCalendarWrapper>
                                    <FullCalendar
                                        locale={t('CULTURE')}
                                        allDayMaintainDuration
                                        dayMaxEventRows={2}
                                        droppable
                                        editable
                                        eventClick={handleEventSelect}
                                        eventDisplay="block"
                                        eventDrop={handleEventDrop}
                                        events={events}
                                        headerToolbar={false}
                                        height={800}
                                        initialDate={date}
                                        initialView={view}
                                        plugins={[
                                            dayGridPlugin,
                                            interactionPlugin,
                                            listPlugin,
                                            timeGridPlugin,
                                            timelinePlugin
                                        ]}
                                        ref={calendarRef}
                                        rerenderDelay={10}
                                        select={handleRangeSelect}
                                        selectable
                                        weekends
                                        firstDay={parseInt(t('FIRST_DAY_OF_WEEK'))}
                                    />
                                </FullCalendarWrapper>
                                <Dialog
                                    fullWidth
                                    maxWidth="xl"
                                    onClose={handleModalClose}
                                    open={isModalOpen}
                                >
                                    {isModalOpen && selectedEventId &&
                                    <CalendarEventForm
                                        selectedResource={undefined}
                                        eventId={selectedEventId}
                                        onAddComplete={handleModalClose}
                                        onCancel={handleModalClose}
                                        range={selectedEventRange}
                                        isReadOnly={eventIsReadOnly}
                                    />}
                                </Dialog>
                                <Dialog
                                    fullWidth
                                    maxWidth="xl"
                                    onClose={handleModalClose}
                                    open={isNewEventModalOpen}
                                >
                                    <AddCalendarEvent
                                        onAddComplete={handleModalClose}
                                        onCancel={handleModalClose}
                                        range={selectedEventRange}/>
                                </Dialog>
                            </Card>
                        </>
                        : <>
                            <Skeleton variant='text' width='100%' height='800px' animation="wave"
                                      style={{ transformOrigin: 'top' }}/>
                        </>}
                </Container>
            </Box>
        </>
    );
};

export default Calendar;